// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_DashedInput = require("../ZAP.Dart.Client.Components.DashedInput/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Templates_StepWrapper = require("../ZAP.Dart.Client.Screens.Templates.StepWrapper/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    pinLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    errorMsg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(18) ]),
    button: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(244), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(10) ]),
    bottomPinComponent: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(48) ])
};
var component = React_Basic.createComponent("ResetPin");
var resetPin = (function () {
    var onCancel = function (self) {
        return self.props.onAction(ReactNavigation_Types.PgSubmit.create(new Data_Either.Left(Data_Unit.unit)));
    };
    var lengthCondition = function (self) {
        return Data_String_CodeUnits.length(self.state.pin) === 6 && Data_String_CodeUnits.length(self.state.confirmPin) === 6;
    };
    var onSubmit = function (self) {
        var pinsMatch = self.state.pin === self.state.confirmPin;
        return function __do() {
            self.setState(function (v) {
                return {
                    pin: "",
                    confirmPin: "",
                    pinsMatch: pinsMatch
                };
            })();
            return Control_Applicative.when(Effect.applicativeEffect)(pinsMatch && lengthCondition(self))(self.props.onAction(ReactNavigation_Types.PgSubmit.create(new Data_Either.Right(self.state.pin))))();
        };
    };
    var render = function (self) {
        var pinInput = function (v) {
            return React_Basic_Native_Generated.view()({
                style: v.style,
                children: [ ZAP_Dart_Client_Components_Typography.b2({
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    style: sheet.pinLabel,
                    value: v.label
                }), ZAP_Dart_Client_Components_DashedInput.dashedInput({
                    onChange: v.onChange,
                    length: 6,
                    fontSize: 32,
                    letterSpacing: 8,
                    value: v.value,
                    inputType: ZAP_Dart_Client_Components_DashedInput.Numeric.value,
                    onSubmitEditing: React_Basic_Events.handler_(v.onSubmitEditing),
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    style: sheet.input,
                    secureTextEntry: true,
                    autoFocus: v.autoFocus
                }) ]
            });
        };
        return ZAP_Dart_Client_Screens_Templates_StepWrapper.stepWrapper({
            header: new Data_Tuple.Tuple("RESET YOUR", "6- DIGIT PIN"),
            message: new Data_Tuple.Tuple("", ""),
            content: [ pinInput({
                style: ZAP_Dart_Client_Styles_Utils.noStyle,
                value: self.state.pin,
                label: "Enter New PIN",
                onChange: function (text) {
                    return self.setState(function (v) {
                        return {
                            pin: text,
                            pinsMatch: true,
                            confirmPin: v.confirmPin
                        };
                    });
                },
                autoFocus: true,
                onSubmitEditing: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
            }), pinInput({
                style: sheet.bottomPinComponent,
                value: self.state.confirmPin,
                label: "Confirm New PIN",
                onChange: function (text) {
                    return self.setState(function (v) {
                        return {
                            pin: v.pin,
                            pinsMatch: true,
                            confirmPin: text
                        };
                    });
                },
                autoFocus: false,
                onSubmitEditing: onSubmit(self)
            }), ZAP_Dart_Client_Components_Typography.b2({
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.errorMsg,
                value: (function () {
                    if (self.state.pinsMatch) {
                        return "";
                    };
                    return "Pins do not match!";
                })()
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.button,
                disabled: !lengthCondition(self),
                inverted: true,
                size: ZAP_Dart_Client_Components_Button.lg,
                onPress: onSubmit(self),
                title: "SUBMIT"
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.button,
                disabled: false,
                inverted: false,
                size: ZAP_Dart_Client_Components_Button.md,
                onPress: onCancel(self),
                title: "CANCEL"
            }) ]
        });
    };
    var initialState = {
        pin: "",
        confirmPin: "",
        pinsMatch: true
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
})();
module.exports = {
    resetPin: resetPin
};
