// Generated by purs version 0.13.5
"use strict";
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Char = require("../Data.Char/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Digit = function (x) {
    return x;
};
var toInt = function (v) {
    return v;
};
var toChar = function (v) {
    return Data_Maybe.fromJust()(Data_Char.fromCharCode(v + 48 | 0));
};
var showDigit = new Data_Show.Show(function (v) {
    return "Digit " + Data_Show.show(Data_Show.showInt)(v);
});
var genericDigit = new Data_Generic_Rep.Generic(function (x) {
    return x;
}, function (x) {
    return x;
});
var fromInt = function (c) {
    if (0 <= c && c <= 9) {
        return new Data_Maybe.Just(c);
    };
    if (Data_Boolean.otherwise) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Data.Digit (line 27, column 1 - line 27, column 30): " + [ c.constructor.name ]);
};
var fromChar = function (c) {
    var code = Data_Char.toCharCode(c);
    var $16 = code >= 48 && code <= 57;
    if ($16) {
        return new Data_Maybe.Just(Digit(code - 48 | 0));
    };
    return Data_Maybe.Nothing.value;
};
var eqDigit = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var ordDigit = new Data_Ord.Ord(function () {
    return eqDigit;
}, function (v) {
    return function (v1) {
        return Data_Ord.compare(Data_Ord.ordInt)(v)(v1);
    };
});
var _zero = 0;
var _two = 2;
var _three = 3;
var _six = 6;
var _seven = 7;
var _one = 1;
var _nine = 9;
var _four = 4;
var _five = 5;
var _eight = 8;
module.exports = {
    fromInt: fromInt,
    toInt: toInt,
    fromChar: fromChar,
    toChar: toChar,
    "_zero": _zero,
    "_one": _one,
    "_two": _two,
    "_three": _three,
    "_four": _four,
    "_five": _five,
    "_six": _six,
    "_seven": _seven,
    "_eight": _eight,
    "_nine": _nine,
    genericDigit: genericDigit,
    showDigit: showDigit,
    eqDigit: eqDigit,
    ordDigit: ordDigit
};
