// Generated by purs version 0.13.5
"use strict";
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_External_KeyboardAwareScrollView = require("../ZAP.Dart.Client.Components.External.KeyboardAwareScrollView/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ]),
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(40), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40) ]),
    header: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(30) ]),
    message: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.lineHeight(18) ]),
    bottomContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ])
};
var component = React_Basic.createComponent("StepWrapper");
var guestStepWrapper = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ ZAP_Dart_Client_Components_External_KeyboardAwareScrollView.keyboardAwareScrollView()({
                contentContainerStyle: sheet.scrollContainer,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.headerContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.h3({
                        style: sheet.header,
                        value: Data_Tuple.fst(v.header),
                        color: ZAP_Dart_Client_Styles_Colors.fg
                    }), Data_Monoid.guard(React_Basic.monoidJSX)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(Data_Tuple.snd(v.header)))(ZAP_Dart_Client_Components_Typography.h3({
                        style: sheet.header,
                        value: Data_Tuple.snd(v.header),
                        color: ZAP_Dart_Client_Styles_Colors.fg
                    })) ]
                }), Data_Monoid.guard(React_Basic.monoidJSX)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(Data_Tuple.fst(v.message)))(ZAP_Dart_Client_Components_Typography.b2({
                    color: ZAP_Dart_Client_Styles_Colors.fg,
                    style: sheet.message,
                    value: Data_Tuple.fst(v.message)
                })), Data_Monoid.guard(React_Basic.monoidJSX)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(Data_Tuple.snd(v.message)))(ZAP_Dart_Client_Components_Typography.b2({
                    color: ZAP_Dart_Client_Styles_Colors.fg,
                    style: sheet.message,
                    value: Data_Tuple.snd(v.message)
                })), React_Basic_Native_Generated.view()({
                    style: sheet.bottomContainer,
                    children: v.content
                }) ]
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    guestStepWrapper: guestStepWrapper
};
