// Generated by purs version 0.13.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    tab: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(62), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_Styles_Flex.flexEnd(React_Basic_Native_Styles_Flex.alignmentStartEnd)), React_Basic_Native_Styles.borderTopWidth(3), React_Basic_Native_Styles.borderColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    buttons: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(62), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.column), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.flex(1) ]),
    qrContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(80), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(80), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(8), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.white), React_Basic_Native_Styles.borderRadius(100), React_Basic_Native_Styles.borderColor(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles.borderWidth(3), React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(4.0), React_Basic_Native_Styles.shadowOffset({
        height: 2.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(2) ]),
    qrButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    qrImage: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.white), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.borderRadius(100) ])
};
var component = React_Basic.createComponent("TabBarContent");
var tabBarContent = (function () {
    var routesWithIdx = function (state) {
        return Data_Array.mapWithIndex(function (idx) {
            return function (route) {
                return {
                    idx: idx,
                    route: route
                };
            };
        })(state.routes);
    };
    var routeLength = function (state) {
        return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(Data_Array.length(state.routes))(2);
    };
    var renderQrButton = function (onPress) {
        return React_Basic_Native_Generated.view()({
            style: sheet.qrContainer,
            children: [ React_Basic_Native_Generated.touchableOpacity()({
                style: sheet.qrButton,
                onPress: onPress,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.qrImage,
                    children: [ ZAP_Dart_Client_Components_Icon.icon({
                        name: "qr_outlined",
                        size: 36,
                        color: ZAP_Dart_Client_Styles_Colors.fgMuted
                    }) ]
                }) ]
            }) ]
        });
    };
    var getRightButtons = function (state) {
        return Data_Array.drop(routeLength(state))(routesWithIdx(state));
    };
    var getLeftButtons = function (state) {
        return Data_Array.take(routeLength(state))(routesWithIdx(state));
    };
    var getIcon = function (str) {
        if (str === "about") {
            return "about_bottleco_outlined";
        };
        if (str === "catalog") {
            return "catalog_outlined";
        };
        if (str === "rewards") {
            return "rewards_outlined";
        };
        return "menu_outlined";
    };
    var renderButtons = function (button) {
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(button.routes)(function (v) {
            return React_Basic_Native_Generated.touchableOpacity()({
                style: sheet.buttons,
                onPress: button.onTabPress(v.route),
                children: [ ZAP_Dart_Client_Components_Icon.icon({
                    name: getIcon(v.route.routeName),
                    size: 36,
                    color: (function () {
                        var v1 = button.currentIdx === v.idx;
                        if (v1) {
                            return ZAP_Dart_Client_Styles_Colors.primary;
                        };
                        if (!v1) {
                            return ZAP_Dart_Client_Styles_Colors.fgMuted;
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.TabBar (line 113, column 21 - line 115, column 46): " + [ v1.constructor.name ]);
                    })()
                }) ]
            });
        });
    };
    var render = function (v) {
        return React_Basic_Native_Generated.safeAreaView()({
            style: sheet.container,
            children: [ React_Basic_Native_Generated.view()({
                style: sheet.tab,
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderButtons({
                    routes: getLeftButtons(v.state),
                    currentIdx: v.state.index,
                    onTabPress: function (route) {
                        return React_Basic_Events.handler_(function () {
                            return v.onTabPress({
                                route: route
                            });
                        });
                    }
                }))(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ renderQrButton(React_Basic_Events.handler_(v.onAction(new Data_Maybe.Just(ZAP_Dart_Client_Screens_Types.ScanQr.value)))) ])(renderButtons({
                    routes: getRightButtons(v.state),
                    currentIdx: v.state.index,
                    onTabPress: function (route) {
                        return React_Basic_Events.handler_(function () {
                            return v.onTabPress({
                                route: route
                            });
                        });
                    }
                })))
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    tabBarContent: tabBarContent
};
