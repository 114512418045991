// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(1), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch) ])
};
var component = React_Basic.createComponent("Divider");
var divider$prime = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: v.style,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.divider, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.backgroundColor(v.color) ]) ])
            }))
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
var divider = function (style) {
    return divider$prime({
        style: style,
        color: ZAP_Dart_Client_Styles_Colors.divider
    });
};
var divider_ = divider(ZAP_Dart_Client_Styles_Utils.noStyle);
module.exports = {
    divider: divider,
    divider_: divider_,
    "divider'": divider$prime
};
