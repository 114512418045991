// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Promise = require("../Control.Promise/index.js");
var setItem = function (key) {
    return function (value) {
        return Control_Promise.toAffE(function () {
            return $foreign["_setItem"](key, value);
        });
    };
};
var removeItem = function (key) {
    return Control_Promise.toAffE(function () {
        return $foreign["_removeItem"](key);
    });
};
var getItem = function (key) {
    return Control_Promise.toAffE(function () {
        return $foreign["_getItem"](key);
    });
};
var getAllKeys = Control_Promise.toAffE($foreign["_getAllKeys"]);
var clear = Control_Promise.toAffE($foreign["_clear"]);
module.exports = {
    getItem: getItem,
    setItem: setItem,
    removeItem: removeItem,
    clear: clear,
    getAllKeys: getAllKeys,
    "_getItem": $foreign["_getItem"],
    "_setItem": $foreign["_setItem"],
    "_removeItem": $foreign["_removeItem"],
    "_clear": $foreign["_clear"],
    "_getAllKeys": $foreign["_getAllKeys"]
};
