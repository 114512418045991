// Generated by purs version 0.13.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Function = require("../Data.Function/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactNavigation_Utils_HOCs_WithAsyncId = require("../ReactNavigation.Utils.HOCs.WithAsyncId/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var routeUiComponent = React_Basic.createComponent("RouteUi");
var routeUiScreen = function (f) {
    var render = function (v) {
        return f(v.props.navigation);
    };
    return React_Basic.toReactComponent()(Control_Category.identity(Control_Category.categoryFn))(routeUiComponent)({
        render: render
    });
};
var routeUiPage = function (dictOnPgAction) {
    var $7 = ReactNavigation_Utils_HOCs_WithPageProps.withPageProps(dictOnPgAction);
    return function ($8) {
        return routeUiScreen($7($8));
    };
};
var routeUiInitD = function (dictOnPgAction) {
    return function (dictLacks) {
        var $9 = routeUiPage(dictOnPgAction);
        var $10 = ReactNavigation_Utils_HOCs_WithAsyncId.withAsyncId();
        return function ($11) {
            return $9($10($11));
        };
    };
};
var routeUiPage_ = function (c) {
    return routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(Data_Function["const"](c));
};
module.exports = {
    routeUiPage: routeUiPage,
    routeUiPage_: routeUiPage_,
    routeUiInitD: routeUiInitD
};
