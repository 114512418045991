// Generated by purs version 0.13.5
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ZAP_Dart_Client_Components_External_WebView = require("../ZAP.Dart.Client.Components.External.WebView/index.js");
var ZAP_Dart_Client_Constants = require("../ZAP.Dart.Client.Constants/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ])
};
var component = React_Basic.createComponent("About");
var about = function (info) {
    var render = function (props) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ ZAP_Dart_Client_Components_External_WebView.webview({
                uri: ZAP_Dart_Client_Constants.aboutUrl,
                defaultTitle: "About us"
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
};
module.exports = {
    about: about
};
