// Generated by purs version 0.13.5
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(50), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(40), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    image: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(300), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(185) ]),
    messageContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(30), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    primaryMessage: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(25), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    secondaryMessage: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ])
};
var component = React_Basic.createComponent("EmptyPage");
var emptyPage = (function () {
    var render = function (props) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ ZAP_Dart_Client_Components_Image.image({
                style: sheet.image,
                source: props.image,
                resizeMode: React_Basic_Native_Styles.contain
            }), React_Basic_Native_Generated.view()({
                style: sheet.messageContainer,
                children: [ ZAP_Dart_Client_Components_Typography.h4({
                    style: sheet.primaryMessage,
                    color: ZAP_Dart_Client_Styles_Colors.fg,
                    value: props.message.primary
                }), ZAP_Dart_Client_Components_Typography.b1({
                    style: sheet.secondaryMessage,
                    color: ZAP_Dart_Client_Styles_Colors.fg,
                    value: props.message.secondary
                }) ]
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    component: component,
    emptyPage: emptyPage,
    sheet: sheet
};
