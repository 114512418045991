// Generated by purs version 0.13.5
"use strict";
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var styleDisabled = function (disabled) {
    return React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.opacity((function () {
        if (disabled) {
            return 0.5;
        };
        if (!disabled) {
            return 1.0;
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Input.Styles (line 43, column 17 - line 45, column 21): " + [ disabled.constructor.name ]);
    })()) ]);
};
var containerStyles = [ React_Basic_Native_Styles.borderWidth(0.5), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.black), React_Basic_Native_Styles.borderStyle(React_Basic_Native_Styles.solid), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.transparent), React_Basic_Native_Styles.margin(React_Basic_Native_Styles.intMargin)(0), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(45), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Text.fontSize(12), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.2), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.altFg), React_Basic_Native_Styles.paddingLeft(React_Basic_Native_Styles.intPaddingLeft)(15), React_Basic_Native_Styles.paddingRight(React_Basic_Native_Styles.intPaddingRight)(15), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(10), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(10), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left) ];
var container = React_Basic_Native_Styles.styles(containerStyles);
module.exports = {
    container: container,
    containerStyles: containerStyles,
    styleDisabled: styleDisabled
};
