// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic = require("../React.Basic/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var convertProps = function (props) {
    return {
        style: props.style,
        value: Data_Maybe.fromMaybe("")(props.value),
        onChange: function ($0) {
            return props.onChange(ZAP_Dart_Client_Screens_Utils.getNonEmpty($0))();
        },
        items: props.items,
        placeholder: props.placeholder
    };
};
var select = function (props) {
    return React_Basic.element($foreign["_select"])(convertProps(props));
};
module.exports = {
    select: select
};
