// Generated by purs version 0.13.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Effect_Random = require("../Effect.Random/index.js");
var $$Math = require("../Math/index.js");
var Seed = function (x) {
    return x;
};
var unSeed = function (v) {
    return v;
};
var showSeed = new Data_Show.Show(function (v) {
    return "Seed " + Data_Show.show(Data_Show.showInt)(v);
});
var seedMin = 1;
var lcgM = 2147483647;
var seedMax = lcgM - 1 | 0;
var mkSeed = function (x) {
    var ensureBetween = function (min) {
        return function (max) {
            return function (n) {
                var rangeSize = max - min | 0;
                var n$prime = Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(n)(rangeSize);
                var $14 = n$prime < min;
                if ($14) {
                    return n$prime + max | 0;
                };
                return n$prime;
            };
        };
    };
    return ensureBetween(seedMin)(seedMax)(x);
};
var randomSeed = Data_Functor.map(Effect.functorEffect)(mkSeed)(Effect_Random.randomInt(seedMin)(seedMax));
var lcgC = 0;
var lcgA = 48271;
var lcgPerturb = function (d) {
    return function (v) {
        return Seed(Data_Maybe.fromJust()(Data_Int.fromNumber($$Math.remainder(Data_Int.toNumber(lcgA) * Data_Int.toNumber(v) + d)(Data_Int.toNumber(lcgM)))));
    };
};
var lcgNext = lcgPerturb(Data_Int.toNumber(lcgC));
var eqSeed = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordSeed = new Data_Ord.Ord(function () {
    return eqSeed;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordInt)(x)(y);
    };
});
module.exports = {
    mkSeed: mkSeed,
    unSeed: unSeed,
    randomSeed: randomSeed,
    lcgA: lcgA,
    lcgC: lcgC,
    lcgM: lcgM,
    lcgNext: lcgNext,
    lcgPerturb: lcgPerturb,
    eqSeed: eqSeed,
    ordSeed: ordSeed,
    showSeed: showSeed
};
