// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_ProfilePicture = require("../ZAP.Dart.Client.Components.ProfilePicture/index.js");
var ZAP_Dart_Client_Components_Tile = require("../ZAP.Dart.Client.Components.Tile/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Contexts_AdvancedOpts = require("../ZAP.Dart.Client.Contexts.AdvancedOpts/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    topContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    bottomContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(30) ]),
    fullName: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(5.0e-2), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(10) ]),
    editProfileBtnContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(35), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(118), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(15), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(4), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(2) ]),
    editProfileIcon: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.intMarginRight)(4) ]),
    tilePairContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(30) ]),
    tileContent: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    tileValue: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    tileName: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(3), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    menuBtnContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(0), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(33), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(48), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.flexStart(React_Basic_Native_Styles_Flex.justifyContentFlexSE)) ]),
    menuIcon: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.intMarginRight)(12) ]),
    button: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    buttonText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ])
};
var profileButton = function (b) {
    return React_Basic_Native_Generated.touchableOpacity()({
        style: React_Basic_Native_Styles["styles'"]([ sheet.button, React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(b.backgroundColor) ]), b.containerStyle ]),
        onPress: React_Basic_Events.handler_(b.onPress),
        children: [ b.icon, b.text ]
    });
};
var component = React_Basic.createComponent("Profile");
var profile = function (v) {
    var initialState = {};
    var didMount = function (self) {
        return self.props.updateTotalPoints(new Data_Maybe.Just(self.props.initD.profile.redeemable.value));
    };
    var createNameContainer = function (value) {
        return ZAP_Dart_Client_Components_Typography.h3({
            style: sheet.fullName,
            color: React_Basic_Native_PropTypes_Color.black,
            value: value
        });
    };
    var formatName = function (firstName) {
        return function (lastName) {
            if (firstName instanceof Data_Maybe.Just && lastName instanceof Data_Maybe.Just) {
                return createNameContainer(firstName.value0 + (" " + lastName.value0));
            };
            if (firstName instanceof Data_Maybe.Just) {
                return createNameContainer(firstName.value0);
            };
            if (lastName instanceof Data_Maybe.Just) {
                return createNameContainer(lastName.value0);
            };
            return Data_Monoid.mempty(React_Basic.monoidJSX);
        };
    };
    var topContainer = function (v1) {
        return React_Basic_Native_Generated.view()({
            style: sheet.topContainer,
            children: [ ZAP_Dart_Client_Components_ProfilePicture.profilePicture({
                width: 125,
                height: 125,
                avatarUrl: v1.initD.profile.avatarUrl
            }), formatName(v1.initD.profile.firstName)(v1.initD.profile.lastName), profileButton({
                title: "Edit Profile",
                onPress: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.EditProfile.value)),
                containerStyle: sheet.editProfileBtnContainer,
                backgroundColor: ZAP_Dart_Client_Styles_Colors.primary,
                text: ZAP_Dart_Client_Components_Typography.b3({
                    color: ZAP_Dart_Client_Styles_Colors.bg,
                    style: sheet.buttonText,
                    value: "Edit Profile"
                }),
                icon: React_Basic_Native_Generated.view()({
                    style: sheet.editProfileIcon,
                    children: [ ZAP_Dart_Client_Components_Icon.icon({
                        name: "profile_edit_dark_outlined",
                        size: 19,
                        color: React_Basic_Native_PropTypes_Color.white
                    }) ]
                })
            }) ]
        });
    };
    var createMiddleContainer = function (v1) {
        return React_Basic_Native_Generated.view()({
            style: sheet.tileContent,
            children: [ ZAP_Dart_Client_Components_Typography.h3({
                style: sheet.tileValue,
                color: React_Basic_Native_PropTypes_Color.rgb(0)(169)(164),
                value: v1.value
            }), ZAP_Dart_Client_Components_Typography.b4({
                style: sheet.tileName,
                color: React_Basic_Native_PropTypes_Color.black,
                value: v1.name
            }) ]
        });
    };
    var middleContainer = function (v1) {
        return ZAP_Dart_Client_Components_Tile.tilePair({
            style: sheet.tilePairContainer,
            left: Control_Applicative.pure(Control_Applicative.applicativeArray)(createMiddleContainer({
                value: Data_Number_Format.toString(Data_HugeNum.toNumber(v1.initD.profile.redeemable.value)),
                name: Data_Maybe.fromMaybe("Points")(v1.initD.profile.redeemable.currencyName)
            })),
            right: Control_Applicative.pure(Control_Applicative.applicativeArray)(createMiddleContainer({
                value: Data_Show.show(Data_Show.showInt)(v1.initD.profile.rewardsPurchasedCount),
                name: "Rewards Purchased"
            }))
        });
    };
    var createMenuButton = function (menuButton) {
        return profileButton({
            containerStyle: sheet.menuBtnContainer,
            title: menuButton.title,
            onPress: menuButton.action,
            backgroundColor: React_Basic_Native_PropTypes_Color.white,
            text: ZAP_Dart_Client_Components_Typography.b2({
                color: React_Basic_Native_PropTypes_Color.black,
                style: sheet.buttonText,
                value: menuButton.title
            }),
            icon: React_Basic_Native_Generated.view()({
                style: sheet.menuIcon,
                children: [ ZAP_Dart_Client_Components_Icon.icon({
                    name: menuButton.iconName,
                    size: 24,
                    color: React_Basic_Native_PropTypes_Color.black
                }) ]
            })
        });
    };
    var bottomContainer = function (v1) {
        return [ createMenuButton({
            title: "View Transactions",
            iconName: "transactions_filled",
            action: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.ViewTransactions.value))
        }), createMenuButton({
            title: "Reset Pin",
            iconName: "padlock_filled",
            action: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.ResetPin.value))
        }), createMenuButton({
            title: "Logout",
            iconName: "logout_filled",
            action: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.Logout.value))
        }), ZAP_Dart_Client_Contexts_AdvancedOpts.advancedOptsConsumer(function (v2) {
            return Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(v2.value))(createMenuButton({
                title: "Advanced Options",
                iconName: "dev-opts",
                action: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.AdvancedOpts.value))
            }));
        }) ];
    };
    var render = function (v1) {
        return React_Basic_Native_Generated.scrollView()({
            children: [ React_Basic_Native_Generated.view()({
                style: sheet.container,
                children: [ topContainer(v1.props), middleContainer(v1.props), React_Basic_Native_Generated.view()({
                    style: sheet.bottomContainer,
                    children: bottomContainer(v1.props)
                }) ]
            }) ]
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        render: render
    });
};
module.exports = {
    profile: profile
};
