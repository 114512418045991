// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Promise = require("../Control.Promise/index.js");
var requestCameraPermission = Control_Promise.toAffE($foreign["_requestCameraPermission"]);
var openAppSettings = Control_Promise.toAffE($foreign["_openAppSettings"]);
var checkCameraPermission = Control_Promise.toAffE($foreign["_checkCameraPermission"]);
module.exports = {
    openAppSettings: openAppSettings,
    checkCameraPermission: checkCameraPermission,
    requestCameraPermission: requestCameraPermission,
    "_openAppSettings": $foreign["_openAppSettings"],
    "_checkCameraPermission": $foreign["_checkCameraPermission"],
    "_requestCameraPermission": $foreign["_requestCameraPermission"]
};
