// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Record = require("../Record/index.js");
var onboardingSlides = [ {
    text: "Buy specially marked " + ($foreign.importedBuildConfig.name + " products"),
    image: $foreign.getStarted1
}, {
    text: "Peel or scratch stickers to reveal QR codes",
    image: $foreign.getStarted2
}, {
    text: "Visit " + ($foreign.importedBuildConfig.url + " on your phone to earn points"),
    image: $foreign.getStarted3
}, {
    text: "Exchange your points for exciting rewards",
    image: $foreign.getStarted4
} ];
var buildConfig = Record.merge()()({
    coloredLogo: $foreign.coloredLogo,
    whiteLogo: $foreign.whiteLogo,
    onboardingSlides: onboardingSlides
})($foreign.importedBuildConfig);
module.exports = {
    buildConfig: buildConfig
};
