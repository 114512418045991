// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_Components_NavBar_CartMgtNavBar = require("../ZAP.Dart.Client.Components.NavBar.CartMgtNavBar/index.js");
var ZAP_Dart_Client_Screens_About = require("../ZAP.Dart.Client.Screens.About/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_CartMgtNavBar.cartMgtNavBar)(ReactNavigation_Types.NBTotalPoints.value);
var aboutRs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "about";
}))));
var aboutFlow = (function () {
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable.notNull(navBar)
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var afterInitF = function (v) {
        return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.PopToTop.value);
    };
    return ReactNavigation_AuthFlow.createAuthFlow(new Data_Symbol.IsSymbol(function () {
        return "about";
    }))(ReactNavigation.navCreatorStack)(ReactNavigation_AuthFlow.runAuthNavMFallBack)(aboutRs)(aboutRs.about)(Data_Function["const"](Data_Unit.unit))(configBuilder)(afterInitF);
})();
var aboutScreen = ReactNavigation.createFlowScreen()(aboutFlow)({
    about: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_About.about))
});
module.exports = {
    aboutScreen: aboutScreen
};
