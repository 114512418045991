// Generated by purs version 0.13.5
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Image = require("../ReactNative.Image/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_CheckBox = require("../ZAP.Dart.Client.Components.CheckBox/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_QtyInput = require("../ZAP.Dart.Client.Components.QtyInput/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    cartItemContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(20), React_Basic_Native_Styles.borderBottomWidth(React_Basic_Native_Styles.hairlineWidth), React_Basic_Native_Styles.borderBottomColor(ZAP_Dart_Client_Styles_Colors.separator) ]),
    cartItemSelected: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.lightPrimary) ]),
    cartItemUnselected: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ]),
    checkBoxContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.intMarginRight)(20) ]),
    imgContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.overflow(React_Basic_Native_PropTypes.hidden(React_Basic_Native_Styles.overflowV)), React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.intMarginRight)(20) ]),
    itemPhoto: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(100), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(130) ]),
    captionContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderBottomLeftRadius(5), React_Basic_Native_Styles.borderBottomRightRadius(5), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(25), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(10), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(8), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.bottom(React_Basic_Native_Styles.intBottom)(0), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgba(38)(38)(38)(0.8)) ]),
    captionText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.lineHeight(12) ]),
    itemMgtContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexWrap(React_Basic_Native_Styles_Flex.wrap), React_Basic_Native_Styles_Flex.flexShrink(1) ]),
    vendorName: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(5) ]),
    itemDescription: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.light), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(5) ]),
    itemPrice: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left), React_Basic_Native_Styles_Flex.flexWrap(React_Basic_Native_Styles_Flex.wrap), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(5) ]),
    qtyInputContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.bottom(React_Basic_Native_Styles.intBottom)(0) ])
};
var component = React_Basic.createComponent("CartItem");
var cartItem = (function () {
    var render = function (props) {
        if (props.cartItem.quantity instanceof Data_Maybe.Nothing) {
            return Data_Monoid.mempty(React_Basic.monoidJSX);
        };
        if (props.cartItem.quantity instanceof Data_Maybe.Just) {
            return React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.cartItemContainer, (function () {
                    if (props.marked) {
                        return sheet.cartItemSelected;
                    };
                    if (!props.marked) {
                        return sheet.cartItemUnselected;
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.CartItem (line 50, column 15 - line 52, column 50): " + [ props.marked.constructor.name ]);
                })() ]),
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.checkBoxContainer,
                    children: [ ZAP_Dart_Client_Components_CheckBox.checkBox({
                        isChecked: props.marked,
                        onPress: React_Basic_Events.handler_(props.onMarked(props.cartItem)),
                        color: ZAP_Dart_Client_Styles_Colors.altFg
                    }) ]
                }), React_Basic_Native_Generated.view()({
                    style: sheet.imgContainer,
                    children: [ ZAP_Dart_Client_Components_Image.image({
                        style: sheet.itemPhoto,
                        source: Data_Maybe.fromMaybe(ZAP_Dart_Client_Assets.defaultRewardItem)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.cartItem.photoUri)(ReactNative_Image.imageUri)),
                        resizeMode: React_Basic_Native_Styles.cover
                    }), Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (value) {
                        return React_Basic_Native_Generated.view()({
                            style: sheet.captionContainer,
                            children: [ ZAP_Dart_Client_Components_Typography.b3({
                                style: sheet.captionText,
                                color: ZAP_Dart_Client_Styles_Colors.bg,
                                value: value
                            }) ]
                        });
                    })(props.cartItem.stockCountLabel) ]
                }), React_Basic_Native_Generated.view()({
                    style: sheet.itemMgtContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.b4({
                        style: sheet.vendorName,
                        color: ZAP_Dart_Client_Styles_Colors.altFg,
                        value: Data_Maybe.fromMaybe("---")(props.cartItem.vendorName)
                    }), ZAP_Dart_Client_Components_Typography.b3({
                        style: sheet.itemDescription,
                        color: ZAP_Dart_Client_Styles_Colors.altFg,
                        value: Data_Maybe.fromMaybe("---")(props.cartItem.name)
                    }), ZAP_Dart_Client_Components_Typography.b3({
                        style: sheet.itemPrice,
                        color: ZAP_Dart_Client_Styles_Colors.primary,
                        value: Data_Maybe.fromMaybe("---")(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.cartItem.price)(function (p) {
                            return Data_Show.show(Data_Show.showNumber)(Data_HugeNum.toNumber(p));
                        }))(function (s) {
                            return s + " PTS";
                        }))
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.qtyInputContainer,
                        children: [ ZAP_Dart_Client_Components_QtyInput.qtyInput({
                            value: props.cartItem.quantity.value0,
                            onChange: function (qty) {
                                return props.onUpdate({
                                    productId: props.cartItem.productId,
                                    quantity: qty - props.cartItem.quantity.value0 | 0
                                });
                            },
                            addButtonDisabled: false
                        }) ]
                    }) ]
                }) ]
            });
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.CartItem (line 43, column 18 - line 120, column 10): " + [ props.cartItem.quantity.constructor.name ]);
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    cartItem: cartItem
};
