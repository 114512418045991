// Generated by purs version 0.13.5
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Layout = require("../ReactNative.Layout/index.js");
var ReactNative_StatusBar = require("../ReactNative.StatusBar/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_External_Swiper = require("../ZAP.Dart.Client.Components.External.Swiper/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    contentWrapper: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(15), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.white) ]),
    contentContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(24) ]),
    logo: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.flex(3), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(70)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.resizeMode(React_Basic_Native_Styles.contain) ]),
    swiperContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(8) ]),
    slideContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    footerWrapper: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(3), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(32) ]),
    prevButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.left(React_Basic_Native_Styles.intLeft)(12) ]),
    nextButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.right(React_Basic_Native_Styles.intRight)(12) ]),
    singleSlideContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    slideDescription: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(2), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(20), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(40) ]),
    slideDescriptionTxt: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.percentMaxWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(80)), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.7) ]),
    slideImage: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(5), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(80)), React_Basic_Native_Styles.resizeMode(React_Basic_Native_Styles.contain) ]),
    spacer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(40) ]),
    activeDot: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    bottomArea: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(0) ])
};
var component = React_Basic.createComponent("GetStarted");
var gettingStarted = (function () {
    var render = function (self) {
        var swiperButtonLoc = React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.top(React_Basic_Native_Styles.numberTop)(-self.state.swiperContainerHeight / 2.0) ]);
        return React_Basic.fragment([ React_Basic_Native_Generated.safeAreaView()({
            style: sheet.bottomArea
        }), React_Basic_Native_Generated.safeAreaView()({
            style: sheet.container,
            children: [ ReactNative_StatusBar.statusBar()({
                backgroundColor: ZAP_Dart_Client_Styles_Colors.bg,
                barStyle: ReactNative_StatusBar.darkContent
            }), React_Basic_Native_Generated.view()({
                style: sheet.contentWrapper,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.contentContainer,
                    children: [ React_Basic_Native_Generated.image()({
                        style: sheet.logo,
                        source: ZAP_Dart_Client_BuildConfig.buildConfig.coloredLogo
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.swiperContainer,
                        onLayout: React_Basic_Events.handler(React_Basic_DOM_Events.nativeEvent)(function (e) {
                            return self.setState(function (v) {
                                return {
                                    swiperContainerHeight: (ReactNative_Layout.getLayoutRect(e)).height
                                };
                            });
                        }),
                        children: [ ZAP_Dart_Client_Components_External_Swiper.swiper()({
                            activeDotStyle: sheet.activeDot,
                            prevButtonElement: React_Basic_Native_Generated.view()({
                                style: React_Basic_Native_Styles["styles'"]([ sheet.prevButton, swiperButtonLoc ]),
                                children: [ ZAP_Dart_Client_Components_Icon.icon({
                                    name: "left",
                                    size: 24,
                                    color: ZAP_Dart_Client_Styles_Colors.primary
                                }) ]
                            }),
                            nextButtonElement: React_Basic_Native_Generated.view()({
                                style: React_Basic_Native_Styles["styles'"]([ sheet.nextButton, swiperButtonLoc ]),
                                children: [ ZAP_Dart_Client_Components_Icon.icon({
                                    name: "right",
                                    size: 24,
                                    color: ZAP_Dart_Client_Styles_Colors.primary
                                }) ]
                            }),
                            children: Data_Functor.mapFlipped(Data_Functor.functorArray)(ZAP_Dart_Client_BuildConfig.buildConfig.onboardingSlides)(function (v) {
                                return React_Basic_Native_Generated.view()({
                                    style: sheet.singleSlideContainer,
                                    children: [ React_Basic_Native_Generated.image()({
                                        style: sheet.slideImage,
                                        source: v.image
                                    }), React_Basic_Native_Generated.view()({
                                        style: sheet.slideDescription,
                                        children: [ ZAP_Dart_Client_Components_Typography.b2({
                                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                                            value: v.text,
                                            style: sheet.slideDescriptionTxt
                                        }) ]
                                    }), React_Basic_Native_Generated.view()({
                                        style: sheet.spacer,
                                        children: [  ]
                                    }) ]
                                });
                            })
                        }) ]
                    }) ]
                }) ]
            }), React_Basic_Native_Generated.view()({
                style: sheet.footerWrapper,
                children: [ ZAP_Dart_Client_Components_Button.primaryButton({
                    onPress: self.props.onAction(new ReactNavigation_Types.PgSubmit(Data_Unit.unit)),
                    title: "GET STARTED",
                    disabled: false,
                    inverted: true,
                    size: ZAP_Dart_Client_Components_Button.lg,
                    style: React_Basic_Native_Styles["styles'"]([  ])
                }) ]
            }) ]
        }) ]);
    };
    var initialState = {
        swiperContainerHeight: 0.0
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
})();
module.exports = {
    gettingStarted: gettingStarted
};
