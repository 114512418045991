// Generated by purs version 0.13.5
"use strict";
var React_Basic_Native_Internal = require("../React.Basic.Native.Internal/index.js");
var KeyboardShouldPersistTaps = function (x) {
    return x;
};
var never = "never";
var handled = "handled";
var flatList = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("FlatList")(props);
    };
};
var always = "always";
module.exports = {
    never: never,
    always: always,
    handled: handled,
    flatList: flatList
};
