// Generated by purs version 0.13.5
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var repeat = function (dictMonoid) {
    var repeat$prime = function ($copy_accum) {
        return function ($copy_part) {
            return function ($copy_count) {
                var $tco_var_accum = $copy_accum;
                var $tco_var_part = $copy_part;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(accum, part, count) {
                    if (count < 1) {
                        $tco_done = true;
                        return accum;
                    };
                    $tco_var_accum = Data_Semigroup.append(dictMonoid.Semigroup0())(accum)(part);
                    $tco_var_part = part;
                    $copy_count = count - 1 | 0;
                    return;
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_accum, $tco_var_part, $copy_count);
                };
                return $tco_result;
            };
        };
    };
    return repeat$prime(Data_Monoid.mempty(dictMonoid));
};
var foldDigits = function (dictFoldable) {
    return Data_Foldable.foldl(dictFoldable)(function (acc) {
        return function (d) {
            return (acc * 10 | 0) + d | 0;
        };
    })(0);
};
module.exports = {
    foldDigits: foldDigits,
    repeat: repeat
};
