// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Promise = require("../Control.Promise/index.js");
var ReactNative_Platform = require("../ReactNative.Platform/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var hasVideoInputDevice = Control_Promise.toAffE($foreign["_hasVideoInputDevice"]);
var clientOs = (function () {
    if (ReactNative_Platform.os instanceof ReactNative_Platform.IOS) {
        return ZAP_Dart_Common.Ios.value;
    };
    if (ReactNative_Platform.os instanceof ReactNative_Platform.Android) {
        return ZAP_Dart_Common.Android.value;
    };
    if (ReactNative_Platform.os instanceof ReactNative_Platform.Web) {
        return ZAP_Dart_Common.Web.value;
    };
    if (ReactNative_Platform.os instanceof ReactNative_Platform.OSOther) {
        return new ZAP_Dart_Common.Others({
            device: ReactNative_Platform.os.value0
        });
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Utilities.DeviceInfo (line 15, column 12 - line 19, column 47): " + [ ReactNative_Platform.os.constructor.name ]);
})();
module.exports = {
    hasVideoInputDevice: hasVideoInputDevice,
    clientOs: clientOs,
    "_hasVideoInputDevice": $foreign["_hasVideoInputDevice"]
};
