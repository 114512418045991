// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_ApiClient_Utils = require("../ZAP.Dart.Client.ApiClient.Utils/index.js");
var ZAP_Dart_Client_Components_NavBar_SimpleNavBar = require("../ZAP.Dart.Client.Components.NavBar.SimpleNavBar/index.js");
var ZAP_Dart_Client_Dialogs_ScanError = require("../ZAP.Dart.Client.Dialogs.ScanError/index.js");
var ZAP_Dart_Client_Flows_Utils = require("../ZAP.Dart.Client.Flows.Utils/index.js");
var ZAP_Dart_Client_Screens_ClaimReceipt = require("../ZAP.Dart.Client.Screens.ClaimReceipt/index.js");
var ZAP_Dart_Client_Screens_CompleteProfile = require("../ZAP.Dart.Client.Screens.CompleteProfile/index.js");
var ZAP_Dart_Client_Screens_CompletionProfileStatus = require("../ZAP.Dart.Client.Screens.CompletionProfileStatus/index.js");
var ZAP_Dart_Client_Screens_EnterShortCode = require("../ZAP.Dart.Client.Screens.EnterShortCode/index.js");
var ZAP_Dart_Client_Screens_ScanQr = require("../ZAP.Dart.Client.Screens.ScanQr/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Utilities_DeviceInfo = require("../ZAP.Dart.Client.Utilities.DeviceInfo/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "scanQr";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "enterShortCode";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "completionProfileStatus";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "completeProfile";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "claimReceipt";
}))));
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_SimpleNavBar.simpleNavBar)(new ReactNavigation_Types.NBTitle("EARN POINTS"));
var flow = (function () {
    var getCode = function (v) {
        if (v instanceof ZAP_Dart_Client_Screens_Types.SQVal) {
            return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(v.value0);
        };
        if (v instanceof ZAP_Dart_Client_Screens_Types.SQGoToEnterShortCodeScreen) {
            return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "enterShortCode";
            }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.enterShortCode))(function (v1) {
                if (v1 instanceof ZAP_Dart_Client_Screens_Types.SCIMScanQr) {
                    return ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_AuthFlow.monadNMAuthNavM);
                };
                if (v1 instanceof ZAP_Dart_Client_Screens_Types.SCIMManual) {
                    return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)({
                        claimCode: new ZAP_Dart_Common.ShortCode({
                            val: v1.value0
                        }),
                        releaseHook: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
                    });
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.ScanQr (line 67, column 7 - line 69, column 107): " + [ v1.constructor.name ]);
            });
        };
        if (v instanceof ZAP_Dart_Client_Screens_Types.SQClose) {
            return ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_AuthFlow.monadNMAuthNavM);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.ScanQr (line 63, column 13 - line 70, column 25): " + [ v.constructor.name ]);
    };
    var end = Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.PopToTop.value);
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable.notNull(navBar)
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var completeProfileInfo = function (clientApi) {
        return {
            initD: clientApi.getProfile,
            apiCalls: {
                updateProfile: clientApi.updateProfile
            }
        };
    };
    var afterInitF = function (v) {
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.SQClose) {
            return end;
        };
        return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(getCode(v.value))(function (v1) {
            return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ZAP_Dart_Client_ApiClient_Utils["acallApi'"](ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)(v.info.clientApi.claim)({
                claimCode: v1.claimCode,
                clientOs: new Data_Maybe.Just(ZAP_Dart_Client_Utilities_DeviceInfo.clientOs)
            }))(function (v2) {
                if (v2 instanceof Data_Either.Right) {
                    return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_Class_MonadNavM.navPushI(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                        return "claimReceipt";
                    }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.claimReceipt)(v2.value0))(function (v3) {
                        if (v3 instanceof ZAP_Dart_Client_Screens_Types.CRCompleteProfile) {
                            return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                                return "completeProfile";
                            }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow["processInfosRLConsInitD'"](ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
                                return "initD";
                            }))()()())(new Data_Symbol.IsSymbol(function () {
                                return "apiCalls";
                            }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
                                return "updateProfile";
                            }))()()())()()()())(rs.completeProfile)(completeProfileInfo(v.info.clientApi)))(function (v4) {
                                if (v4 instanceof ZAP_Dart_Client_Screens_Types.CompleteProfileMaybeLater) {
                                    return end;
                                };
                                if (v4 instanceof ZAP_Dart_Client_Screens_Types.CompleteProfileSuccess) {
                                    return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                                        return "completionProfileStatus";
                                    }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.completionProfileStatus))(end);
                                };
                                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.ScanQr (line 57, column 21 - line 59, column 88): " + [ v4.constructor.name ]);
                            });
                        };
                        return end;
                    });
                };
                if (v2 instanceof Data_Either.Left) {
                    return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_Class_MonadNavM.navDialogIC(ReactNavigation_AuthFlow.monadNMAuthNavM)(ZAP_Dart_Client_Dialogs_ScanError.scanErrDialog)({
                        claimCode: v1.claimCode,
                        error: v2.value0
                    })(v1.releaseHook))(end);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.ScanQr (line 51, column 7 - line 61, column 88): " + [ v2.constructor.name ]);
            });
        });
    };
    return ReactNavigation_AuthFlow.createAuthFlow(new Data_Symbol.IsSymbol(function () {
        return "scanQr";
    }))(ReactNavigation.navCreatorStack)(ReactNavigation_AuthFlow.runAuthNavMFallBack)(rs)(rs.scanQr)(Data_Function["const"](Data_Unit.unit))(configBuilder)(afterInitF);
})();
var scanQrScreen = (function () {
    var scanQr = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable["null"]
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_ScanQr.scanQr)
    });
    var completionProfileStatus = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("CONGRATULATIONS"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_CompletionProfileStatus.completionProfileStatus)
    });
    var completeProfile = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("COMPLETE PROFILE"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_CompleteProfile.completeProfile)
    });
    return ReactNavigation.createFlowScreen()(flow)({
        scanQr: scanQr,
        enterShortCode: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_EnterShortCode.enterShortCode)),
        claimReceipt: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_ClaimReceipt.claimReceipt)),
        completeProfile: completeProfile,
        completionProfileStatus: completionProfileStatus
    });
})();
module.exports = {
    scanQrScreen: scanQrScreen
};
