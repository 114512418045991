// Generated by purs version 0.13.5
"use strict";
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var ZAP_Dart_Client_Components_NavBar_BaseNavBar = require("../ZAP.Dart.Client.Components.NavBar.BaseNavBar/index.js");
var simpleNavBar = (function () {
    var right = function (v) {
        return Data_Monoid.mempty(React_Basic.monoidJSX);
    };
    return ZAP_Dart_Client_Components_NavBar_BaseNavBar.baseNavBar({
        right: right,
        persistBack: true
    });
})();
module.exports = {
    simpleNavBar: simpleNavBar
};
