// Generated by purs version 0.13.5
"use strict";
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Image = require("../ReactNative.Image/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_Card = require("../ZAP.Dart.Client.Components.Card/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var textColor = React_Basic_Native_PropTypes_Color.rgb(38)(36)(30);
var sheet = {
    card: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRadius(5) ]),
    touchableCard: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(10) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(7) ]),
    vendorLogo: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(48), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(16) ]),
    bodyContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.overflow(React_Basic_Native_PropTypes.hidden(React_Basic_Native_Styles.overflowV)), React_Basic_Native_Styles.flex(1) ]),
    itemPhoto: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    captionContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderBottomLeftRadius(5), React_Basic_Native_Styles.borderBottomRightRadius(5), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(55), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(10), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(8), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.bottom(React_Basic_Native_Styles.intBottom)(0), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgba(38)(38)(38)(0.8)) ]),
    footerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(7) ]),
    itemPriceContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderColor(textColor), React_Basic_Native_Styles.borderWidth(React_Basic_Native_Styles.hairlineWidth), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(22), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(50), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(2), React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.intMarginRight)(5) ]),
    itemPrice: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.extraBold) ]),
    addToCartButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    proximaNovaBold: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold) ]),
    noStyle: React_Basic_Native_Styles.staticStyles([  ])
};
var component = React_Basic.createComponent("CatalogItemCard");
var catalogItemCard = (function () {
    var render = function (v) {
        var price = (function () {
            if (v.item.price instanceof Data_Maybe.Nothing) {
                return Data_Monoid.mempty(Data_Monoid.monoidString);
            };
            if (v.item.price instanceof Data_Maybe.Just) {
                return ZAP_Dart_Client_Screens_Utils.formatPoints(v.item.price.value0) + " PTS";
            };
            throw new Error("Failed pattern match at ZAP.Dart.Components.CatalogItemCard (line 126, column 13 - line 128, column 41): " + [ v.item.price.constructor.name ]);
        })();
        var header = React_Basic_Native_Generated.view()({
            style: sheet.headerContainer,
            children: [ ZAP_Dart_Client_Components_Image.image({
                style: sheet.vendorLogo,
                source: Data_Maybe.fromMaybe(ZAP_Dart_Client_Assets.defaultRewardItem)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.item.vendorColoredLogoUri)(ReactNative_Image.imageUri)),
                resizeMode: React_Basic_Native_Styles.contain
            }), ZAP_Dart_Client_Components_Typography.b4({
                style: sheet.noStyle,
                color: textColor,
                value: Data_Maybe.fromMaybe("")(v.item.stockCountLabel)
            }) ]
        });
        var footer = React_Basic_Native_Generated.view()({
            style: sheet.footerContainer,
            children: [ React_Basic_Native_Generated.view()({
                style: sheet.itemPriceContainer,
                children: [ ZAP_Dart_Client_Components_Typography.b5({
                    style: sheet.itemPrice,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: price
                }) ]
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.addToCartButton,
                title: "ADD TO CART",
                onPress: v.addToCart,
                disabled: false,
                inverted: false,
                size: ZAP_Dart_Client_Components_Button.xs
            }) ]
        });
        var cardHeight = React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)((function () {
            if (v.cardWidth < 180) {
                return 192;
            };
            return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v.cardWidth * 16 | 0)(15);
        })()) ]);
        var body = React_Basic_Native_Generated.view()({
            style: sheet.bodyContainer,
            children: [ ZAP_Dart_Client_Components_Image.image({
                style: sheet.itemPhoto,
                source: Data_Maybe.fromMaybe(ZAP_Dart_Client_Assets.defaultRewardItem)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.item.photoUri)(ReactNative_Image.imageUri)),
                resizeMode: React_Basic_Native_Styles.cover
            }), React_Basic_Native_Generated.view()({
                style: sheet.captionContainer,
                children: [ ZAP_Dart_Client_Components_Typography.b4({
                    style: sheet.proximaNovaBold,
                    color: ZAP_Dart_Client_Styles_Colors.bg,
                    value: Data_String_Common.toUpper(Data_Maybe.fromMaybe("")(v.item.vendorName))
                }), ZAP_Dart_Client_Components_Typography.b4({
                    style: sheet.noStyle,
                    color: ZAP_Dart_Client_Styles_Colors.bg,
                    value: Data_Maybe.fromMaybe("")(v.item.name)
                }) ]
            }) ]
        });
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(v.cardWidth) ]),
            children: [ ZAP_Dart_Client_Components_Card.card({
                style: React_Basic_Native_Styles["styles'"]([ sheet.card, ZAP_Dart_Client_Styles_Utils.styleDisabled(Data_Maybe.isNothing(v.item.price)) ]),
                children: [ React_Basic_Native_Generated.touchableOpacity()({
                    style: React_Basic_Native_Styles["styles'"]([ sheet.touchableCard, cardHeight ]),
                    onPress: React_Basic_Events.handler_(v.onPress),
                    disabled: Data_Maybe.isNothing(v.item.price),
                    children: [ header, body, footer ]
                }) ]
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    catalogItemCard: catalogItemCard
};
