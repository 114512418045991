// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Dimensions = require("../ReactNative.Dimensions/index.js");
var ReactNative_Image = require("../ReactNative.Image/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_EmptyPage = require("../ZAP.Dart.Client.Components.EmptyPage/index.js");
var ZAP_Dart_Client_Components_External_Swiper = require("../ZAP.Dart.Client.Components.External.Swiper/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_InfiniteList = require("../ZAP.Dart.Client.Components.InfiniteList/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Constants = require("../ZAP.Dart.Client.Constants/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Components_CatalogItemCard = require("../ZAP.Dart.Components.CatalogItemCard/index.js");
var slideComponent = React_Basic.createComponent("Slide");
var listPaddingHorizontal = 14;
var listItemPadding = 6;
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.altBg) ]),
    listContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(listPaddingHorizontal), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(40), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.altBg), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    listItemContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(listItemPadding) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(9), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(20), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(listItemPadding) ]),
    sectionTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold) ]),
    emptyText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(60), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    activeDot: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    slideContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.intMarginRight)(3) ]),
    imageContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ]),
    image: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.overflow(React_Basic_Native_PropTypes.hidden(React_Basic_Native_Styles.overflowV)) ]),
    spacer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(40) ])
};
var slide$prime = (function () {
    var render = function (v) {
        var imageHeight$prime = (function () {
            if (v.deviceWidth < 412) {
                return 153;
            };
            if (v.deviceWidth > ZAP_Dart_Client_Constants.maxScreenWidth) {
                return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(ZAP_Dart_Client_Constants.maxScreenWidth * 153 | 0)(412);
            };
            return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v.deviceWidth * 153 | 0)(412);
        })();
        var imageDim = React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(imageHeight$prime) ]);
        return React_Basic_Native_Generated.touchableOpacity()({
            style: sheet.slideContainer,
            onPress: React_Basic_Events.handler_(ZAP_Dart_Client_Screens_Utils.openUrl(v.slide.url)),
            activeOpacity: 1.0,
            children: [ React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.imageContainer, imageDim ]),
                children: [ ZAP_Dart_Client_Components_Image.image({
                    style: sheet.image,
                    source: ReactNative_Image.imageUri(v.slide.image),
                    resizeMode: React_Basic_Native_Styles.cover
                }) ]
            }), React_Basic_Native_Generated.view()({
                style: sheet.spacer,
                children: [  ]
            }) ]
        });
    };
    return React_Basic.makeStateless(slideComponent)(render);
})();
var getTotalPaddingHorizontal = function (numColumns) {
    return (listPaddingHorizontal * 2 | 0) + (listItemPadding * (numColumns * 2 | 0) | 0) | 0;
};
var getCardWidth = function (deviceWidth) {
    return function (numColumns) {
        var cardWidth = function (dWidth) {
            return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(dWidth - getTotalPaddingHorizontal(numColumns) | 0)(numColumns);
        };
        if (deviceWidth < ZAP_Dart_Client_Constants.maxScreenWidth) {
            return cardWidth(deviceWidth);
        };
        return cardWidth(ZAP_Dart_Client_Constants.maxScreenWidth);
    };
};
var count = 10;
var component = React_Basic.createComponent("Catalog");
var catalogHeaderComponent = React_Basic.createComponent("CatalogHeader");
var catalogHeader = (function () {
    var featuredSlides = function (v) {
        var imageHeight$prime = (function () {
            if (v.deviceWidth < 412) {
                return 193;
            };
            if (v.deviceWidth > ZAP_Dart_Client_Constants.maxScreenWidth) {
                return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(ZAP_Dart_Client_Constants.maxScreenWidth * 193 | 0)(412);
            };
            return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v.deviceWidth * 193 | 0)(412);
        })();
        var imageDim = React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(imageHeight$prime) ]);
        var empty = React_Basic_Native_Generated.view_([  ]);
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(10) ]),
            children: [ ZAP_Dart_Client_Components_External_Swiper.swiper()({
                activeDotStyle: sheet.activeDot,
                prevButtonElement: empty,
                nextButtonElement: empty,
                containerStyle: imageDim,
                children: Data_Functor.mapFlipped(Data_Functor.functorArray)(v.slides)(function (sl) {
                    return slide$prime({
                        slide: sl,
                        deviceWidth: v.deviceWidth
                    });
                })
            }) ]
        });
    };
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: sheet.headerContainer,
            children: [ ZAP_Dart_Client_Components_Typography.h5({
                style: sheet.sectionTitle,
                color: ZAP_Dart_Client_Styles_Colors.darkPrimary,
                value: "Featured"
            }), featuredSlides({
                slides: ZAP_Dart_Client_BuildConfig.buildConfig.banners,
                deviceWidth: v.deviceWidth
            }), ZAP_Dart_Client_Components_Typography.h5({
                style: sheet.sectionTitle,
                color: ZAP_Dart_Client_Styles_Colors.darkPrimary,
                value: "All Rewards"
            }) ]
        });
    };
    return React_Basic.makeStateless(catalogHeaderComponent)(render);
})();
var breakpoint = 532;
var catalog = function (v) {
    var initialState = {
        deviceWidth: ReactNative_Dimensions.getWindow.width
    };
    var fetchCatalogItemsFn = function (self) {
        return function (v1) {
            return function (marker) {
                return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(v.apiCalls.listCatalogItems({
                    count: count,
                    marker: marker
                }))(function (v2) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Effect_Class.liftEffect(Control_Monad_Except_Trans.monadEffectExceptT(Effect_Aff.monadEffectAff))(self.props.updateTotalPoints(v2.totalPoints)))(function () {
                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff))({
                            items: v2.items,
                            marker: v2.marker
                        });
                    });
                });
            };
        };
    };
    var dimHandler = function (self) {
        return function (v1) {
            return self.setState(function (v2) {
                var $26 = {};
                for (var $27 in v2) {
                    if ({}.hasOwnProperty.call(v2, $27)) {
                        $26[$27] = v2[$27];
                    };
                };
                $26.deviceWidth = v1.window.width;
                return $26;
            });
        };
    };
    var willUnmount = function (self) {
        return ReactNative_Dimensions.removeEventListener(ReactNative_Dimensions.change)(dimHandler(self));
    };
    var didMount = function (self) {
        return ReactNative_Dimensions.addEventListener(ReactNative_Dimensions.change)(dimHandler(self));
    };
    var addToCart = function (self) {
        return function (productId) {
            return function (productName) {
                var showAddedToCartAlert = self.props.showDialog(new ReactNavigation_Types.Confirm({
                    title: "Item added to cart",
                    message: "Continue browsing or proceed to checkout?",
                    actionLabel: "CHECKOUT"
                }))(self.props.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.GoToCart.value)));
                var onResolve = function (v1) {
                    if (v1 instanceof Data_Either.Right) {
                        return function __do() {
                            self.props.updateTotalPoints(v1.value0.totalPoints)();
                            return showAddedToCartAlert();
                        };
                    };
                    if (v1 instanceof Data_Either.Left) {
                        return self.props.showDialog(ZAP_Dart_Client_Screens_Utils.addToCartErr(v1.value0)(productName))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.Catalog (line 168, column 17 - line 175, column 22): " + [ v1.constructor.name ]);
                };
                var updateCart = ZAP_Dart_Client_Screens_Utils.mkLApi(v.apiCalls.updateCart({
                    productQuantity: {
                        productId: productId,
                        quantity: 1
                    }
                }))(onResolve);
                return self.props.loadApiP(updateCart);
            };
        };
    };
    var render = function (self) {
        var numColumns = (function () {
            if (self.state.deviceWidth < breakpoint) {
                return 2;
            };
            return 3;
        })();
        var cardWidth = getCardWidth(self.state.deviceWidth)(numColumns);
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ ZAP_Dart_Client_Components_InfiniteList.infiniteList(Data_Eq.eqUnit)({
                contentContainerStyle: sheet.listContainer,
                key: "catalog-" + Data_Show.show(Data_Show.showInt)(numColumns),
                fetchFn: fetchCatalogItemsFn(self)(Data_Unit.unit),
                header: catalogHeader({
                    deviceWidth: self.state.deviceWidth
                }),
                renderItem: function (v1) {
                    return React_Basic_Native_Generated.view()({
                        style: sheet.listItemContainer,
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Components_CatalogItemCard.catalogItemCard({
                            cardWidth: cardWidth,
                            item: v1.item,
                            addToCart: addToCart(self)(v1.item.productId)(v1.item.name),
                            onPress: self.props.onAction(new ReactNavigation_Types.PgSubmit(new ZAP_Dart_Client_Screens_Types.GoToCatalogItem(v1.item.productId)))
                        }))
                    });
                },
                keyExtractor: function (v1) {
                    return v1.item.productId;
                },
                numColumns: numColumns,
                onRefresh: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
                params: Data_Unit.unit,
                runLApi: self.props.runLApi,
                isFocused: self.props.focusState.isFocused,
                emptyComponent: ZAP_Dart_Client_Components_EmptyPage.emptyPage({
                    image: ZAP_Dart_Client_Assets.emptyCatalog,
                    message: {
                        primary: "Coming soon",
                        secondary: "Hang on tight! Rewards will be available soon"
                    }
                })
            }) ]
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        willUnmount: willUnmount,
        render: render
    });
};
module.exports = {
    catalog: catalog
};
