// Generated by purs version 0.13.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    midRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(12), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(20) ]),
    bottomRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch) ]),
    titleText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(20), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.lineHeight(24), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(2), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold) ]),
    messageText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(16), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.5), React_Basic_Native_Styles_Text.lineHeight(18), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(16), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(1), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.fgMuted) ]),
    primaryActionText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(16), React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.lineHeight(21), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.8), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ])
};
var sc = React_Basic.createComponent("SingleActionUi");
var singleActionUi = (function () {
    var render = function (v) {
        return React_Basic.fragment([ React_Basic_Native_Generated.view()({
            style: sheet.midRow,
            children: Data_Array.singleton(React_Basic_Native_Generated.text()({
                style: sheet.titleText,
                children: [ React_Basic_Native.string(v.title) ]
            }))
        }), React_Basic_Native_Generated.view()({
            style: sheet.midRow,
            children: Data_Array.singleton(React_Basic_Native_Generated.text()({
                style: sheet.messageText,
                children: [ React_Basic_Native.string(v.message) ]
            }))
        }), React_Basic_Native_Generated.view()({
            style: sheet.divider,
            children: [  ]
        }), React_Basic_Native_Generated.touchableOpacity()({
            onPress: React_Basic_Events.handler_(v.primaryAction.onPress),
            style: sheet.bottomRow,
            children: [ React_Basic_Native_Generated.text()({
                style: sheet.primaryActionText,
                children: [ React_Basic_Native.string(v.primaryAction.label) ]
            }) ]
        }) ]);
    };
    return React_Basic.makeStateless(sc)(render);
})();
var component = React_Basic.createComponent("SingleActionDialog");
var singleActionDialog = function (f) {
    var render = function (props) {
        var onAction$prime = function ($5) {
            return props.onAction(ReactNavigation_Types.DgSubmit.create($5));
        };
        var config = f(props.info)({
            onAction: onAction$prime,
            close: props.onAction(ReactNavigation_Types.DgClose.value)
        });
        return singleActionUi(config);
    };
    return React_Basic.makeStateless(component)(render);
};
module.exports = {
    singleActionDialog: singleActionDialog,
    singleActionUi: singleActionUi
};
