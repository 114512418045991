// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Components_External_Select = require("../ZAP.Dart.Client.Components.External.Select/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Input_Styles = require("../ZAP.Dart.Client.Components.Input.Styles/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    iconContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.right(React_Basic_Native_Styles.intRight)(10), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.zIndex(-1 | 0) ])
};
var component = React_Basic.createComponent("Dropdown");
var dropdown = (function () {
    var render = function (props) {
        return React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_External_Select.select({
            style: React_Basic_Native_Styles.styles(Data_Semigroup.append(Data_Semigroup.semigroupArray)(ZAP_Dart_Client_Components_Input_Styles.containerStyles)([ React_Basic_Native_Styles.paddingRight(React_Basic_Native_Styles.intPaddingRight)(30) ])),
            value: props.value,
            onChange: props.onChange,
            items: props.items,
            placeholder: props.placeholder
        }), React_Basic_Native_Generated.view()({
            style: sheet.iconContainer,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Icon.icon({
                name: "down",
                size: 20,
                color: ZAP_Dart_Client_Styles_Colors.altFg
            }))
        }) ]);
    };
    return React_Basic.makeStateless(component)(render);
})();
var dropdown_ = function (props) {
    return dropdown({
        value: props.value,
        onChange: props.onChange,
        items: Data_Functor.mapFlipped(Data_Functor.functorArray)(props.items)(function (i) {
            return {
                label: i,
                value: i
            };
        }),
        placeholder: props.placeholder
    });
};
module.exports = {
    dropdown: dropdown,
    dropdown_: dropdown_
};
