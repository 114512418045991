// Generated by purs version 0.13.5
"use strict";
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    title: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(5) ])
};
var component = React_Basic.createComponent("FormSection");
var formSection = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: v.style,
            children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ ZAP_Dart_Client_Components_Typography.h4({
                style: sheet.title,
                color: ZAP_Dart_Client_Styles_Colors.altFg,
                value: v.title
            }) ])(v.children)
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    formSection: formSection
};
