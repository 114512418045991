// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Exists = require("../Data.Exists/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var ExpressRpc_Types = require("../ExpressRpc.Types/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var ZAP_Dart_Client_ApiClient_Utils = require("../ZAP.Dart.Client.ApiClient.Utils/index.js");
var ZAP_Dart_Client_Components_FullPageSpinner = require("../ZAP.Dart.Client.Components.FullPageSpinner/index.js");
var ZAP_Dart_Client_Contexts_Global = require("../ZAP.Dart.Client.Contexts.Global/index.js");
var ZAP_Dart_Client_Dialogs_Template_Alert = require("../ZAP.Dart.Client.Dialogs.Template.Alert/index.js");
var ZAP_Dart_Client_Dialogs_Template_Confirm = require("../ZAP.Dart.Client.Dialogs.Template.Confirm/index.js");
var ZAP_Dart_Client_Dialogs_Template_Wrapper = require("../ZAP.Dart.Client.Dialogs.Template.Wrapper/index.js");
var OnPgAction = function (onPgAction) {
    this.onPgAction = onPgAction;
};
var withPagePropsC = React_Basic.createComponent("WithPageProps");
var onPgAction = function (dict) {
    return dict.onPgAction;
};
var withPageProps = function (dictOnPgAction) {
    return function (pageComp) {
        return function (initProps) {
            var runApiM$prime = function (nav) {
                return function (cb) {
                    return function (onStart) {
                        return function (onEnd) {
                            return function (onNetworkError) {
                                return function (v) {
                                    var runner = (function () {
                                        var $77 = Data_Function.flip(Effect_Aff.runAff_);
                                        return function ($78) {
                                            return $77(ExpressRpc_Types.runApiM($78));
                                        };
                                    })();
                                    return Control_Apply.applySecond(Effect.applyEffect)(onStart)(runner(v.value0)(function (e) {
                                        return Control_Apply.applySecond(Effect.applyEffect)(onEnd)((function () {
                                            if (e instanceof Data_Either.Right && e.value0 instanceof Data_Either.Right) {
                                                return v.value1(e.value0.value0);
                                            };
                                            if (e instanceof Data_Either.Right && e.value0 instanceof Data_Either.Left) {
                                                return onPgAction(dictOnPgAction)(Type_Proxy["Proxy"].value)(nav)(cb)(new ReactNavigation_Types.PgErr(e.value0.value0));
                                            };
                                            if (e instanceof Data_Either.Left) {
                                                return onNetworkError;
                                            };
                                            throw new Error("Failed pattern match at ReactNavigation.Utils.HOCs.WithPageProps (line 114, column 24 - line 118, column 51): " + [ e.constructor.name ]);
                                        })());
                                    }));
                                };
                            };
                        };
                    };
                };
            };
            var onAction = function (self) {
                return function (out) {
                    return function __do() {
                        var v = React_Basic.readProps(self)();
                        var nav = ReactNavigation.getNavigator(v);
                        var cb = (Data_Newtype.unwrap(ReactNavigation_Types.navCbiNewtype)(ReactNavigation.getParam(v))).cb;
                        return onPgAction(dictOnPgAction)(Type_Proxy["Proxy"].value)(nav)(cb)(out)();
                    };
                };
            };
            var render = function (v) {
                var nav = ReactNavigation.getNavigator(v.props);
                var dialogWrapper = function (d) {
                    return ZAP_Dart_Client_Dialogs_Template_Wrapper.wrapper({
                        onClose: ReactNavigation.destroyDialog(nav),
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(d)
                    });
                };
                var createDialog$prime = ReactNavigation.createDialog(nav);
                var showDialog = function (v1) {
                    return function (f) {
                        if (v1 instanceof ReactNavigation_Types.Alert) {
                            return createDialog$prime(dialogWrapper(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog({
                                info: v1.value0,
                                onAction: function (v2) {
                                    if (v2 instanceof ReactNavigation_Types.DgSubmit) {
                                        return Control_Apply.applySecond(Effect.applyEffect)(f)(ReactNavigation.destroyDialog(nav));
                                    };
                                    if (v2 instanceof ReactNavigation_Types.DgClose) {
                                        return ReactNavigation.destroyDialog(nav);
                                    };
                                    throw new Error("Failed pattern match at ReactNavigation.Utils.HOCs.WithPageProps (line 232, column 15 - line 234, column 45): " + [ v2.constructor.name ]);
                                }
                            })));
                        };
                        if (v1 instanceof ReactNavigation_Types.Confirm) {
                            return createDialog$prime(dialogWrapper(ZAP_Dart_Client_Dialogs_Template_Confirm.confirmDialog({
                                info: v1.value0,
                                onAction: function (v2) {
                                    if (v2 instanceof ReactNavigation_Types.DgSubmit && v2.value0) {
                                        return Control_Apply.applySecond(Effect.applyEffect)(f)(ReactNavigation.destroyDialog(nav));
                                    };
                                    return ReactNavigation.destroyDialog(nav);
                                }
                            })));
                        };
                        throw new Error("Failed pattern match at ReactNavigation.Utils.HOCs.WithPageProps (line 225, column 5 - line 225, column 55): " + [ v1.constructor.name, f.constructor.name ]);
                    };
                };
                var cbi = ReactNavigation.getParam(v.props);
                var info = (Data_Newtype.unwrap(ReactNavigation_Types.navCbiNewtype)(cbi)).infoF(nav);
                var cb = (Data_Newtype.unwrap(ReactNavigation_Types.navCbiNewtype)(cbi)).cb;
                var loadApiA = Data_Exists.runExists(runApiM$prime(nav)(cb)(ReactNavigation.showSpinner(nav))(ReactNavigation.hideSpinner(nav))(ReactNavigation.createErrDialog(nav)(ZAP_Dart_Client_ApiClient_Utils.networkErrD)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
                var loadApiP = Data_Exists.runExists(runApiM$prime(nav)(cb)(v.setState(function (v1) {
                    return {
                        showSpinnerOverlay: true,
                        focusState: v1.focusState,
                        action: v1.action,
                        unsubs: v1.unsubs
                    };
                }))(v.setState(function (v1) {
                    return {
                        showSpinnerOverlay: false,
                        focusState: v1.focusState,
                        action: v1.action,
                        unsubs: v1.unsubs
                    };
                }))(ReactNavigation.createErrDialog(nav)(ZAP_Dart_Client_ApiClient_Utils.networkErrD)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
                var runLApi = function (apiCall) {
                    return function (onNetworkError) {
                        return Data_Exists.runExists(runApiM$prime(nav)(cb)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(onNetworkError))(apiCall);
                    };
                };
                return ZAP_Dart_Client_Contexts_Global.globalConsumer(function (v1) {
                    return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic.keyed("element")(React_Basic.fragment([ ZAP_Dart_Client_Components_FullPageSpinner.fullPageSpinner({
                        show: v.state.showSpinnerOverlay
                    }), pageComp(info)({
                        onAction: onAction(v),
                        showDialog: showDialog,
                        runLApi: runLApi,
                        loadApiA: loadApiA,
                        loadApiP: loadApiP,
                        focusState: v.state.focusState,
                        updateTotalPoints: v1.updateTotalPoints
                    }) ])));
                });
            };
            var emptyFocusState = {
                isFocused: false,
                isBlurred: false,
                isFocusing: false
            };
            var getInitialFocusState = {
                isFocused: Effect_Unsafe.unsafePerformEffect(ReactNavigation.getIsFocused(ReactNavigation.getNavigator(initProps))),
                isBlurred: emptyFocusState.isBlurred,
                isFocusing: emptyFocusState.isFocusing
            };
            var initialState = {
                showSpinnerOverlay: false,
                focusState: getInitialFocusState,
                unsubs: [  ],
                action: Data_Maybe.Nothing.value
            };
            var didUpdate = function (self) {
                return function (prevSelf) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            };
            var cleanNavListeners = function (self) {
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(self.state.unsubs)(Control_Category.identity(Control_Category.categoryFn));
            };
            var willUnmount = function (self) {
                return cleanNavListeners(self);
            };
            var bindNavListeners = function (self) {
                var willFocus = self.setState(function (v) {
                    var $52 = {};
                    for (var $53 in v) {
                        if ({}.hasOwnProperty.call(v, $53)) {
                            $52[$53] = v[$53];
                        };
                    };
                    $52.focusState = {
                        isFocusing: true,
                        isBlurred: emptyFocusState.isBlurred,
                        isFocused: emptyFocusState.isFocused
                    };
                    return $52;
                });
                var nav = ReactNavigation.getNavigator(self.props);
                var didFocus = self.setState(function (v) {
                    var $55 = {};
                    for (var $56 in v) {
                        if ({}.hasOwnProperty.call(v, $56)) {
                            $55[$56] = v[$56];
                        };
                    };
                    $55.focusState = {
                        isFocusing: emptyFocusState.isFocusing,
                        isBlurred: emptyFocusState.isBlurred,
                        isFocused: true
                    };
                    return $55;
                });
                var didBlur = self.setState(function (v) {
                    var $58 = {};
                    for (var $59 in v) {
                        if ({}.hasOwnProperty.call(v, $59)) {
                            $58[$59] = v[$59];
                        };
                    };
                    $58.focusState = {
                        isFocusing: emptyFocusState.isFocusing,
                        isBlurred: true,
                        isFocused: emptyFocusState.isFocused
                    };
                    return $58;
                });
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)([ ReactNavigation.addListener(nav)(ReactNavigation_Types.WillFocus.value)(willFocus), ReactNavigation.addListener(nav)(ReactNavigation_Types.DidBlur.value)(didBlur), ReactNavigation.addListener(nav)(ReactNavigation_Types.DidFocus.value)(didFocus) ])(function (listener) {
                    return function __do() {
                        var v = listener();
                        return self.setState(function (s) {
                            var $62 = {};
                            for (var $63 in s) {
                                if ({}.hasOwnProperty.call(s, $63)) {
                                    $62[$63] = s[$63];
                                };
                            };
                            $62.unsubs = Data_Array.cons(v)(s.unsubs);
                            return $62;
                        })();
                    };
                });
            };
            var didMount = function (self) {
                return bindNavListeners(self);
            };
            return React_Basic.make()(withPagePropsC)({
                initialState: initialState,
                render: render,
                didMount: didMount,
                willUnmount: willUnmount,
                didUpdate: didUpdate
            })(initProps);
        };
    };
};
var onAEitherFallback = new OnPgAction(function (v) {
    return function (nav) {
        return function (cb) {
            return function (v1) {
                if (v1 instanceof ReactNavigation_Types.PgSubmit) {
                    return cb(nav)(v1.value0);
                };
                if (v1 instanceof ReactNavigation_Types.PgBack) {
                    return ReactNavigation.goBack(nav);
                };
                if (v1 instanceof ReactNavigation_Types.PgCancel) {
                    return ReactNavigation.goBack(nav);
                };
                if (v1 instanceof ReactNavigation_Types.PgErr) {
                    return ReactNavigation.goBack(nav);
                };
                throw new Error("Failed pattern match at ReactNavigation.Utils.HOCs.WithPageProps (line 267, column 6 - line 271, column 45): " + [ v.constructor.name, nav.constructor.name, cb.constructor.name, v1.constructor.name ]);
            };
        };
    };
});
var onAEitherClientErr = new OnPgAction(function (v) {
    return function (nav) {
        return function (cb) {
            return function (v1) {
                if (v1 instanceof ReactNavigation_Types.PgSubmit) {
                    return cb(nav)(new Data_Either.Right(v1.value0));
                };
                if (v1 instanceof ReactNavigation_Types.PgBack) {
                    return ReactNavigation.goBack(nav);
                };
                if (v1 instanceof ReactNavigation_Types.PgCancel) {
                    return ReactNavigation.goBack(nav);
                };
                if (v1 instanceof ReactNavigation_Types.PgErr) {
                    return cb(nav)(new Data_Either.Left(v1.value0));
                };
                throw new Error("Failed pattern match at ReactNavigation.Utils.HOCs.WithPageProps (line 262, column 1 - line 266, column 50): " + [ v.constructor.name, nav.constructor.name, cb.constructor.name, v1.constructor.name ]);
            };
        };
    };
});
module.exports = {
    withPageProps: withPageProps,
    OnPgAction: OnPgAction,
    onPgAction: onPgAction,
    onAEitherClientErr: onAEitherClientErr,
    onAEitherFallback: onAEitherFallback
};
