// Generated by purs version 0.13.5
"use strict";
var Data_Long_Internal = require("../Data.Long.Internal/index.js");
var toUnsigned = Data_Long_Internal.signedToUnsigned;
var toStringAs = Data_Long_Internal.toStringAs;
var toString = Data_Long_Internal.toString;
var toNumber = Data_Long_Internal.toNumber;
var toInt = Data_Long_Internal.toInt(Data_Long_Internal.infoSigned);
var rem = Data_Long_Internal.rem(Data_Long_Internal["semiringLong'Signed"]);
var quot = Data_Long_Internal.quot(Data_Long_Internal["semiringLong'Signed"]);
var parity = Data_Long_Internal.parity;
var odd = Data_Long_Internal.odd;
var lowBits = Data_Long_Internal.lowBits;
var highBits = Data_Long_Internal.highBits;
var fromStringAs = Data_Long_Internal.fromStringAs(Data_Long_Internal.infoSigned);
var fromString = Data_Long_Internal.fromString(Data_Long_Internal.infoSigned);
var fromNumber = Data_Long_Internal.fromNumber(Data_Long_Internal.infoSigned)(Data_Long_Internal["boundedLong'Signed"]);
var fromLowHighBits = Data_Long_Internal.fromLowHighBits(Data_Long_Internal.infoSigned);
var fromInt = Data_Long_Internal.signedLongFromInt;
var even = Data_Long_Internal.even;
module.exports = {
    fromLowHighBits: fromLowHighBits,
    fromInt: fromInt,
    fromNumber: fromNumber,
    fromString: fromString,
    fromStringAs: fromStringAs,
    lowBits: lowBits,
    highBits: highBits,
    toInt: toInt,
    toNumber: toNumber,
    toString: toString,
    toStringAs: toStringAs,
    parity: parity,
    even: even,
    odd: odd,
    quot: quot,
    rem: rem,
    toUnsigned: toUnsigned
};
