// Generated by purs version 0.13.5
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    cardContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(2.0), React_Basic_Native_Styles.shadowOffset({
        height: 2.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(4), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ]),
    cardButtonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(6.0), React_Basic_Native_Styles.shadowOffset({
        height: 1.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(4), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ])
};
var cardComponent = React_Basic.createComponent("Card");
var cardButtonComponent = React_Basic.createComponent("CardButton");
var cardButton = (function () {
    var render = function (props) {
        return React_Basic_Native_Generated.touchableOpacity()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.cardButtonContainer, props.style, ZAP_Dart_Client_Styles_Utils.styleDisabled(props.disabled) ]),
            onPress: React_Basic_Events.handler_(props.onPress),
            disabled: props.disabled,
            children: props.children
        });
    };
    return React_Basic.makeStateless(cardButtonComponent)(render);
})();
var card = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.cardContainer, v.style ]),
            children: v.children
        });
    };
    return React_Basic.makeStateless(cardComponent)(render);
})();
module.exports = {
    card: card,
    cardButton: cardButton
};
