// Generated by purs version 0.13.5
"use strict";
var Data_Int = require("../Data.Int/index.js");
var Data_Show = require("../Data.Show/index.js");
var Color = function (x) {
    return x;
};
var yellowgreen = "yellowgreen";
var yellow = "yellow";
var whitesmoke = "whitesmoke";
var white = "white";
var wheat = "wheat";
var violet = "violet";
var turquoise = "turquoise";
var transparent = "transparent";
var tomato = "tomato";
var thistle = "thistle";
var teal = "teal";
var tan = "tan";
var steelblue = "steelblue";
var springgreen = "springgreen";
var snow = "snow";
var slategrey = "slategrey";
var slategray = "slategray";
var slateblue = "slateblue";
var skyblue = "skyblue";
var silver = "silver";
var sienna = "sienna";
var seashell = "seashell";
var seagreen = "seagreen";
var sandybrown = "sandybrown";
var salmon = "salmon";
var saddlebrown = "saddlebrown";
var royalblue = "royalblue";
var rosybrown = "rosybrown";
var rgbi = function (i) {
    return Color("#" + Data_Int.toStringAs(Data_Int.hexadecimal)(i));
};
var rgba = function (r) {
    return function (g) {
        return function (b) {
            return function (a) {
                return Color("rgba(" + (Data_Show.show(Data_Show.showInt)(r) + (", " + (Data_Show.show(Data_Show.showInt)(g) + (", " + (Data_Show.show(Data_Show.showInt)(b) + (", " + (Data_Show.show(Data_Show.showNumber)(a) + ")"))))))));
            };
        };
    };
};
var rgb = function (r) {
    return function (g) {
        return function (b) {
            return Color("rgb(" + (Data_Show.show(Data_Show.showInt)(r) + (", " + (Data_Show.show(Data_Show.showInt)(g) + (", " + (Data_Show.show(Data_Show.showInt)(b) + ")"))))));
        };
    };
};
var red = "red";
var rebeccapurple = "rebeccapurple";
var purple = "purple";
var powderblue = "powderblue";
var plum = "plum";
var pink = "pink";
var peru = "peru";
var peachpuff = "peachpuff";
var papayawhip = "papayawhip";
var palevioletred = "palevioletred";
var paleturquoise = "paleturquoise";
var palegreen = "palegreen";
var palegoldenrod = "palegoldenrod";
var orchid = "orchid";
var orangered = "orangered";
var orange = "orange";
var olivedrab = "olivedrab";
var olive = "olive";
var oldlace = "oldlace";
var navy = "navy";
var navajowhite = "navajowhite";
var moccasin = "moccasin";
var mistyrose = "mistyrose";
var mintcream = "mintcream";
var midnightblue = "midnightblue";
var mediumvioletred = "mediumvioletred";
var mediumturquoise = "mediumturquoise";
var mediumspringgreen = "mediumspringgreen";
var mediumslateblue = "mediumslateblue";
var mediumseagreen = "mediumseagreen";
var mediumpurple = "mediumpurple";
var mediumorchid = "mediumorchid";
var mediumblue = "mediumblue";
var mediumaquamarine = "mediumaquamarine";
var maroon = "maroon";
var magenta = "magenta";
var linen = "linen";
var limegreen = "limegreen";
var lime = "lime";
var lightyellow = "lightyellow";
var lightsteelblue = "lightsteelblue";
var lightslategrey = "lightslategrey";
var lightslategray = "lightslategray";
var lightskyblue = "lightskyblue";
var lightseagreen = "lightseagreen";
var lightsalmon = "lightsalmon";
var lightpink = "lightpink";
var lightgrey = "lightgrey";
var lightgreen = "lightgreen";
var lightgray = "lightgray";
var lightgoldenrodyellow = "lightgoldenrodyellow";
var lightcyan = "lightcyan";
var lightcoral = "lightcoral";
var lightblue = "lightblue";
var lemonchiffon = "lemonchiffon";
var lawngreen = "lawngreen";
var lavenderblush = "lavenderblush";
var lavender = "lavender";
var khaki = "khaki";
var ivory = "ivory";
var indigo = "indigo";
var indianred = "indianred";
var hotpink = "hotpink";
var honeydew = "honeydew";
var grey = "grey";
var greenyellow = "greenyellow";
var green = "green";
var gray = "gray";
var goldenrod = "goldenrod";
var gold = "gold";
var ghostwhite = "ghostwhite";
var gainsboro = "gainsboro";
var fuchsia = "fuchsia";
var forestgreen = "forestgreen";
var floralwhite = "floralwhite";
var firebrick = "firebrick";
var dodgerblue = "dodgerblue";
var dimgrey = "dimgrey";
var dimgray = "dimgray";
var deepskyblue = "deepskyblue";
var deeppink = "deeppink";
var darkviolet = "darkviolet";
var darkturquoise = "darkturquoise";
var darkslategrey = "darkslategrey";
var darkslategray = "darkslategray";
var darkslateblue = "darkslateblue";
var darkseagreen = "darkseagreen";
var darksalmon = "darksalmon";
var darkred = "darkred";
var darkorchid = "darkorchid";
var darkorange = "darkorange";
var darkolivegreen = "darkolivegreen";
var darkmagenta = "darkmagenta";
var darkkhaki = "darkkhaki";
var darkgrey = "darkgrey";
var darkgreen = "darkgreen";
var darkgray = "darkgray";
var darkgoldenrod = "darkgoldenrod";
var darkcyan = "darkcyan";
var darkblue = "darkblue";
var cyan = "cyan";
var crimson = "crimson";
var cornsilk = "cornsilk";
var cornflowerblue = "cornflowerblue";
var coral = "coral";
var chocolate = "chocolate";
var chartreuse = "chartreuse";
var cadetblue = "cadetblue";
var burlywood = "burlywood";
var brown = "brown";
var blueviolet = "blueviolet";
var blue = "blue";
var blanchedalmond = "blanchedalmond";
var black = "black";
var bisque = "bisque";
var beige = "beige";
var azure = "azure";
var aquamarine = "aquamarine";
var aqua = "aqua";
var antiquewhite = "antiquewhite";
var aliceblue = "aliceblue";
module.exports = {
    rgb: rgb,
    rgba: rgba,
    rgbi: rgbi,
    transparent: transparent,
    aliceblue: aliceblue,
    antiquewhite: antiquewhite,
    aqua: aqua,
    aquamarine: aquamarine,
    azure: azure,
    beige: beige,
    bisque: bisque,
    black: black,
    blanchedalmond: blanchedalmond,
    blue: blue,
    blueviolet: blueviolet,
    brown: brown,
    burlywood: burlywood,
    cadetblue: cadetblue,
    chartreuse: chartreuse,
    chocolate: chocolate,
    coral: coral,
    cornflowerblue: cornflowerblue,
    cornsilk: cornsilk,
    crimson: crimson,
    cyan: cyan,
    darkblue: darkblue,
    darkcyan: darkcyan,
    darkgoldenrod: darkgoldenrod,
    darkgray: darkgray,
    darkgreen: darkgreen,
    darkgrey: darkgrey,
    darkkhaki: darkkhaki,
    darkmagenta: darkmagenta,
    darkolivegreen: darkolivegreen,
    darkorange: darkorange,
    darkorchid: darkorchid,
    darkred: darkred,
    darksalmon: darksalmon,
    darkseagreen: darkseagreen,
    darkslateblue: darkslateblue,
    darkslategray: darkslategray,
    darkslategrey: darkslategrey,
    darkturquoise: darkturquoise,
    darkviolet: darkviolet,
    deeppink: deeppink,
    deepskyblue: deepskyblue,
    dimgray: dimgray,
    dimgrey: dimgrey,
    dodgerblue: dodgerblue,
    firebrick: firebrick,
    floralwhite: floralwhite,
    forestgreen: forestgreen,
    fuchsia: fuchsia,
    gainsboro: gainsboro,
    ghostwhite: ghostwhite,
    gold: gold,
    goldenrod: goldenrod,
    gray: gray,
    green: green,
    greenyellow: greenyellow,
    grey: grey,
    honeydew: honeydew,
    hotpink: hotpink,
    indianred: indianred,
    indigo: indigo,
    ivory: ivory,
    khaki: khaki,
    lavender: lavender,
    lavenderblush: lavenderblush,
    lawngreen: lawngreen,
    lemonchiffon: lemonchiffon,
    lightblue: lightblue,
    lightcoral: lightcoral,
    lightcyan: lightcyan,
    lightgoldenrodyellow: lightgoldenrodyellow,
    lightgray: lightgray,
    lightgreen: lightgreen,
    lightgrey: lightgrey,
    lightpink: lightpink,
    lightsalmon: lightsalmon,
    lightseagreen: lightseagreen,
    lightskyblue: lightskyblue,
    lightslategray: lightslategray,
    lightslategrey: lightslategrey,
    lightsteelblue: lightsteelblue,
    lightyellow: lightyellow,
    lime: lime,
    limegreen: limegreen,
    linen: linen,
    magenta: magenta,
    maroon: maroon,
    mediumaquamarine: mediumaquamarine,
    mediumblue: mediumblue,
    mediumorchid: mediumorchid,
    mediumpurple: mediumpurple,
    mediumseagreen: mediumseagreen,
    mediumslateblue: mediumslateblue,
    mediumspringgreen: mediumspringgreen,
    mediumturquoise: mediumturquoise,
    mediumvioletred: mediumvioletred,
    midnightblue: midnightblue,
    mintcream: mintcream,
    mistyrose: mistyrose,
    moccasin: moccasin,
    navajowhite: navajowhite,
    navy: navy,
    oldlace: oldlace,
    olive: olive,
    olivedrab: olivedrab,
    orange: orange,
    orangered: orangered,
    orchid: orchid,
    palegoldenrod: palegoldenrod,
    palegreen: palegreen,
    paleturquoise: paleturquoise,
    palevioletred: palevioletred,
    papayawhip: papayawhip,
    peachpuff: peachpuff,
    peru: peru,
    pink: pink,
    plum: plum,
    powderblue: powderblue,
    purple: purple,
    rebeccapurple: rebeccapurple,
    red: red,
    rosybrown: rosybrown,
    royalblue: royalblue,
    saddlebrown: saddlebrown,
    salmon: salmon,
    sandybrown: sandybrown,
    seagreen: seagreen,
    seashell: seashell,
    sienna: sienna,
    silver: silver,
    skyblue: skyblue,
    slateblue: slateblue,
    slategray: slategray,
    slategrey: slategrey,
    snow: snow,
    springgreen: springgreen,
    steelblue: steelblue,
    tan: tan,
    teal: teal,
    thistle: thistle,
    tomato: tomato,
    turquoise: turquoise,
    violet: violet,
    wheat: wheat,
    white: white,
    whitesmoke: whitesmoke,
    yellow: yellow,
    yellowgreen: yellowgreen
};
