// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var svg = function (dictUnion) {
    return React_Basic.element($foreign["_svg"]);
};
var rect = function (dictUnion) {
    return React_Basic.element($foreign["_rect"]);
};
var path = function (dictUnion) {
    return React_Basic.element($foreign["_path"]);
};
module.exports = {
    svg: svg,
    path: path,
    rect: rect
};
