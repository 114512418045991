// Generated by purs version 0.13.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Components_CheckBox = require("../ZAP.Dart.Client.Components.CheckBox/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(4) ]),
    label: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingLeft(React_Basic_Native_Styles.intPaddingLeft)(8) ])
};
var component = React_Basic.createComponent("CheckBoxGroup");
var checkBoxGroup = (function () {
    var handlePress = function (props) {
        return function (s) {
            var newSelected = (function () {
                var v = Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqString)(s)(props.value);
                if (v) {
                    return Data_Array["delete"](Data_Eq.eqString)(s)(props.value);
                };
                if (!v) {
                    return Data_Array.insert(Data_Ord.ordString)(s)(props.value);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Input.CheckBoxGroup (line 57, column 23 - line 59, column 46): " + [ v.constructor.name ]);
            })();
            return props.onSelectionChange(newSelected);
        };
    };
    var renderCheckbox = function (props) {
        return function (s) {
            return ZAP_Dart_Client_Components_CheckBox.checkBox({
                onPress: React_Basic_Events.handler_(handlePress(props)(s)),
                isChecked: Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqString)(s)(props.value),
                color: ZAP_Dart_Client_Styles_Colors.altFg
            });
        };
    };
    var render = function (props) {
        return React_Basic.fragment(Data_Functor.mapFlipped(Data_Functor.functorArray)(props.selection)(function (s) {
            return React_Basic_Native_Generated.view()({
                style: sheet.container,
                children: [ renderCheckbox(props)(s), ZAP_Dart_Client_Components_Typography.b3({
                    style: sheet.label,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: s
                }) ]
            });
        }));
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    checkBoxGroup: checkBoxGroup
};
