// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_DashedInput = require("../ZAP.Dart.Client.Components.DashedInput/index.js");
var ZAP_Dart_Client_Screens_Templates_StepWrapper = require("../ZAP.Dart.Client.Screens.Templates.StepWrapper/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var PinStepCreate = (function () {
    function PinStepCreate() {

    };
    PinStepCreate.value = new PinStepCreate();
    return PinStepCreate;
})();
var PinStepConfirm = (function () {
    function PinStepConfirm() {

    };
    PinStepConfirm.value = new PinStepConfirm();
    return PinStepConfirm;
})();
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    resetPinLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(64) ]),
    resetPinBtn: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(4) ]),
    errorMsg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(18) ]),
    submitButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(360), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(80)), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(40), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(72) ])
};
var component = React_Basic.createComponent("CreatePin");
var promptPin = function (ps) {
    var title = (function () {
        if (ps instanceof PinStepCreate) {
            return "Create Your 6-Digit Pincode";
        };
        if (ps instanceof PinStepConfirm) {
            return "Confirm Your 6-Digit Pincode";
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CreatePin (line 49, column 11 - line 51, column 53): " + [ ps.constructor.name ]);
    })();
    var submit = function (self) {
        return function (pin) {
            return Control_Applicative.when(Effect.applicativeEffect)(Data_String_CodeUnits.length(pin) === 6)(Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v) {
                return {
                    pin: ""
                };
            }))(self.props.onAction(ReactNavigation_Types.PgSubmit.create(pin))));
        };
    };
    var onSubmit = function (self) {
        return submit(self)(self.state.pin);
    };
    var initialState = {
        pin: ""
    };
    var canSubmit = function (state) {
        return Data_String_CodeUnits.length(state.pin) === 6;
    };
    var render = function (self) {
        return ZAP_Dart_Client_Screens_Templates_StepWrapper.stepWrapper({
            header: (function () {
                if (ps instanceof PinStepCreate) {
                    return new Data_Tuple.Tuple("CREATE YOUR", "6-DIGIT PIN");
                };
                if (ps instanceof PinStepConfirm) {
                    return new Data_Tuple.Tuple("CONFIRM YOUR", "6-DIGIT PINCODE");
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CreatePin (line 69, column 9 - line 71, column 67): " + [ ps.constructor.name ]);
            })(),
            message: (function () {
                if (ps instanceof PinStepCreate) {
                    return new Data_Tuple.Tuple("You'll be needing this in order to", "buy rewards using your Bottle Co Points.");
                };
                if (ps instanceof PinStepConfirm) {
                    return new Data_Tuple.Tuple("Just retype your pincode from the", "previous screen, and you\u2019re all set!");
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CreatePin (line 73, column 9 - line 75, column 109): " + [ ps.constructor.name ]);
            })(),
            content: [ ZAP_Dart_Client_Components_DashedInput.dashedInput({
                value: self.state.pin,
                length: 6,
                fontSize: 32,
                letterSpacing: 8,
                onChange: function (text) {
                    return self.setState(function (v) {
                        return {
                            pin: text
                        };
                    });
                },
                inputType: ZAP_Dart_Client_Components_DashedInput.Numeric.value,
                onSubmitEditing: React_Basic_Events.handler_(onSubmit(self)),
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.input,
                secureTextEntry: true,
                autoFocus: true
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.submitButton,
                disabled: !canSubmit(self.state),
                inverted: true,
                size: ZAP_Dart_Client_Components_Button.lg,
                onPress: onSubmit(self),
                title: "SUBMIT"
            }) ]
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
};
var confirmPin = promptPin(PinStepConfirm.value);
var createPin = promptPin(PinStepCreate.value);
module.exports = {
    createPin: createPin,
    confirmPin: confirmPin
};
