// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_DashedInput = require("../ZAP.Dart.Client.Components.DashedInput/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Templates_StepWrapper = require("../ZAP.Dart.Client.Screens.Templates.StepWrapper/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    labelResend: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(48) ]),
    btnResend: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(4) ]),
    errorMsg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(18) ]),
    cancelButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(244), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(20) ])
};
var defaultErrorMsg = "Unable to process your request";
var getResendOtpErrMsg = function (e) {
    return {
        title: "Resend Failed",
        message: (function () {
            if (e instanceof ZAP_Dart_Common.ResendOtpErrSessionExpired) {
                return "Session expired";
            };
            return defaultErrorMsg;
        })()
    };
};
var getVerifyOtpErrMsg = function (e) {
    return {
        title: "Verification Failed",
        message: (function () {
            if (e instanceof ZAP_Dart_Common.VerifyOtpErrMaxAttemptCountExceeded) {
                return "Max verification attempts exceeded";
            };
            if (e instanceof ZAP_Dart_Common.VerifyOtpErrSessionExpired) {
                return "Session expired";
            };
            if (e instanceof ZAP_Dart_Common.VerifyOtpErrOthers) {
                return defaultErrorMsg;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.AuthorizedEnterOtp (line 166, column 5 - line 169, column 44): " + [ e.constructor.name ]);
        })()
    };
};
var component = React_Basic.createComponent("EnterOtp");
var authorizedEnterOtp = function (config) {
    var onCancel = function (self) {
        return self.props.onAction(ReactNavigation_Types.PgSubmit.create(new Data_Either.Left(Data_Unit.unit)));
    };
    var onAction = function (self) {
        return function (res) {
            return self.props.onAction(ReactNavigation_Types.PgSubmit.create(res));
        };
    };
    var initialState = {
        otp: "",
        errorMsg: Data_Maybe.Nothing.value,
        isResendAllowed: true
    };
    var getRefId = function (self) {
        if (self.props.initD instanceof Data_Either.Right) {
            return self.props.initD.value0.refId;
        };
        if (self.props.initD instanceof Data_Either.Left) {
            return "";
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.AuthorizedEnterOtp (line 71, column 19 - line 73, column 17): " + [ self.props.initD.constructor.name ]);
    };
    var resendOtp = function (self) {
        var onResolve = function (v) {
            if (v instanceof Data_Either.Right) {
                return self.setState(function (v1) {
                    return {
                        isResendAllowed: v.value0.isResendAllowed,
                        errorMsg: v1.errorMsg,
                        otp: v1.otp
                    };
                });
            };
            if (v instanceof Data_Either.Left) {
                if (v.value0 instanceof ZAP_Dart_Common.ResendOtpErrMaxResendCountExceeded) {
                    return self.setState(function (v1) {
                        return {
                            isResendAllowed: v1.isResendAllowed,
                            errorMsg: new Data_Maybe.Just("Max Resend Count Exceeded"),
                            otp: v1.otp
                        };
                    });
                };
                return onAction(self)(new Data_Either.Right(Data_Either.Left.create(getResendOtpErrMsg(v.value0))));
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.AuthorizedEnterOtp (line 91, column 17 - line 95, column 65): " + [ v.constructor.name ]);
        };
        var apiCall = ZAP_Dart_Client_Screens_Utils.mkLApi(config.apiCalls.resendOtp({
            refId: getRefId(self)
        }));
        return self.props.loadApiP(apiCall(onResolve));
    };
    var onResend = function (self) {
        return resendOtp(self);
    };
    var verifyOtp = function (self) {
        return function (otp) {
            var onResolve = function (v) {
                if (v instanceof Data_Either.Right && v.value0.isVerified) {
                    return onAction(self)(new Data_Either.Right(new Data_Either.Right({
                        refId: v.value0.refId,
                        otp: otp
                    })));
                };
                if (v instanceof Data_Either.Right) {
                    return self.setState(function (v1) {
                        return {
                            isResendAllowed: v1.isResendAllowed,
                            errorMsg: new Data_Maybe.Just("The code you entered is invalid!"),
                            otp: ""
                        };
                    });
                };
                if (v instanceof Data_Either.Left) {
                    return onAction(self)(new Data_Either.Right(Data_Either.Left.create(getVerifyOtpErrMsg(v.value0))));
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.AuthorizedEnterOtp (line 81, column 17 - line 85, column 68): " + [ v.constructor.name ]);
            };
            var apiCall = ZAP_Dart_Client_Screens_Utils.mkLApi(config.apiCalls.verifyOtp({
                refId: getRefId(self),
                otp: otp
            }));
            return self.props.loadApiP(apiCall(onResolve));
        };
    };
    var submit = function (self) {
        return function (otp) {
            return Control_Applicative.when(Effect.applicativeEffect)(Data_String_CodeUnits.length(otp) === 4)(Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v) {
                return {
                    otp: "",
                    errorMsg: v.errorMsg,
                    isResendAllowed: v.isResendAllowed
                };
            }))(verifyOtp(self)(otp)));
        };
    };
    var onSubmit = function (self) {
        return React_Basic_Events.handler_(submit(self)(self.state.otp));
    };
    var render = function (self) {
        return ZAP_Dart_Client_Screens_Templates_StepWrapper.stepWrapper({
            header: new Data_Tuple.Tuple("VERIFY YOUR", "MOBILE NUMBER"),
            message: new Data_Tuple.Tuple("Enter the 4-digit OTP sent to", "your mobile number"),
            content: [ ZAP_Dart_Client_Components_DashedInput.dashedInput({
                value: self.state.otp,
                length: 4,
                fontSize: 32,
                letterSpacing: 8,
                onChange: function (text) {
                    return Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v) {
                        return {
                            otp: text,
                            errorMsg: Data_Maybe.Nothing.value,
                            isResendAllowed: v.isResendAllowed
                        };
                    }))(submit(self)(text));
                },
                inputType: ZAP_Dart_Client_Components_DashedInput.Numeric.value,
                onSubmitEditing: onSubmit(self),
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.input,
                secureTextEntry: false,
                autoFocus: true
            }), ZAP_Dart_Client_Components_Typography.b2({
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.errorMsg,
                value: Data_Maybe.fromMaybe("")(self.state.errorMsg)
            }), ZAP_Dart_Client_Components_Typography.b2({
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.labelResend,
                value: "Didn't get the code?"
            }), ZAP_Dart_Client_Components_Button.linkButton({
                onPress: onResend(self),
                title: "RESEND CODE",
                disabled: !self.state.isResendAllowed,
                size: ZAP_Dart_Client_Components_Button.sm,
                style: sheet.btnResend
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.cancelButton,
                disabled: false,
                inverted: false,
                size: ZAP_Dart_Client_Components_Button.md,
                onPress: onCancel(self),
                title: "CANCEL"
            }) ]
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
};
module.exports = {
    authorizedEnterOtp: authorizedEnterOtp
};
