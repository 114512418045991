// Generated by purs version 0.13.5
"use strict";
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_Card = require("../ZAP.Dart.Client.Components.Card/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    qtyValue: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(4), React_Basic_Native_Styles.minWidth(React_Basic_Native_Styles.intMinWidth)(48), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    opButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(4), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(28), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(28), React_Basic_Native_Styles.borderRadius(4), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    opButtonText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(17), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.altFg) ])
};
var component = React_Basic.createComponent("QtyInput");
var qtyInput = (function () {
    var styleDisabled = function (disabled) {
        return React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.opacity((function () {
            if (disabled) {
                return 0.4;
            };
            return 1.0;
        })()) ]);
    };
    var opButton = function (v) {
        return ZAP_Dart_Client_Components_Card.cardButton({
            style: sheet.opButton,
            onPress: v.onChange(v.operation(v.value)),
            disabled: v.disabled,
            children: [ React_Basic_Native_Generated.text()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.opButtonText, styleDisabled(v.disabled) ]),
                children: [ React_Basic_Native.string(v.text) ]
            }) ]
        });
    };
    var subButton = function (props) {
        return opButton({
            onChange: props.onChange,
            value: props.value,
            operation: function (v) {
                return v - 1 | 0;
            },
            text: "-",
            disabled: (props.value - 1 | 0) < 1
        });
    };
    var addButton = function (props) {
        return opButton({
            onChange: props.onChange,
            value: props.value,
            operation: function (v) {
                return v + 1 | 0;
            },
            text: "+",
            disabled: props.addButtonDisabled
        });
    };
    var render = function (props) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ subButton(props), ZAP_Dart_Client_Components_Typography.b2({
                style: sheet.qtyValue,
                color: ZAP_Dart_Client_Styles_Colors.altFg,
                value: Data_Show.show(Data_Show.showInt)(props.value)
            }), addButton(props) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    qtyInput: qtyInput
};
