// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Dimensions = require("../ReactNative.Dimensions/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_PrivacyLinks = require("../ZAP.Dart.Client.Components.PrivacyLinks/index.js");
var ZAP_Dart_Client_Components_StoreLinkFooter = require("../ZAP.Dart.Client.Components.StoreLinkFooter/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    contentContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexGrow(1), React_Basic_Native_Styles_Flex.flexShrink(0), React_Basic_Native_Styles.flexBasis(React_Basic_Native_Styles.intFlexBasis)("auto"), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(72) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(20) ]),
    headerTextContainer: React_Basic_Native_Styles.staticStyles([  ]),
    headerText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(3), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    logo: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(260), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(169), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(8), React_Basic_Native_Styles.resizeMode(React_Basic_Native_Styles.contain) ]),
    selectButtonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(25) ]),
    selectButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRadius(20), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.margin(React_Basic_Native_Styles.intMargin)(8), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(132), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(132), React_Basic_Native_Styles.shadowOpacity(0.4), React_Basic_Native_Styles.shadowRadius(7.0), React_Basic_Native_Styles.shadowOffset({
        height: 3.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(5) ]),
    selectButtonIconContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(3), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    selectButtonTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.fg) ]),
    signinContainer: React_Basic_Native_Styles.staticStyles([  ]),
    signinLink: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.primaryLink) ]),
    footerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexShrink(0), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    links: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    mobileConfettiContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.top(React_Basic_Native_Styles.intTop)(0), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(19) ]),
    desktopConfetti: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.top(React_Basic_Native_Styles.intTop)(0), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(19) ])
};
var component = React_Basic.createComponent("WebLanding");
var webLanding = (function () {
    var confetti = (function () {
        if (ReactNative_Dimensions.getWindow.width > 736) {
            return ZAP_Dart_Client_Components_Image.image({
                style: React_Basic_Native_Styles["styles'"]([ sheet.desktopConfetti, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(ReactNative_Dimensions.getWindow.width), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(ReactNative_Dimensions.getWindow.height) ]) ]),
                source: ZAP_Dart_Client_Assets.confettiDesktop,
                resizeMode: React_Basic_Native_Styles.contain
            });
        };
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.mobileConfettiContainer, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(ReactNative_Dimensions.getWindow.width), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(ReactNative_Dimensions.getWindow.height) ]) ]),
            children: [ ZAP_Dart_Client_Components_Image.image({
                style: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(Data_Ord.min(Data_Ord.ordInt)(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(840)(363) * ReactNative_Dimensions.getWindow.height | 0)(233)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(Data_Ord.min(Data_Ord.ordInt)(ReactNative_Dimensions.getWindow.height)(540)) ]),
                source: ZAP_Dart_Client_Assets.confettiMobileLeft,
                resizeMode: React_Basic_Native_Styles.contain
            }), ZAP_Dart_Client_Components_Image.image({
                style: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(Data_Ord.min(Data_Ord.ordInt)(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(840)(363) * ReactNative_Dimensions.getWindow.height | 0)(233)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(Data_Ord.min(Data_Ord.ordInt)(ReactNative_Dimensions.getWindow.height)(540)) ]),
                source: ZAP_Dart_Client_Assets.confettiMobileRight,
                resizeMode: React_Basic_Native_Styles.contain
            }) ]
        });
    })();
    var render = function (props) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ React_Basic_Native_Generated.scrollView()({
                contentContainerStyle: sheet.scrollContainer,
                children: [ confetti, React_Basic_Native_Generated.view()({
                    style: sheet.contentContainer,
                    children: [ React_Basic_Native_Generated.view()({
                        style: sheet.links,
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_PrivacyLinks.privacyLinks({}))
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.headerContainer,
                        children: [ React_Basic_Native_Generated.image()({
                            style: sheet.logo,
                            source: ZAP_Dart_Client_BuildConfig.buildConfig.whiteLogo
                        }), React_Basic_Native_Generated.view()({
                            style: sheet.headerTextContainer,
                            children: [ ZAP_Dart_Client_Components_Typography.h5({
                                style: sheet.headerText,
                                color: ZAP_Dart_Client_Styles_Colors.altBg,
                                value: "Earn points from your Pampers products  "
                            }), ZAP_Dart_Client_Components_Typography.h5({
                                style: sheet.headerText,
                                color: ZAP_Dart_Client_Styles_Colors.altBg,
                                value: "and get amazing rewards by clicking the"
                            }), ZAP_Dart_Client_Components_Typography.h5({
                                style: sheet.headerText,
                                color: ZAP_Dart_Client_Styles_Colors.altBg,
                                value: "button below!"
                            }) ]
                        }) ]
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.selectButtonContainer,
                        children: [ React_Basic_Native_Generated.touchableOpacity()({
                            style: sheet.selectButton,
                            onPress: React_Basic_Events.handler_(props.onAction(ReactNavigation_Types.PgSubmit.create(ZAP_Dart_Client_Screens_Types.WebLandingInputCCode.value))),
                            children: [ React_Basic_Native_Generated.view()({
                                style: sheet.selectButtonIconContainer,
                                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Icon.icon({
                                    name: "input_qr",
                                    size: 24,
                                    color: ZAP_Dart_Client_Styles_Colors.altFg
                                }))
                            }), ZAP_Dart_Client_Components_Typography.b1({
                                style: sheet.selectButtonTitle,
                                value: "Input Codes",
                                color: ZAP_Dart_Client_Styles_Colors.altFg
                            }) ]
                        }) ]
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.signinContainer,
                        children: [ ZAP_Dart_Client_Components_Typography.text({
                            style: sheet.headerText,
                            color: ZAP_Dart_Client_Styles_Colors.altBg,
                            type: ZAP_Dart_Client_Components_Typography.B1.value,
                            children: [ React_Basic_Native_Generated.text()({
                                style: sheet.signinLink,
                                onPress: React_Basic_Events.handler_(props.onAction(ReactNavigation_Types.PgSubmit.create(ZAP_Dart_Client_Screens_Types.WebLandingSignIn.value))),
                                children: [ React_Basic_Native.string("Register or Sign In") ]
                            }) ]
                        }) ]
                    }) ]
                }), ZAP_Dart_Client_Components_StoreLinkFooter.storeLinkFooter({
                    style: sheet.footerContainer,
                    inverted: false
                }) ]
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    webLanding: webLanding
};
