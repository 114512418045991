// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Now = require("../Effect.Now/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ReactNative_ActivityIndicator = require("../ReactNative.ActivityIndicator/index.js");
var ReactNative_FlatList = require("../ReactNative.FlatList/index.js");
var ZAP_Dart_Client_Components_Banner = require("../ZAP.Dart.Client.Components.Banner/index.js");
var ZAP_Dart_Client_Components_FullPageSpinner = require("../ZAP.Dart.Client.Components.FullPageSpinner/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_NetworkErrorPage = require("../ZAP.Dart.Client.Components.NetworkErrorPage/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Utilities_DateUtils = require("../ZAP.Dart.Client.Utilities.DateUtils/index.js");
var NetworkError = (function () {
    function NetworkError() {

    };
    NetworkError.value = new NetworkError();
    return NetworkError;
})();
var sheet = {
    footerComponent: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(20), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.flex(1) ]),
    footerButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)) ])
};
var loadMoreFooterComponent = React_Basic.createComponent("LoadMoreFooterComponent");
var loadMoreFooter = (function () {
    var render = function (props) {
        if (props.loadingMore) {
            return React_Basic_Native_Generated.view()({
                style: sheet.footerComponent,
                children: [ React_Basic_Native_Generated.activityIndicator()({
                    size: ReactNative_ActivityIndicator.large,
                    animating: props.loadingMore
                }) ]
            });
        };
        var $24 = !props.loadingMore && props.loadMoreError;
        if ($24) {
            return React_Basic_Native_Generated.view()({
                style: sheet.footerComponent,
                children: [ React_Basic_Native_Generated.touchableOpacity()({
                    style: sheet.footerButton,
                    onPress: React_Basic_Events.handler_(props.fetchMore),
                    disabled: false,
                    children: [ ZAP_Dart_Client_Components_Icon.icon({
                        name: "load_more",
                        size: 48,
                        color: ZAP_Dart_Client_Styles_Colors.lightIcon
                    }) ]
                }) ]
            });
        };
        return Data_Monoid.mempty(React_Basic.monoidJSX);
    };
    return React_Basic.makeStateless(loadMoreFooterComponent)(render);
})();
var getLastUpdatedText = function (v) {
    return function (v1) {
        if (v instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
            var jsDate = Data_JSDate.fromDateTime(v1.value0);
            return " Last updated " + (ZAP_Dart_Client_Utilities_DateUtils.formatDistanceToNow(jsDate) + " ago.");
        };
        return "";
    };
};
var component = React_Basic.createComponent("InfiniteList");
var infiniteList = function (dictEq) {
    var willUnmount = function (self) {
        return ZAP_Dart_Client_Screens_Utils.removeClock(self.state.intervalId);
    };
    var initialState = {
        items: [  ],
        marker: Data_Maybe.Nothing.value,
        refreshing: false,
        pullRefreshing: false,
        loadingMore: false,
        loadMoreError: false,
        error: Data_Maybe.Nothing.value,
        now: Data_Maybe.Nothing.value,
        lastUpdated: Data_Maybe.Nothing.value,
        intervalId: Data_Maybe.Nothing.value
    };
    var fetch = function (self) {
        return function (fetcher) {
            return function (onInitial) {
                return function (onSuccess) {
                    return function (onError) {
                        var onResolve = function (res) {
                            return function __do() {
                                var v = Effect_Now.nowDateTime();
                                var v1 = self.setState(function (v1) {
                                    return {
                                        marker: res.marker,
                                        lastUpdated: new Data_Maybe.Just(v),
                                        error: v1.error,
                                        items: v1.items,
                                        refreshing: v1.refreshing,
                                        loadMoreError: v1.loadMoreError,
                                        loadingMore: v1.loadingMore,
                                        pullRefreshing: v1.pullRefreshing,
                                        intervalId: v1.intervalId,
                                        now: v1.now
                                    };
                                })();
                                return onSuccess(res)();
                            };
                        };
                        var onNetworkError = function __do() {
                            var v = self.setState(function (v) {
                                return {
                                    marker: v.marker,
                                    lastUpdated: v.lastUpdated,
                                    error: new Data_Maybe.Just(NetworkError.value),
                                    items: v.items,
                                    refreshing: v.refreshing,
                                    loadMoreError: v.loadMoreError,
                                    loadingMore: v.loadingMore,
                                    pullRefreshing: v.pullRefreshing,
                                    intervalId: v.intervalId,
                                    now: v.now
                                };
                            })();
                            return onError();
                        };
                        var apiCall = ZAP_Dart_Client_Screens_Utils.mkLApi(fetcher)(onResolve);
                        return Control_Apply.applySecond(Effect.applyEffect)(onInitial)(self.props.runLApi(apiCall)(onNetworkError));
                    };
                };
            };
        };
    };
    var fetchBg = function (self) {
        var fetcher = self.props.fetchFn(Data_Maybe.Nothing.value);
        return fetch(self)(fetcher)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(function (res) {
            return self.setState(function (v) {
                return {
                    marker: v.marker,
                    lastUpdated: v.lastUpdated,
                    error: v.error,
                    items: res.items,
                    refreshing: v.refreshing,
                    loadMoreError: v.loadMoreError,
                    loadingMore: v.loadingMore,
                    pullRefreshing: v.pullRefreshing,
                    intervalId: v.intervalId,
                    now: v.now
                };
            });
        })(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
    var fetchInitial = function (self) {
        var fetcher = self.props.fetchFn(Data_Maybe.Nothing.value);
        return fetch(self)(fetcher)(self.setState(function (v) {
            return {
                marker: v.marker,
                lastUpdated: v.lastUpdated,
                error: Data_Maybe.Nothing.value,
                items: [  ],
                refreshing: true,
                loadMoreError: false,
                loadingMore: v.loadingMore,
                pullRefreshing: v.pullRefreshing,
                intervalId: v.intervalId,
                now: v.now
            };
        }))(function (res) {
            return self.setState(function (v) {
                return {
                    marker: v.marker,
                    lastUpdated: v.lastUpdated,
                    error: v.error,
                    items: res.items,
                    refreshing: false,
                    loadMoreError: false,
                    loadingMore: v.loadingMore,
                    pullRefreshing: v.pullRefreshing,
                    intervalId: v.intervalId,
                    now: v.now
                };
            });
        })(self.setState(function (v) {
            return {
                marker: v.marker,
                lastUpdated: v.lastUpdated,
                error: v.error,
                items: v.items,
                refreshing: false,
                loadMoreError: v.loadMoreError,
                loadingMore: v.loadingMore,
                pullRefreshing: v.pullRefreshing,
                intervalId: v.intervalId,
                now: v.now
            };
        }));
    };
    var fetchMore = function (self) {
        var fetcher = self.props.fetchFn(self.state.marker);
        var fetchMore$prime = fetch(self)(fetcher)(self.setState(function (v) {
            return {
                marker: v.marker,
                lastUpdated: v.lastUpdated,
                error: v.error,
                items: v.items,
                refreshing: v.refreshing,
                loadMoreError: false,
                loadingMore: true,
                pullRefreshing: v.pullRefreshing,
                intervalId: v.intervalId,
                now: v.now
            };
        }))(function (res) {
            return self.setState(function (v) {
                return {
                    marker: v.marker,
                    lastUpdated: v.lastUpdated,
                    error: v.error,
                    items: Data_Semigroup.append(Data_Semigroup.semigroupArray)(self.state.items)(res.items),
                    refreshing: v.refreshing,
                    loadMoreError: false,
                    loadingMore: false,
                    pullRefreshing: v.pullRefreshing,
                    intervalId: v.intervalId,
                    now: v.now
                };
            });
        })(self.setState(function (v) {
            return {
                marker: v.marker,
                lastUpdated: v.lastUpdated,
                error: v.error,
                items: v.items,
                refreshing: v.refreshing,
                loadMoreError: true,
                loadingMore: false,
                pullRefreshing: v.pullRefreshing,
                intervalId: v.intervalId,
                now: v.now
            };
        }));
        var canLoadMore = (function () {
            var hasMarker = (function () {
                if (self.state.marker instanceof Data_Maybe.Nothing) {
                    return false;
                };
                if (self.state.marker instanceof Data_Maybe.Just) {
                    return true;
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Components.InfiniteList (line 256, column 21 - line 258, column 25): " + [ self.state.marker.constructor.name ]);
            })();
            return hasMarker && !self.state.loadingMore;
        })();
        return Control_Applicative.when(Effect.applicativeEffect)(canLoadMore)(fetchMore$prime);
    };
    var fetchUpdate = function (self) {
        var fetcher = self.props.fetchFn(Data_Maybe.Nothing.value);
        return fetch(self)(fetcher)(self.setState(function (v) {
            return {
                marker: v.marker,
                lastUpdated: v.lastUpdated,
                error: Data_Maybe.Nothing.value,
                items: v.items,
                refreshing: true,
                loadMoreError: false,
                loadingMore: v.loadingMore,
                pullRefreshing: v.pullRefreshing,
                intervalId: v.intervalId,
                now: v.now
            };
        }))(function (res) {
            return self.setState(function (v) {
                return {
                    marker: v.marker,
                    lastUpdated: v.lastUpdated,
                    error: Data_Maybe.Nothing.value,
                    items: res.items,
                    refreshing: false,
                    loadMoreError: false,
                    loadingMore: v.loadingMore,
                    pullRefreshing: v.pullRefreshing,
                    intervalId: v.intervalId,
                    now: v.now
                };
            });
        })(self.setState(function (v) {
            return {
                marker: v.marker,
                lastUpdated: v.lastUpdated,
                error: v.error,
                items: v.items,
                refreshing: false,
                loadMoreError: v.loadMoreError,
                loadingMore: v.loadingMore,
                pullRefreshing: v.pullRefreshing,
                intervalId: v.intervalId,
                now: v.now
            };
        }));
    };
    var handleRefresh = function (v) {
        return fetch(v)(v.props.fetchFn(Data_Maybe.Nothing.value))(v.setState(function (v1) {
            return {
                marker: v1.marker,
                lastUpdated: v1.lastUpdated,
                error: Data_Maybe.Nothing.value,
                items: v1.items,
                refreshing: v1.refreshing,
                loadMoreError: false,
                loadingMore: v1.loadingMore,
                pullRefreshing: true,
                intervalId: v1.intervalId,
                now: v1.now
            };
        }))(function (res) {
            return Control_Apply.applySecond(Effect.applyEffect)(v.setState(function (v1) {
                return {
                    marker: v1.marker,
                    lastUpdated: v1.lastUpdated,
                    error: v1.error,
                    items: res.items,
                    refreshing: v1.refreshing,
                    loadMoreError: v1.loadMoreError,
                    loadingMore: v1.loadingMore,
                    pullRefreshing: false,
                    intervalId: v1.intervalId,
                    now: v1.now
                };
            }))(v.props.onRefresh);
        })(Control_Apply.applySecond(Effect.applyEffect)(v.setState(function (v1) {
            return {
                marker: v1.marker,
                lastUpdated: v1.lastUpdated,
                error: v1.error,
                items: v1.items,
                refreshing: v1.refreshing,
                loadMoreError: v1.loadMoreError,
                loadingMore: v1.loadingMore,
                pullRefreshing: false,
                intervalId: v1.intervalId,
                now: v1.now
            };
        }))(v.props.onRefresh));
    };
    var render = function (self) {
        if (self.state.error instanceof Data_Maybe.Just && (self.state.items.length === 0 && !self.state.refreshing)) {
            return ZAP_Dart_Client_Components_NetworkErrorPage.networkErrorPage(fetchInitial(self));
        };
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
            children: [ ZAP_Dart_Client_Components_Banner.banner({
                show: Data_Maybe.isJust(self.state.error) || self.state.loadMoreError,
                message: new ZAP_Dart_Client_Components_Banner.BMCustom("No internet connection available." + getLastUpdatedText(self.state.now)(self.state.lastUpdated))
            }), ZAP_Dart_Client_Components_FullPageSpinner.fullPageSpinner({
                show: self.state.refreshing
            }), ReactNative_FlatList.flatList()({
                contentContainerStyle: self.props.contentContainerStyle,
                data: self.state.items,
                renderItem: self.props.renderItem,
                keyExtractor: function (item, index) {
                    return self.props.keyExtractor({
                        item: item,
                        index: index
                    });
                },
                numColumns: self.props.numColumns,
                refreshing: self.state.pullRefreshing,
                onRefresh: handleRefresh(self),
                onEndReached: Control_Applicative.when(Effect.applicativeEffect)(!self.state.loadMoreError)(fetchMore(self)),
                onEndReachedThreshold: 0.1,
                ListHeaderComponent: self.props.header,
                ListFooterComponent: loadMoreFooter({
                    loadingMore: self.state.loadingMore,
                    loadMoreError: self.state.loadMoreError,
                    fetchMore: fetchMore(self)
                }),
                ListEmptyComponent: Data_Monoid.guard(React_Basic.monoidJSX)(!self.state.refreshing)(self.props.emptyComponent),
                keyboardShouldPersistTaps: ReactNative_FlatList.always,
                key: self.props.key
            }) ]
        });
    };
    var didUpdate = function (self) {
        return function (v) {
            return function __do() {
                Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(dictEq)(v.prevProps.params)(self.props.params))(fetchBg(self))();
                return Control_Applicative.when(Effect.applicativeEffect)(self.props.isFocused && self.props.isFocused !== v.prevProps.isFocused)(fetchUpdate(self))();
            };
        };
    };
    var didMount = function (self) {
        return function __do() {
            ZAP_Dart_Client_Screens_Utils.addClock(1.0)(self)();
            return fetchInitial(self)();
        };
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        willUnmount: willUnmount,
        didUpdate: didUpdate,
        render: render
    });
};
module.exports = {
    NetworkError: NetworkError,
    component: component,
    infiniteList: infiniteList,
    loadMoreFooterComponent: loadMoreFooterComponent,
    loadMoreFooter: loadMoreFooter,
    getLastUpdatedText: getLastUpdatedText,
    sheet: sheet
};
