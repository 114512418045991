// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var show = Effect_Uncurried.runEffectFn2($foreign["_show"]);
module.exports = {
    show: show,
    "short": $foreign["short"],
    "long": $foreign["long"]
};
