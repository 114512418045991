// Generated by purs version 0.13.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ZAP_Dart_Client_Contexts_AdvancedOpts = require("../ZAP.Dart.Client.Contexts.AdvancedOpts/index.js");
var ZAP_Dart_Client_Contexts_Global = require("../ZAP.Dart.Client.Contexts.Global/index.js");
var ZAP_Dart_Client_Flows_Top = require("../ZAP.Dart.Client.Flows.Top/index.js");
var ZAP_Dart_Client_RootNavWrapper = require("../ZAP.Dart.Client.RootNavWrapper/index.js");
var component = React_Basic.createComponent("ZAPDart");
var app = (function () {
    var render = function (self) {
        return React_Basic.element(ZAP_Dart_Client_Contexts_AdvancedOpts.advancedOptsProvider)({
            children: React_Basic.element(ZAP_Dart_Client_Contexts_Global.globalProvider)({
                children: React_Basic.element(ZAP_Dart_Client_RootNavWrapper.rootNavWrapper(ReactNavigation.createAppContainer(ZAP_Dart_Client_Flows_Top.topScreen)))({})
            })
        });
    };
    return React_Basic.toReactComponent()(Control_Category.identity(Control_Category.categoryFn))(component)({
        render: render
    });
})();
module.exports = {
    app: app
};
