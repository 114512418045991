// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_BabyForm = require("../ZAP.Dart.Client.Components.BabyForm/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_Divider = require("../ZAP.Dart.Client.Components.Divider/index.js");
var ZAP_Dart_Client_Components_Input_Validation = require("../ZAP.Dart.Client.Components.Input.Validation/index.js");
var ZAP_Dart_Client_Components_ProfileForm = require("../ZAP.Dart.Client.Components.ProfileForm/index.js");
var ZAP_Dart_Client_Components_ProfilePicture = require("../ZAP.Dart.Client.Components.ProfilePicture/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    profilePhotoContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(100), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(200), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(200), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(8.0), React_Basic_Native_Styles.shadowOffset({
        height: 4.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(4) ]),
    profilePhoto: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.borderRadius(100), React_Basic_Native_Styles.overflow(React_Basic_Native_PropTypes.hidden(React_Basic_Native_Styles.overflowV)) ]),
    submitButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(40) ]),
    cancelButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(20) ]),
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(25) ])
};
var renderDivider = ZAP_Dart_Client_Components_Divider.divider(sheet.divider);
var component = React_Basic.createComponent("EditProfile");
var editProfile = function (v) {
    return function (props) {
        var translateProfile = function (p) {
            return {
                firstName: p.firstName,
                lastName: p.lastName,
                mobile: p.mobile,
                email: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(p.email)(function (v1) {
                    if (v1 instanceof ZAP_Dart_Common.Confirmed) {
                        return new ZAP_Dart_Client_Components_ProfileForm.Confirmed(v1.value0.value);
                    };
                    if (v1 instanceof ZAP_Dart_Common.Unconfirmed) {
                        return new ZAP_Dart_Client_Components_ProfileForm.Unconfirmed(v1.value0.value);
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EditProfile (line 131, column 13 - line 133, column 57): " + [ v1.constructor.name ]);
                }),
                location: p.location,
                gender: p.gender
            };
        };
        var handlePickFile = function (self) {
            return function (res) {
                return self.setState(function (v1) {
                    return {
                        formProfile: v1.formProfile,
                        newAvatar: new Data_Maybe.Just(res),
                        registrationData: v1.registrationData
                    };
                });
            };
        };
        var deriveState = function (v1) {
            return {
                formProfile: translateProfile(v1.initD.profile),
                newAvatar: v1.initD.profile.avatarUrl,
                registrationData: v1.initD.profile.registrationData
            };
        };
        var didUpdate = function (self) {
            return function (v1) {
                return Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "registrationFields";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "isRequired";
                }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
                    return "input";
                }))(ZAP_Dart_Common.eqInputType))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString)))))()(new Data_Symbol.IsSymbol(function () {
                    return "profile";
                }))(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "registrationData";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "value";
                }))(ZAP_Dart_Common.eqValue))()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString)))))()(new Data_Symbol.IsSymbol(function () {
                    return "mobile";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "location";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "lastName";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "gender";
                }))(Data_Maybe.eqMaybe(ZAP_Dart_Common.eqGender)))()(new Data_Symbol.IsSymbol(function () {
                    return "firstName";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "email";
                }))(Data_Maybe.eqMaybe(ZAP_Dart_Common.eqEmail)))()(new Data_Symbol.IsSymbol(function () {
                    return "avatarUrl";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))))()(new Data_Symbol.IsSymbol(function () {
                    return "locations";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "cities";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString)))))))))(self.props.initD)(v1.prevProps.initD))(self.setState(Data_Function["const"](deriveState(self.props))));
            };
        };
        var initialState = deriveState(props);
        var cleanRegData = (function () {
            var cleanSingle = function (id) {
                return function (name) {
                    return function (oldVal) {
                        var newValue = Data_String_Common.trim(oldVal);
                        var valueOpt = (function () {
                            var v1 = Data_String_Common["null"](newValue);
                            if (v1) {
                                return Data_Maybe.Nothing.value;
                            };
                            if (!v1) {
                                return Data_Maybe.Just.create(new ZAP_Dart_Common.Single({
                                    value: newValue
                                }));
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EditProfile (line 152, column 20 - line 154, column 53): " + [ v1.constructor.name ]);
                        })();
                        return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(valueOpt)(function (value) {
                            return {
                                id: id,
                                name: name,
                                value: value
                            };
                        });
                    };
                };
            };
            var cleanMulti = function (id) {
                return function (name) {
                    return function (oldVal) {
                        var newValue = Data_Array.filter(function (a) {
                            return !Data_String_Common["null"](a);
                        })(Data_Functor.mapFlipped(Data_Functor.functorArray)(oldVal)(Data_String_Common.trim));
                        var valueOpt = (function () {
                            var v1 = Data_Array["null"](newValue);
                            if (v1) {
                                return Data_Maybe.Nothing.value;
                            };
                            if (!v1) {
                                return Data_Maybe.Just.create(new ZAP_Dart_Common.Multi({
                                    value: newValue
                                }));
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EditProfile (line 164, column 20 - line 166, column 52): " + [ v1.constructor.name ]);
                        })();
                        return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(valueOpt)(function (value) {
                            return {
                                id: id,
                                name: name,
                                value: value
                            };
                        });
                    };
                };
            };
            var cleanF = function (d) {
                if (d.value instanceof ZAP_Dart_Common.Single) {
                    return cleanSingle(d.id)(d.name)(d.value.value0.value);
                };
                if (d.value instanceof ZAP_Dart_Common.Multi) {
                    return cleanMulti(d.id)(d.name)(d.value.value0.value);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EditProfile (line 142, column 16 - line 144, column 54): " + [ d.value.constructor.name ]);
            };
            return Data_Array.mapMaybe(cleanF);
        })();
        var cleanProfileData = function (s) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(s)(Data_String_Common.trim))(function (newS) {
                var v1 = Data_String_Common["null"](newS);
                if (v1) {
                    return Data_Maybe.Nothing.value;
                };
                if (!v1) {
                    return new Data_Maybe.Just(newS);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EditProfile (line 173, column 20 - line 175, column 29): " + [ v1.constructor.name ]);
            });
        };
        var onSubmit = function (self) {
            var newProfile = {
                firstName: cleanProfileData(self.state.formProfile.firstName),
                lastName: cleanProfileData(self.state.formProfile.lastName),
                newAvatar: cleanProfileData(self.state.newAvatar),
                email: cleanProfileData(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(self.state.formProfile.email)(function (v1) {
                    if (v1 instanceof ZAP_Dart_Client_Components_ProfileForm.Confirmed) {
                        return v1.value0;
                    };
                    if (v1 instanceof ZAP_Dart_Client_Components_ProfileForm.Unconfirmed) {
                        return v1.value0;
                    };
                    if (v1 instanceof ZAP_Dart_Client_Components_ProfileForm.Unsubmitted) {
                        return v1.value0;
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EditProfile (line 195, column 15 - line 198, column 35): " + [ v1.constructor.name ]);
                })),
                location: cleanProfileData(self.state.formProfile.location),
                gender: self.state.formProfile.gender,
                registrationData: cleanRegData(self.state.registrationData)
            };
            var updateProfile = ZAP_Dart_Client_Screens_Utils.mkLApi(v.apiCalls.updateProfile({
                profile: newProfile
            }))(function (v1) {
                if (v1 instanceof Data_Either.Right) {
                    return self.props.onAction(new ReactNavigation_Types.PgSubmit(Data_Unit.unit));
                };
                if (v1 instanceof Data_Either.Left) {
                    return self.props.showDialog(new ReactNavigation_Types.Alert(ZAP_Dart_Client_Components_ProfileForm.updateProfileErrDialog(v1.value0)))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EditProfile (line 183, column 11 - line 185, column 115): " + [ v1.constructor.name ]);
            });
            return self.props.loadApiP(updateProfile);
        };
        var showConfirmAlert = function (self) {
            return self.props.showDialog(new ReactNavigation_Types.Confirm({
                title: "Save changes?",
                message: "Are you sure you want to save the changes made to your profile?",
                actionLabel: "Save"
            }))(onSubmit(self));
        };
        var render = function (self) {
            return React_Basic_Native_Generated.scrollView()({
                contentContainerStyle: sheet.container,
                children: [ ZAP_Dart_Client_Components_ProfilePicture.profilePicture({
                    width: 200,
                    height: 200,
                    avatarUrl: self.props.initD.profile.avatarUrl
                }), renderDivider, ZAP_Dart_Client_Components_ProfileForm.profileForm({
                    profile: self.state.formProfile,
                    registrationFields: self.props.initD.registrationFields,
                    locations: self.props.initD.locations,
                    onProfileChange: function (p) {
                        return self.setState(function (v1) {
                            return {
                                formProfile: p,
                                newAvatar: v1.newAvatar,
                                registrationData: v1.registrationData
                            };
                        });
                    }
                }), renderDivider, ZAP_Dart_Client_Components_BabyForm.babyForm({
                    registrationData: self.state.registrationData,
                    registrationFields: self.props.initD.registrationFields,
                    onChange: function (v1) {
                        return self.setState(function (v2) {
                            return {
                                formProfile: v2.formProfile,
                                newAvatar: v2.newAvatar,
                                registrationData: v1
                            };
                        });
                    },
                    onError: ZAP_Dart_Client_Components_Input_Validation.handleError(self)
                }), renderDivider, ZAP_Dart_Client_Components_Button.primaryButton({
                    style: sheet.submitButton,
                    title: "SUBMIT DETAILS",
                    onPress: showConfirmAlert(self),
                    disabled: false,
                    inverted: false,
                    size: ZAP_Dart_Client_Components_Button.md
                }), ZAP_Dart_Client_Components_Button.secondaryButton({
                    style: sheet.cancelButton,
                    title: "CANCEL",
                    onPress: self.props.onAction(ReactNavigation_Types.PgCancel.value),
                    disabled: false,
                    inverted: false
                }) ]
            });
        };
        return React_Basic.make()(component)({
            initialState: initialState,
            render: render,
            didUpdate: didUpdate
        })(props);
    };
};
module.exports = {
    editProfile: editProfile
};
