// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_External_KeyboardAwareScrollView = require("../ZAP.Dart.Client.Components.External.KeyboardAwareScrollView/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(50), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(40), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles.margin(React_Basic_Native_Styles.autoMargin)(React_Basic_Native_Styles.marginAuto) ]),
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    headerLabelContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(70) ]),
    headerLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontSize(40) ]),
    couponGraphic: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(180), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(360) ]),
    headerValueContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(40), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(70), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    couponContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(25), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    headerValue: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontSize(60), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.extraBold) ]),
    headerValueLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontSize(30), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.extraBold) ]),
    actionsContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(360), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(64) ]),
    actionsLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    actionsButtonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(70) ]),
    secondaryButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(20) ])
};
var component = React_Basic.createComponent("ClaimReceipt");
var claimReceipt = function (v) {
    var initialState = {};
    var didMount = function (self) {
        return self.props.updateTotalPoints(v.totalPoints);
    };
    var content = function (c) {
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(c)(function (v1) {
            if (v1 instanceof ZAP_Dart_Common.Points) {
                return React_Basic_Native_Generated.view()({
                    style: ZAP_Dart_Client_Styles_Utils.noStyle,
                    children: [ React_Basic_Native_Generated.view()({
                        style: sheet.headerLabelContainer,
                        children: [ ZAP_Dart_Client_Components_Typography.b1({
                            style: sheet.headerLabel,
                            value: "You earned",
                            color: ZAP_Dart_Client_Styles_Colors.altBg
                        }) ]
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.headerValueContainer,
                        children: [ ZAP_Dart_Client_Components_Typography.h1({
                            style: sheet.headerValue,
                            value: Data_Number_Format.toStringWith(Data_Number_Format.fixed(2))(Data_HugeNum.toNumber(v1.value0.earned)),
                            color: ZAP_Dart_Client_Styles_Colors.altBg
                        }), ZAP_Dart_Client_Components_Typography.h2({
                            style: sheet.headerValueLabel,
                            value: Data_Maybe.fromMaybe("points")(v1.value0.currencyName),
                            color: ZAP_Dart_Client_Styles_Colors.altBg
                        }) ]
                    }) ]
                });
            };
            if (v1 instanceof ZAP_Dart_Common.Coupon) {
                return React_Basic_Native_Generated.view()({
                    style: ZAP_Dart_Client_Styles_Utils.noStyle,
                    children: [ React_Basic_Native_Generated.view()({
                        style: sheet.headerLabelContainer,
                        children: [ ZAP_Dart_Client_Components_Typography.b1({
                            style: sheet.headerLabel,
                            value: "You earned",
                            color: ZAP_Dart_Client_Styles_Colors.altBg
                        }), ZAP_Dart_Client_Components_Typography.b1({
                            style: sheet.headerLabel,
                            value: "a coupon",
                            color: ZAP_Dart_Client_Styles_Colors.altBg
                        }) ]
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.couponContainer,
                        children: [ ZAP_Dart_Client_Components_Image.image({
                            style: sheet.couponGraphic,
                            source: ZAP_Dart_Client_Assets.couponGraphic,
                            resizeMode: React_Basic_Native_Styles.contain
                        }) ]
                    }) ]
                });
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.ClaimReceipt (line 56, column 11 - line 118, column 16): " + [ v1.constructor.name ]);
        });
    };
    var actions = function (v1) {
        var setActionsLabels = React_Basic_Native_Generated.view_((function () {
            if (v.isProfileComplete) {
                return [ ZAP_Dart_Client_Components_Typography.h4({
                    style: sheet.actionsLabel,
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    value: "Spend your points"
                }), ZAP_Dart_Client_Components_Typography.h4({
                    style: sheet.actionsLabel,
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    value: "on amazing rewards"
                }) ];
            };
            if (!v.isProfileComplete) {
                return [ ZAP_Dart_Client_Components_Typography.h4({
                    style: sheet.actionsLabel,
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    value: "Spend your points by"
                }), ZAP_Dart_Client_Components_Typography.h4({
                    style: sheet.actionsLabel,
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    value: "completing your profile"
                }) ];
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.ClaimReceipt (line 124, column 18 - line 148, column 14): " + [ v.isProfileComplete.constructor.name ]);
        })());
        var setActionsButtons = React_Basic_Native_Generated.view()({
            style: sheet.actionsButtonContainer,
            children: (function () {
                if (v.isProfileComplete) {
                    return [ ZAP_Dart_Client_Components_Button.primaryButton({
                        title: "GO TO CATALOG",
                        onPress: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.CRDone.value)),
                        disabled: false,
                        inverted: true,
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        size: ZAP_Dart_Client_Components_Button.md
                    }) ];
                };
                if (!v.isProfileComplete) {
                    return [ ZAP_Dart_Client_Components_Button.primaryButton({
                        title: "COMPLETE PROFILE",
                        onPress: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.CRCompleteProfile.value)),
                        disabled: false,
                        inverted: true,
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        size: ZAP_Dart_Client_Components_Button.md
                    }), ZAP_Dart_Client_Components_Button.secondaryButton({
                        title: "MAYBE LATER",
                        onPress: v1.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.CRMaybeLater.value)),
                        disabled: false,
                        inverted: true,
                        style: sheet.secondaryButton
                    }) ];
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.ClaimReceipt (line 154, column 13 - line 181, column 18): " + [ v.isProfileComplete.constructor.name ]);
            })()
        });
        return React_Basic_Native_Generated.view()({
            style: sheet.actionsContainer,
            children: [ setActionsLabels, setActionsButtons ]
        });
    };
    var render = function (v1) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_External_KeyboardAwareScrollView.keyboardAwareScrollView()({
                contentContainerStyle: sheet.scrollContainer,
                children: Data_Array.snoc(content(v.claims))(actions(v1.props))
            }))
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        render: render
    });
};
module.exports = {
    claimReceipt: claimReceipt
};
