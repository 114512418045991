// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
module.exports = {
    enterCode: $foreign.enterCode,
    defaultRewardItem: $foreign.defaultRewardItem,
    couponGraphic: $foreign.couponGraphic,
    lostConnection: $foreign.lostConnection,
    emptyContainer: $foreign.emptyContainer,
    emptyCatalog: $foreign.emptyCatalog,
    emptyCart: $foreign.emptyCart,
    emptyRewards: $foreign.emptyRewards,
    emptyTransactions: $foreign.emptyTransactions,
    noSearchResults: $foreign.noSearchResults,
    generalError: $foreign.generalError,
    adchoices: $foreign.adchoices,
    downloadBlack: $foreign.downloadBlack,
    downloadWhite: $foreign.downloadWhite,
    downloadGooglePlay: $foreign.downloadGooglePlay,
    downloadAppstore: $foreign.downloadAppstore,
    confettiDesktop: $foreign.confettiDesktop,
    confettiMobileLeft: $foreign.confettiMobileLeft,
    confettiMobileRight: $foreign.confettiMobileRight
};
