// Generated by purs version 0.13.5
"use strict";
var imageUri = function (uri) {
    return {
        uri: uri
    };
};
module.exports = {
    imageUri: imageUri
};
