// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_ApiClient_Utils = require("../ZAP.Dart.Client.ApiClient.Utils/index.js");
var ZAP_Dart_Client_Dialogs_Template_Alert = require("../ZAP.Dart.Client.Dialogs.Template.Alert/index.js");
var ZAP_Dart_Client_Screens_EnterOtp = require("../ZAP.Dart.Client.Screens.EnterOtp/index.js");
var ZAP_Dart_Client_Screens_ResetPin = require("../ZAP.Dart.Client.Screens.ResetPin/index.js");
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "resetPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "enterOtp";
}))));
var flow = (function () {
    var showSuccessDialog = Control_Apply.applySecond(ReactNavigation.navmApply)(ReactNavigation_Class_MonadNavM.navDialogI(ReactNavigation_Class_MonadNavM.monadNMNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
        title: "SUCCESS",
        message: "You have successfully reset your 6-digit PIN!",
        actionLabel: "ENTER PIN"
    }))(Control_Applicative.pure(ReactNavigation.navmApplicative)(Data_Unit.unit));
    var showErrorDialog = function (v) {
        return Control_Apply.applySecond(ReactNavigation.navmApply)(ReactNavigation_Class_MonadNavM.navDialogI(ReactNavigation_Class_MonadNavM.monadNMNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
            title: v.title,
            message: v.message,
            actionLabel: "Try Again"
        }))(Control_Applicative.pure(ReactNavigation.navmApplicative)(Data_Unit.unit));
    };
    var resetPinError = function (err) {
        return {
            title: "Reset Pin Failed",
            message: "Invalid user"
        };
    };
    var initRouteF = function (v) {
        return {
            mobileNumber: v.mobileNumber,
            verifyOtp: function (otp) {
                return v.clientApi.verifyOtp({
                    otp: otp,
                    refId: v.refId
                });
            },
            resendOtp: v.clientApi.resendOtp({
                refId: v.refId
            })
        };
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable["null"]
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var afterInitF = function (v) {
        if (v.value instanceof Data_Either.Right) {
            return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "resetPin";
            }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.resetPin))(function (v1) {
                if (v1 instanceof Data_Either.Right) {
                    return Control_Bind.bind(ReactNavigation.navmBind)(ZAP_Dart_Client_ApiClient_Utils.callApi_(ReactNavigation_Class_MonadNavM.monadNMNavM)(ReactNavigation.navmMonad)(v.info.clientApi.resetPin({
                        pin: v1.value0,
                        mobileNumber: v.info.mobileNumber,
                        refId: v.info.refId,
                        otp: v.value.value0
                    })))(function (v2) {
                        if (v2 instanceof Data_Either.Right) {
                            return showSuccessDialog;
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return showErrorDialog(resetPinError(v2.value0));
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.ResetPin (line 60, column 11 - line 62, column 56): " + [ v2.constructor.name ]);
                    });
                };
                if (v1 instanceof Data_Either.Left) {
                    return Control_Applicative.pure(ReactNavigation.navmApplicative)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.ResetPin (line 57, column 7 - line 63, column 28): " + [ v1.constructor.name ]);
            });
        };
        if (v.value instanceof Data_Either.Left) {
            return showErrorDialog(v.value.value0);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.ResetPin (line 54, column 117 - line 64, column 32): " + [ v.value.constructor.name ]);
    };
    return ReactNavigation["createFlow'"](new Data_Symbol.IsSymbol(function () {
        return "enterOtp";
    }))(ReactNavigation.navCreatorStack)(rs)(rs.enterOtp)(initRouteF)(configBuilder)(afterInitF);
})();
var resetPinScreen = ReactNavigation.createFlowScreen()(flow)({
    enterOtp: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_EnterOtp.enterOtp)),
    resetPin: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_ResetPin.resetPin))
});
module.exports = {
    resetPinScreen: resetPinScreen
};
