// Generated by purs version 0.13.5
"use strict";
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var JustifyContent = function (x) {
    return x;
};
var FlexWrap = function (x) {
    return x;
};
var FlexDirection = function (x) {
    return x;
};
var FlexAlignment = function (x) {
    return x;
};
var FlexStartEnd = function (flexEnd, flexStart) {
    this.flexEnd = flexEnd;
    this.flexStart = flexStart;
};
var wrap = "wrap";
var stretch = "stretch";
var spaceBetween = "space-between";
var spaceAround = "space-around";
var rowReverse = "row-reverse";
var row = "row";
var nowrap = "nowrap";
var justifyContentFlexSE = new FlexStartEnd("flex-end", "flex-start");
var justifyContent = React_Basic_Native_Styles.unsafeStyleProp("justifyContent");
var jcCenter = new React_Basic_Native_PropTypes.CenterEnum("center");
var flexWrap = React_Basic_Native_Styles.unsafeStyleProp("flexWrap");
var flexStart = function (dict) {
    return dict.flexStart;
};
var flexShrink = React_Basic_Native_Styles.unsafeStyleProp("flexShrink");
var flexGrow = React_Basic_Native_Styles.unsafeStyleProp("flexGrow");
var flexEnd = function (dict) {
    return dict.flexEnd;
};
var flexDirection = React_Basic_Native_Styles.unsafeStyleProp("flexDirection");
var flexAlignmentCenter = new React_Basic_Native_PropTypes.CenterEnum("center");
var flexAlignmentAuto = new React_Basic_Native_PropTypes.AutoEnum("auto");
var columnReverse = "column-reverse";
var column = "column";
var alignmentStartEnd = new FlexStartEnd("flex-end", "flex-start");
var alignSelf = React_Basic_Native_Styles.unsafeStyleProp("alignSelf");
var alignItems = React_Basic_Native_Styles.unsafeStyleProp("alignItems");
var alignContent = React_Basic_Native_Styles.unsafeStyleProp("alignContent");
module.exports = {
    flexEnd: flexEnd,
    flexStart: flexStart,
    FlexStartEnd: FlexStartEnd,
    FlexAlignment: FlexAlignment,
    stretch: stretch,
    FlexDirection: FlexDirection,
    row: row,
    rowReverse: rowReverse,
    column: column,
    columnReverse: columnReverse,
    FlexWrap: FlexWrap,
    wrap: wrap,
    nowrap: nowrap,
    JustifyContent: JustifyContent,
    spaceBetween: spaceBetween,
    spaceAround: spaceAround,
    alignContent: alignContent,
    alignItems: alignItems,
    alignSelf: alignSelf,
    flexDirection: flexDirection,
    flexGrow: flexGrow,
    flexShrink: flexShrink,
    flexWrap: flexWrap,
    justifyContent: justifyContent,
    alignmentStartEnd: alignmentStartEnd,
    flexAlignmentAuto: flexAlignmentAuto,
    flexAlignmentCenter: flexAlignmentCenter,
    justifyContentFlexSE: justifyContentFlexSE,
    jcCenter: jcCenter
};
