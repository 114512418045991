// Generated by purs version 0.13.5
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Formatter_Internal = require("../Data.Formatter.Internal/index.js");
var Data_Formatter_Parser_Utils = require("../Data.Formatter.Parser.Utils/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Global = require("../Global/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var parseDigit = function (dictMonad) {
    return function (dictStringLike) {
        return Text_Parsing_Parser_Combinators["try"](dictMonad)(Data_Formatter_Parser_Utils.oneOfAs(Data_Functor.functorArray)(Data_Foldable.foldableArray)(dictMonad)(Text_Parsing_Parser_String["char"](dictStringLike)(dictMonad))([ new Data_Tuple.Tuple("0", 0), new Data_Tuple.Tuple("1", 1), new Data_Tuple.Tuple("2", 2), new Data_Tuple.Tuple("3", 3), new Data_Tuple.Tuple("4", 4), new Data_Tuple.Tuple("5", 5), new Data_Tuple.Tuple("6", 6), new Data_Tuple.Tuple("7", 7), new Data_Tuple.Tuple("8", 8), new Data_Tuple.Tuple("9", 9) ]));
    };
};
var parseFractional = function (dictMonad) {
    return function (dictStringLike) {
        return Data_Functor.mapFlipped(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(parseDigit(dictMonad)(dictStringLike)))((function () {
            var $11 = Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(Data_Show.show(Data_Show.showInt));
            return function ($12) {
                return Global.readFloat((function (v) {
                    return "0." + v;
                })($11($12)));
            };
        })());
    };
};
var parseInteger = function (dictMonad) {
    return function (dictStringLike) {
        return Data_Functor.mapFlipped(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(parseDigit(dictMonad)(dictStringLike)))(Data_Formatter_Internal.foldDigits(Data_Foldable.foldableArray));
    };
};
var parseMaybeInteger = function (dictMonad) {
    return function (dictStringLike) {
        return Text_Parsing_Parser_Combinators.optionMaybe(dictMonad)(parseInteger(dictMonad)(dictStringLike));
    };
};
var parseNumber = function (dictMonad) {
    return function (dictStringLike) {
        return Control_Apply.apply(Text_Parsing_Parser.applyParserT(dictMonad))(Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Semiring.add(Data_Semiring.semiringNumber))(Data_Functor.mapFlipped(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(parseInteger(dictMonad)(dictStringLike))(Data_Int.toNumber)))(Text_Parsing_Parser_Combinators.option(dictMonad)(0.0)(Text_Parsing_Parser_Combinators["try"](dictMonad)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(dictStringLike)(dictMonad)([ ".", "," ]))(parseFractional(dictMonad)(dictStringLike)))));
    };
};
module.exports = {
    parseInteger: parseInteger,
    parseMaybeInteger: parseMaybeInteger,
    parseNumber: parseNumber,
    parseDigit: parseDigit
};
