// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_String_Regex_Unsafe = require("../Data.String.Regex.Unsafe/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Platform = require("../ReactNative.Platform/index.js");
var ReactNative_TextInput = require("../ReactNative.TextInput/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var Default = (function () {
    function Default() {

    };
    Default.value = new Default();
    return Default;
})();
var Numeric = (function () {
    function Numeric() {

    };
    Numeric.value = new Numeric();
    return Numeric;
})();
var AlphaNumeric = (function () {
    function AlphaNumeric() {

    };
    AlphaNumeric.value = new AlphaNumeric();
    return AlphaNumeric;
})();
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.ubuntuMono.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.fg), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    dashedLineContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    dashedLine: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderTopColor(React_Basic_Native_PropTypes_Color.black), React_Basic_Native_Styles.borderTopWidth(1) ])
};
var getDInputProps = (function () {
    var numericRegex = Data_String_Regex_Unsafe.unsafeRegex("^[0-9]*$")(Data_String_Regex_Flags.global);
    var alphaNumericRegex = Data_String_Regex_Unsafe.unsafeRegex("^[a-zA-Z0-9]*$")(Data_String_Regex_Flags.global);
    return function (v) {
        if (v instanceof Default) {
            return {
                keyboardType: ReactNative_TextInput["default"],
                validate: function (v1) {
                    return true;
                }
            };
        };
        if (v instanceof Numeric) {
            return {
                keyboardType: ReactNative_TextInput.numeric,
                validate: function (text) {
                    return Data_String_Regex.test(numericRegex)(text);
                }
            };
        };
        if (v instanceof AlphaNumeric) {
            return {
                keyboardType: ReactNative_TextInput["default"],
                validate: function (text) {
                    return Data_String_Regex.test(alphaNumericRegex)(text);
                }
            };
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.DashedInput (line 125, column 18 - line 137, column 6): " + [ v.constructor.name ]);
    };
})();
var component = React_Basic.createComponent("DashedInput");
var dashedInput = (function () {
    var fontWidth = function (n) {
        return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(8 * n | 0)(16);
    };
    var totalFontWidth = function (n) {
        return function (s) {
            return function (l) {
                return ((fontWidth(n) + s | 0) * l | 0) + 1 | 0;
            };
        };
    };
    var render = function (props) {
        var dInputProps = getDInputProps(props.inputType);
        return React_Basic_Native_Generated.view()({
            style: props.style,
            children: [ React_Basic_Native_Generated.textInput()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.input, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles_Text.fontSize(props.fontSize), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(props.letterSpacing), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(totalFontWidth(props.fontSize)(props.letterSpacing)(props.length)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(props.fontSize + 4 | 0), React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(0), React_Basic_Native_Styles.margin(React_Basic_Native_Styles.intMargin)(0), React_Basic_Native_Styles_Text.color(props.color) ]) ]),
                keyboardType: dInputProps.keyboardType,
                autoCapitalize: ReactNative_TextInput.none,
                spellCheck: false,
                autoCorrect: false,
                secureTextEntry: props.secureTextEntry,
                autoFocus: props.autoFocus,
                maxLength: Data_Int.toNumber(props.length),
                value: props.value,
                onChangeText: function (v) {
                    return Control_Applicative.when(Effect.applicativeEffect)(Data_String_CodePoints.length(v) <= props.length && dInputProps.validate(v))(props.onChange(v))();
                },
                onSubmitEditing: props.onSubmitEditing
            }), React_Basic_Native_Generated.view()({
                style: sheet.dashedLineContainer,
                children: Data_Functor.mapFlipped(Data_Functor.functorArray)(Data_Array.range(1)(props.length))(function (i) {
                    return React_Basic_Native_Generated.view()({
                        style: React_Basic_Native_Styles["styles'"]([ sheet.dashedLine, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(fontWidth(props.fontSize)), (function () {
                            if (ReactNative_Platform.os instanceof ReactNative_Platform.Android) {
                                return React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(props.letterSpacing)(2));
                            };
                            return React_Basic_Native_Styles.marginRight(React_Basic_Native_Styles.intMarginRight)(props.letterSpacing);
                        })(), React_Basic_Native_Styles.borderTopColor(props.color) ]) ]),
                        key: Data_Show.show(Data_Show.showInt)(i)
                    });
                })
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    dashedInput: dashedInput,
    Default: Default,
    Numeric: Numeric,
    AlphaNumeric: AlphaNumeric
};
