// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var IOS = (function () {
    function IOS() {

    };
    IOS.value = new IOS();
    return IOS;
})();
var Android = (function () {
    function Android() {

    };
    Android.value = new Android();
    return Android;
})();
var Web = (function () {
    function Web() {

    };
    Web.value = new Web();
    return Web;
})();
var OSOther = (function () {
    function OSOther(value0) {
        this.value0 = value0;
    };
    OSOther.create = function (value0) {
        return new OSOther(value0);
    };
    return OSOther;
})();
var showPlatform = new Data_Show.Show(function (v) {
    if (v instanceof Android) {
        return "android";
    };
    if (v instanceof IOS) {
        return "ios";
    };
    if (v instanceof Web) {
        return "web";
    };
    if (v instanceof OSOther) {
        return v.value0;
    };
    throw new Error("Failed pattern match at ReactNative.Platform (line 24, column 1 - line 28, column 23): " + [ v.constructor.name ]);
});
var os = (function () {
    if ($foreign["_os"] === "ios") {
        return IOS.value;
    };
    if ($foreign["_os"] === "android") {
        return Android.value;
    };
    if ($foreign["_os"] === "web") {
        return Web.value;
    };
    return new OSOther($foreign["_os"]);
})();
var eqPlatform = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof IOS && y instanceof IOS) {
            return true;
        };
        if (x instanceof Android && y instanceof Android) {
            return true;
        };
        if (x instanceof Web && y instanceof Web) {
            return true;
        };
        if (x instanceof OSOther && y instanceof OSOther) {
            return x.value0 === y.value0;
        };
        return false;
    };
});
module.exports = {
    IOS: IOS,
    Android: Android,
    Web: Web,
    OSOther: OSOther,
    os: os,
    showPlatform: showPlatform,
    eqPlatform: eqPlatform
};
