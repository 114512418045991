// Generated by purs version 0.13.5
"use strict";
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var BMNetworkError = (function () {
    function BMNetworkError() {

    };
    BMNetworkError.value = new BMNetworkError();
    return BMNetworkError;
})();
var BMCustom = (function () {
    function BMCustom(value0) {
        this.value0 = value0;
    };
    BMCustom.create = function (value0) {
        return new BMCustom(value0);
    };
    return BMCustom;
})();
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.black), React_Basic_Native_Styles.minHeight(React_Basic_Native_Styles.intMinHeight)(30), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(10) ]),
    text: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(7) ])
};
var getMessage = function (v) {
    if (v instanceof BMNetworkError) {
        return "No internet connection available.";
    };
    if (v instanceof BMCustom) {
        return v.value0;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Banner (line 46, column 14 - line 48, column 24): " + [ v.constructor.name ]);
};
var component = React_Basic.createComponent("Banner");
var banner = (function () {
    var render = function (v) {
        if (v.show) {
            return React_Basic_Native_Generated.view()({
                style: sheet.container,
                children: [ ZAP_Dart_Client_Components_Typography.b4({
                    color: React_Basic_Native_PropTypes_Color.white,
                    style: sheet.text,
                    value: getMessage(v.message)
                }) ]
            });
        };
        return Data_Monoid.mempty(React_Basic.monoidJSX);
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    BMNetworkError: BMNetworkError,
    BMCustom: BMCustom,
    banner: banner
};
