// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Exists = require("../Data.Exists/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Formatter_DateTime = require("../Data.Formatter.DateTime/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var Data_Time = require("../Data.Time/index.js");
var Data_Time_Duration = require("../Data.Time.Duration/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Now = require("../Effect.Now/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var ReactNative_Linking = require("../ReactNative.Linking/index.js");
var ReactNative_Platform = require("../ReactNative.Platform/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var timeFormatter = Data_Either.fromRight()(Data_Formatter_DateTime.parseFormatString("hh:mm:ss a"));
var removeClock = function (intervalId) {
    if (intervalId instanceof Data_Maybe.Just) {
        return Effect_Timer.clearInterval(intervalId.value0);
    };
    if (intervalId instanceof Data_Maybe.Nothing) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.Utils (line 129, column 26 - line 131, column 23): " + [ intervalId.constructor.name ]);
};
var readableDateFormatter = Data_Either.fromRight()(Data_Formatter_DateTime.parseFormatString("MMM DD, YYYY"));
var openUrl = function (url) {
    if (ReactNative_Platform.os instanceof ReactNative_Platform.Web) {
        return Data_Functor["void"](Effect.functorEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.open(url)("_blank")("")));
    };
    return ReactNative_Linking.openURL(url);
};
var mkLApi = function (cm) {
    return function (onR) {
        return Data_Exists.mkExists(new ReactNavigation_Types["LApiM'"](cm, onR));
    };
};
var isInt = function (n) {
    return Data_Int.floor(n) === Data_Int.ceil(n);
};
var getNonEmpty = function (s) {
    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Data_String_NonEmpty_Internal.fromString(s))(Data_String_NonEmpty_Internal.toString);
};
var fromDate = function (d) {
    return Data_JSDate.fromDateTime(new Data_DateTime.DateTime(d, Data_Bounded.bottom(Data_Time.boundedTime)));
};
var formatTime = function (t) {
    return Data_Formatter_DateTime.format(timeFormatter)(new Data_DateTime.DateTime(Data_Bounded.bottom(Data_Date.boundedDate), t));
};
var formatPoints = function (n) {
    var n$prime = Data_HugeNum.toNumber(n);
    var $13 = isInt(n$prime);
    if ($13) {
        return Data_Number_Format.toString(n$prime);
    };
    return Data_Number_Format.toStringWith(Data_Number_Format.fixed(2))(n$prime);
};
var formatDate = function (d) {
    return Data_Formatter_DateTime.format(readableDateFormatter)(new Data_DateTime.DateTime(d, Data_Bounded.bottom(Data_Time.boundedTime)));
};
var dateFormatter = Data_Either.fromRight()(Data_Formatter_DateTime.parseFormatString("YYYY-MM-DD"));
var readDate = function (s) {
    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Data_Either.hush(Data_Formatter_DateTime.unformat(dateFormatter)(s)))(Data_DateTime.date);
};
var readJsDate = function (s) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(fromDate)(readDate(s));
};
var writeDate = function (dt) {
    return Data_Formatter_DateTime.format(dateFormatter)(new Data_DateTime.DateTime(dt, Data_Bounded.bottom(Data_Time.boundedTime)));
};
var writeJsDate = function (d) {
    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Data_JSDate.toDate(d))(function (dt) {
        return Data_Formatter_DateTime.format(dateFormatter)(new Data_DateTime.DateTime(dt, Data_Bounded.bottom(Data_Time.boundedTime)));
    });
};
var addToCartErr = function (err) {
    return function (productName) {
        return ReactNavigation_Types.Alert.create({
            title: "Unable to add to cart",
            message: (function () {
                if (err instanceof ZAP_Dart_Common.UpdateCartErrInsufficientAllocation) {
                    var m2 = (function () {
                        if (err.value0.isAlreadyInCart instanceof Data_Maybe.Just && err.value0.isAlreadyInCart.value0) {
                            return "and already in your cart";
                        };
                        return "";
                    })();
                    return Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")(Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]))([ "You requested to add more of", Data_Maybe.fromMaybe("this product")(productName), "than the", Data_Maybe.maybe("quantity")(Data_Show.show(Data_Show.showInt))(err.value0.availableQuantity), "available in stock", m2 ])) + ".";
                };
                return "Looks like we encountered a problem";
            })(),
            actionLabel: (function () {
                if (err instanceof ZAP_Dart_Common.UpdateCartErrInsufficientAllocation) {
                    return "Okay";
                };
                return "Try Again";
            })()
        });
    };
};
var addClock = function (minute) {
    return function (self) {
        var intervalDuration = Data_Int.ceil((function () {
            var v = Data_Time_Duration.fromDuration(Data_Time_Duration.durationMinutes)(minute);
            return v;
        })());
        return function __do() {
            var v = Effect_Now.nowDateTime();
            self.setState(function (v1) {
                var $24 = {};
                for (var $25 in v1) {
                    if ({}.hasOwnProperty.call(v1, $25)) {
                        $24[$25] = v1[$25];
                    };
                };
                $24.now = new Data_Maybe.Just(v);
                return $24;
            })();
            var v1 = Effect_Timer.setInterval(intervalDuration)(function __do() {
                var v1 = Effect_Now.nowDateTime();
                return self.setState(function (v2) {
                    var $28 = {};
                    for (var $29 in v2) {
                        if ({}.hasOwnProperty.call(v2, $29)) {
                            $28[$29] = v2[$29];
                        };
                    };
                    $28.now = new Data_Maybe.Just(v1);
                    return $28;
                })();
            })();
            return self.setState(function (v2) {
                var $32 = {};
                for (var $33 in v2) {
                    if ({}.hasOwnProperty.call(v2, $33)) {
                        $32[$33] = v2[$33];
                    };
                };
                $32.intervalId = new Data_Maybe.Just(v1);
                return $32;
            })();
        };
    };
};
module.exports = {
    formatPoints: formatPoints,
    formatDate: formatDate,
    openUrl: openUrl,
    formatTime: formatTime,
    mkLApi: mkLApi,
    fromDate: fromDate,
    readDate: readDate,
    writeDate: writeDate,
    readJsDate: readJsDate,
    writeJsDate: writeJsDate,
    getNonEmpty: getNonEmpty,
    addClock: addClock,
    removeClock: removeClock,
    addToCartErr: addToCartErr
};
