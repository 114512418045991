// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ])
};
var splashE = React_Basic_Hooks.component()()("Splash")(function (v) {
    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqUnit)(Data_Unit.unit)(function __do() {
        v.onAction(ReactNavigation_Types.PgSubmit.create(Data_Unit.unit))();
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    }))(function () {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_Native_Generated.view()({
            style: sheet.container
        }));
    });
});
var splash = React_Basic.element(Effect_Unsafe.unsafePerformEffect(splashE));
module.exports = {
    splash: splash
};
