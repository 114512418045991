// Generated by purs version 0.13.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Long = require("../Data.Long/index.js");
var Data_Long_Internal = require("../Data.Long.Internal/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Millis = function (x) {
    return x;
};
var showMillis = new Data_Show.Show(function (v) {
    return Data_Long.toString(v) + "ms";
});
var ordMillis = Data_Long_Internal["ordLong'"];
var newtypeMillis = new Data_Newtype.Newtype(function (n) {
    return n;
}, Millis);
var semigroupMillis = new Data_Semigroup.Semigroup(Data_Newtype.over2(newtypeMillis)(newtypeMillis)(Millis)(Data_Semiring.add(Data_Long_Internal["semiringLong'Signed"])));
var monoidMillis = new Data_Monoid.Monoid(function () {
    return semigroupMillis;
}, Data_Newtype.wrap(newtypeMillis)(Data_Semiring.zero(Data_Long_Internal["semiringLong'Signed"])));
var eqMillis = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_Long_Internal["eqLong'"])(x)(y);
    };
});
module.exports = {
    Millis: Millis,
    newtypeMillis: newtypeMillis,
    showMillis: showMillis,
    eqMillis: eqMillis,
    ordMillis: ordMillis,
    semigroupMillis: semigroupMillis,
    monoidMillis: monoidMillis
};
