// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Maybe_Trans = require("../Control.Monad.Maybe.Trans/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var ExpressRpc_Types = require("../ExpressRpc.Types/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var Record = require("../Record/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var ZAP_Dart_Client_Dialogs_Template_Alert = require("../ZAP.Dart.Client.Dialogs.Template.Alert/index.js");
var InitD = (function () {
    function InitD(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    InitD.create = function (value0) {
        return function (value1) {
            return new InitD(value0, value1);
        };
    };
    return InitD;
})();
var AuthCtx = function (x) {
    return x;
};
var AuthNavM = function (x) {
    return x;
};
var RunAuthNavM = function (runAuthNavM) {
    this.runAuthNavM = runAuthNavM;
};
var ProcessInfosRL = function (processInfosRL) {
    this.processInfosRL = processInfosRL;
};
var ApplyAuthRL = function (applyAuthRL) {
    this.applyAuthRL = applyAuthRL;
};
var ApplyAuth = function (applyAuth) {
    this.applyAuth = applyAuth;
};
var runAuthNavM = function (dict) {
    return dict.runAuthNavM;
};
var processInfosRLNil = new ProcessInfosRL(function (v) {
    return function (v1) {
        return function (v2) {
            return {};
        };
    };
});
var processInfosRL = function (dict) {
    return dict.processInfosRL;
};
var processInfosRLConsInitD = function (dictProcessInfosRL) {
    return function (dictEquals) {
        return function (dictIsSymbol) {
            return function (dictCons) {
                return function (dictCons1) {
                    return function (dictLacks) {
                        return new ProcessInfosRL(function (at) {
                            return function (v) {
                                return function (r) {
                                    var rest = processInfosRL(dictProcessInfosRL)(at)(Type_Data_RowList.RLProxy.value)(r);
                                    var current = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r);
                                    var $$new = current.value0(at)(current.value1);
                                    return Record_Builder.build(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)($$new))(rest);
                                };
                            };
                        });
                    };
                };
            };
        };
    };
};
var processInfosRLConsInitD$prime = function (dictProcessInfosRL) {
    return function (dictEquals) {
        return function (dictIsSymbol) {
            return function (dictCons) {
                return function (dictCons1) {
                    return function (dictLacks) {
                        return new ProcessInfosRL(function (at) {
                            return function (v) {
                                return function (r) {
                                    var rest = processInfosRL(dictProcessInfosRL)(at)(Type_Data_RowList.RLProxy.value)(r);
                                    var current = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r);
                                    return Record_Builder.build(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(current(at)))(rest);
                                };
                            };
                        });
                    };
                };
            };
        };
    };
};
var processInfos = function (dictRowToList) {
    return function (dictProcessInfosRL) {
        return function (r) {
            return function (at) {
                return processInfosRL(dictProcessInfosRL)(at)(Type_Data_RowList.RLProxy.value)(r);
            };
        };
    };
};
var plusAuthNavM = Control_Monad_Maybe_Trans.plusMaybeT(Control_Monad_Reader_Trans.monadReaderT(ReactNavigation.navmMonad));
var navPushF = function (dictHasRoute) {
    return function (dictMonadReader) {
        return function (dictMonadNavM) {
            return function (dictPlus) {
                return function (r) {
                    return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadReader.MonadAsk0()))(function (v) {
                        return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(ReactNavigation_Class_MonadNavM.navPushI(dictHasRoute)(dictMonadNavM)(r)(v))(function (v1) {
                            if (v1 instanceof Data_Maybe.Just) {
                                return Control_Applicative.pure((dictMonadNavM.Monad0()).Applicative0())(v1.value0);
                            };
                            if (v1 instanceof Data_Maybe.Nothing) {
                                return Control_Plus.empty(dictPlus);
                            };
                            throw new Error("Failed pattern match at ReactNavigation.AuthFlow (line 164, column 3 - line 166, column 21): " + [ v1.constructor.name ]);
                        });
                    });
                };
            };
        };
    };
};
var monadReaderAuthNavM = Control_Monad_Maybe_Trans.monadReaderMaybeT(Control_Monad_Reader_Trans.monadReaderReaderT(ReactNavigation.navmMonad));
var monadEffAuthNavM = Control_Monad_Maybe_Trans.monadEffectMaybe(Control_Monad_Reader_Trans.monadEffectReader(ReactNavigation.navmMonadEffect));
var monadAuthNavM = Control_Monad_Maybe_Trans.monadMaybeT(Control_Monad_Reader_Trans.monadReaderT(ReactNavigation.navmMonad));
var monadAskAuthNavM = Control_Monad_Maybe_Trans.monadAskMaybeT(Control_Monad_Reader_Trans.monadAskReaderT(ReactNavigation.navmMonad));
var monadAffAuthNavM = Effect_Aff_Class.monadAffMaybe(Effect_Aff_Class.monadAffReader(ReactNavigation.navmMonadAff));
var logout = function (dictMonadReader) {
    return function (dictMonadNavM) {
        return function (dictPlus) {
            return Control_Apply.applySecond(((dictMonadNavM.Monad0()).Bind1()).Apply0())(Control_Plus.empty(dictPlus))(Control_Applicative.pure((dictMonadNavM.Monad0()).Applicative0())(Data_Unit.unit));
        };
    };
};
var general = {
    title: "Oops, something's not right.",
    message: "We are unable to process your request right now."
};
var getErrDialog = function (err) {
    if (err instanceof ExpressRpc_Types.Server && err.value0 instanceof ExpressRpc_Types.Unauthorized) {
        return {
            title: "LOGOUT",
            message: "You are unauthorized"
        };
    };
    if (err instanceof ExpressRpc_Types.Server && err.value0 instanceof ExpressRpc_Types.IncompatibleVersion) {
        return {
            title: "Update App",
            message: "You have a deprecated app"
        };
    };
    if (err instanceof ExpressRpc_Types.Server && err.value0 instanceof ExpressRpc_Types.InternalErr) {
        return general;
    };
    if (err instanceof ExpressRpc_Types.Server && err.value0 instanceof ExpressRpc_Types.BadRequest) {
        return general;
    };
    if (err instanceof ExpressRpc_Types.Server && err.value0 instanceof ExpressRpc_Types.NotFound) {
        return general;
    };
    if (err instanceof ExpressRpc_Types.MalformedRes) {
        return general;
    };
    throw new Error("Failed pattern match at ReactNavigation.AuthFlow (line 382, column 20 - line 389, column 26): " + [ err.constructor.name ]);
};
var processClientErr = function (dictMonadNavM) {
    return function (e) {
        return ReactNavigation_Class_MonadNavM.liftNavM(dictMonadNavM)((function () {
            if (e instanceof Data_Either.Right) {
                return Control_Applicative.pure(ReactNavigation.navmApplicative)(e.value0);
            };
            if (e instanceof Data_Either.Left) {
                return ReactNavigation.navErrDialog(getErrDialog(e.value0));
            };
            throw new Error("Failed pattern match at ReactNavigation.AuthFlow (line 370, column 7 - line 372, column 48): " + [ e.constructor.name ]);
        })());
    };
};
var processClientErr$prime = function (dictMonadNavM) {
    return function (dictPlus) {
        return function (e) {
            if (e instanceof Data_Either.Right) {
                return Control_Applicative.pure((dictMonadNavM.Monad0()).Applicative0())(e.value0);
            };
            if (e instanceof Data_Either.Left) {
                var unauthD = ReactNavigation_Class_MonadNavM.navDialogI(dictMonadNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
                    title: "Session Expired",
                    message: "Your session has expired. You can log in again to get back.",
                    actionLabel: "Log in Again"
                });
                var errorDialog = ReactNavigation_Class_MonadNavM.liftNavM(dictMonadNavM)(ReactNavigation.navErrDialog(getErrDialog(e.value0)));
                if (e.value0 instanceof ExpressRpc_Types.Server && e.value0.value0 instanceof ExpressRpc_Types.Unauthorized) {
                    return Control_Apply.applySecond(((dictMonadNavM.Monad0()).Bind1()).Apply0())(unauthD)(Control_Plus.empty(dictPlus));
                };
                return errorDialog;
            };
            throw new Error("Failed pattern match at ReactNavigation.AuthFlow (line 346, column 23 - line 361, column 25): " + [ e.constructor.name ]);
        };
    };
};
var navPushA = function (dictHasRoute) {
    return function (dictMonadReader) {
        return function (dictMonadNavM) {
            return function (dictPlus) {
                return function (dictRowToList) {
                    return function (dictProcessInfosRL) {
                        return function (r) {
                            return function (i) {
                                return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadReader.MonadAsk0()))(function (v) {
                                    return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(ReactNavigation_Class_MonadNavM.navPushI(dictHasRoute)(dictMonadNavM)(r)(processInfosRL(dictProcessInfosRL)(v.authToken)(Type_Data_RowList.RLProxy.value)(i)))(processClientErr$prime(dictMonadNavM)(dictPlus));
                                });
                            };
                        };
                    };
                };
            };
        };
    };
};
var navPushA$prime = function (dictHasRoute) {
    return function (dictMonadReader) {
        return function (dictMonadNavM) {
            return function (dictPlus) {
                return function (dictRowToList) {
                    return function (dictLacks) {
                        return function (dictProcessInfosRL) {
                            return function (r) {
                                return function (i) {
                                    return function (ir) {
                                        return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadReader.MonadAsk0()))(function (v) {
                                            return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(ReactNavigation_Class_MonadNavM.navPushI(dictHasRoute)(dictMonadNavM)(r)(Record_Builder.build(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                return "info";
                                            }))(Data_Symbol.SProxy.value)(i))(processInfosRL(dictProcessInfosRL)(v.authToken)(Type_Data_RowList.RLProxy.value)(ir))))(function (v1) {
                                                return processClientErr$prime(dictMonadNavM)(dictPlus)(v1);
                                            });
                                        });
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var functorAuthNavM = Control_Monad_Maybe_Trans.functorMaybeT(Control_Monad_Reader_Trans.functorReaderT(ReactNavigation.navmFunctor));
var createAuthFlow = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (dictRunAuthNavM) {
            return function (rs) {
                return function (initialRoute) {
                    return function (initRouteF) {
                        return function (rcB) {
                            return function (onA) {
                                return ReactNavigation["createFlow'"](dictIsSymbol)(dictNavCreator)(rs)(initialRoute)(initRouteF)(rcB)(runAuthNavM(dictRunAuthNavM)(onA));
                            };
                        };
                    };
                };
            };
        };
    };
};
var bindAuthNavM = Control_Monad_Maybe_Trans.bindMaybeT(Control_Monad_Reader_Trans.monadReaderT(ReactNavigation.navmMonad));
var authNavMNewType = new Data_Newtype.Newtype(function (n) {
    return n;
}, AuthNavM);
var monadNMAuthNavM = new ReactNavigation_Class_MonadNavM.MonadNavM(function () {
    return monadAuthNavM;
}, (function () {
    var $130 = Data_Newtype.wrap(authNavMNewType);
    var $131 = Control_Monad_Trans_Class.lift(Control_Monad_Maybe_Trans.monadTransMaybeT)(Control_Monad_Reader_Trans.monadReaderT(ReactNavigation.navmMonad));
    var $132 = Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)(ReactNavigation.navmMonad);
    return function ($133) {
        return $130($131($132($133)));
    };
})());
var runAuthNavMFallBack = new RunAuthNavM(function (onA) {
    return function (args) {
        var authNavM = Data_Newtype.unwrap(authNavMNewType)(onA(args));
        return Control_Monad_Reader_Trans.runReaderT(Control_Monad_Maybe_Trans.runMaybeT(authNavM))(args.info);
    };
});
var runAuthNavMfirstRouteAsync = new RunAuthNavM(function (onA) {
    return function (args) {
        var onA$prime = function (v) {
            return Control_Bind.bind(bindAuthNavM)(processClientErr$prime(monadNMAuthNavM)(plusAuthNavM)(v.value))(function (c) {
                return onA({
                    info: args.info,
                    value: c
                });
            });
        };
        var authNavM = Data_Newtype.unwrap(authNavMNewType)(onA$prime(args));
        return Control_Monad_Reader_Trans.runReaderT(Control_Monad_Maybe_Trans.runMaybeT(authNavM))(args.info);
    };
});
var authCtxNewType = new Data_Newtype.Newtype(function (n) {
    return n;
}, AuthCtx);
var applyAuthRLNil = new ApplyAuthRL(function (v) {
    return function (v1) {
        return function (v2) {
            return {};
        };
    };
});
var applyAuthRL = function (dict) {
    return dict.applyAuthRL;
};
var applyAuthRLCons = function (dictApplyAuthRL) {
    return function (dictIsSymbol) {
        return function (dictCons) {
            return function (dictLacks) {
                return function (dictCons1) {
                    return new ApplyAuthRL(function (authToken) {
                        return function (v) {
                            return function (r) {
                                var rest = applyAuthRL(dictApplyAuthRL)(authToken)(Type_Data_RowList.RLProxy.value)(r);
                                var $$new = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r)(authToken);
                                return Record_Builder.build(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)($$new))(rest);
                            };
                        };
                    });
                };
            };
        };
    };
};
var applyAuthRLConsA = function (dictApplyAuthRL) {
    return function (dictIsSymbol) {
        return function (dictCons) {
            return function (dictLacks) {
                return function (dictCons1) {
                    return new ApplyAuthRL(function (authToken) {
                        return function (v) {
                            return function (r) {
                                var rest = applyAuthRL(dictApplyAuthRL)(authToken)(Type_Data_RowList.RLProxy.value)(r);
                                var $$new = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r);
                                return Record_Builder.build(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)($$new))(rest);
                            };
                        };
                    });
                };
            };
        };
    };
};
var applyAuthRLConsB = function (dictApplyAuthRL) {
    return function (dictIsSymbol) {
        return function (dictCons) {
            return function (dictLacks) {
                return function (dictCons1) {
                    return new ApplyAuthRL(function (authToken) {
                        return function (v) {
                            return function (r) {
                                var rest = applyAuthRL(dictApplyAuthRL)(authToken)(Type_Data_RowList.RLProxy.value)(r);
                                var current = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r);
                                var $$new = current.value0(authToken)(current.value1);
                                return Record_Builder.build(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)($$new))(rest);
                            };
                        };
                    });
                };
            };
        };
    };
};
var processInfosRLConsapiCalls = function (dictProcessInfosRL) {
    return function (dictIsSymbol) {
        return function (dictEquals) {
            return function (dictApplyAuthRL) {
                return function (dictRowToList) {
                    return function (dictCons) {
                        return function (dictCons1) {
                            return function (dictLacks) {
                                return new ProcessInfosRL(function (at) {
                                    return function (v) {
                                        return function (r) {
                                            var rest = processInfosRL(dictProcessInfosRL)(at)(Type_Data_RowList.RLProxy.value)(r);
                                            var current = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r);
                                            var $$new = applyAuthRL(dictApplyAuthRL)(at)(Type_Data_RowList.RLProxy.value)(current);
                                            return Record_Builder.build(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)($$new))(rest);
                                        };
                                    };
                                });
                            };
                        };
                    };
                };
            };
        };
    };
};
var applyAuthR = function (dictRowToList) {
    return function (dictApplyAuthRL) {
        return new ApplyAuth(function (at) {
            return function (r) {
                return applyAuthRL(dictApplyAuthRL)(at)(Type_Data_RowList.RLProxy.value)(r);
            };
        });
    };
};
var applyAuthNavM = Control_Monad_Maybe_Trans.applyMaybeT(Control_Monad_Reader_Trans.monadReaderT(ReactNavigation.navmMonad));
var applyAuth = function (dict) {
    return dict.applyAuth;
};
var applicativeAuthNavM = Control_Monad_Maybe_Trans.applicativeMaybeT(Control_Monad_Reader_Trans.monadReaderT(ReactNavigation.navmMonad));
var altAuthNavM = Control_Monad_Maybe_Trans.altMaybeT(Control_Monad_Reader_Trans.monadReaderT(ReactNavigation.navmMonad));
module.exports = {
    AuthNavM: AuthNavM,
    createAuthFlow: createAuthFlow,
    "navPushA'": navPushA$prime,
    navPushA: navPushA,
    navPushF: navPushF,
    logout: logout,
    InitD: InitD,
    ProcessInfosRL: ProcessInfosRL,
    processInfosRL: processInfosRL,
    processInfos: processInfos,
    ApplyAuth: ApplyAuth,
    applyAuth: applyAuth,
    ApplyAuthRL: ApplyAuthRL,
    applyAuthRL: applyAuthRL,
    RunAuthNavM: RunAuthNavM,
    runAuthNavM: runAuthNavM,
    AuthCtx: AuthCtx,
    "processClientErr'": processClientErr$prime,
    processClientErr: processClientErr,
    authCtxNewType: authCtxNewType,
    authNavMNewType: authNavMNewType,
    functorAuthNavM: functorAuthNavM,
    applyAuthNavM: applyAuthNavM,
    applicativeAuthNavM: applicativeAuthNavM,
    bindAuthNavM: bindAuthNavM,
    monadAuthNavM: monadAuthNavM,
    altAuthNavM: altAuthNavM,
    plusAuthNavM: plusAuthNavM,
    monadAskAuthNavM: monadAskAuthNavM,
    monadReaderAuthNavM: monadReaderAuthNavM,
    monadEffAuthNavM: monadEffAuthNavM,
    monadAffAuthNavM: monadAffAuthNavM,
    monadNMAuthNavM: monadNMAuthNavM,
    runAuthNavMfirstRouteAsync: runAuthNavMfirstRouteAsync,
    runAuthNavMFallBack: runAuthNavMFallBack,
    processInfosRLNil: processInfosRLNil,
    processInfosRLConsInitD: processInfosRLConsInitD,
    "processInfosRLConsInitD'": processInfosRLConsInitD$prime,
    processInfosRLConsapiCalls: processInfosRLConsapiCalls,
    applyAuthR: applyAuthR,
    applyAuthRLNil: applyAuthRLNil,
    applyAuthRLCons: applyAuthRLCons,
    applyAuthRLConsB: applyAuthRLConsB,
    applyAuthRLConsA: applyAuthRLConsA
};
