const Constants = require('expo-constants').default;

exports.importedBuildConfig =
  Constants.manifest.extra && Constants.manifest.extra.buildConfig
    ? Constants.manifest.extra.buildConfig
    : {};

exports.coloredLogo = require('../../assets/colored_logo.png');
exports.whiteLogo = require('../../assets/white_logo.png');

exports.getStarted1 = require('../../assets/onboardingSlides/get_started_1.png');
exports.getStarted2 = require('../../assets/onboardingSlides/get_started_2.png');
exports.getStarted3 = require('../../assets/onboardingSlides/get_started_3.png');
exports.getStarted4 = require('../../assets/onboardingSlides/get_started_4.png');
