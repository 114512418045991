// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_ApiClient_Utils = require("../ZAP.Dart.Client.ApiClient.Utils/index.js");
var ZAP_Dart_Client_Components_NavBar_SimpleNavBar = require("../ZAP.Dart.Client.Components.NavBar.SimpleNavBar/index.js");
var ZAP_Dart_Client_Constants = require("../ZAP.Dart.Client.Constants/index.js");
var ZAP_Dart_Client_Dialogs_Template_Alert = require("../ZAP.Dart.Client.Dialogs.Template.Alert/index.js");
var ZAP_Dart_Client_Dialogs_Template_SingleAction = require("../ZAP.Dart.Client.Dialogs.Template.SingleAction/index.js");
var ZAP_Dart_Client_Flows_ResetPin = require("../ZAP.Dart.Client.Flows.ResetPin/index.js");
var ZAP_Dart_Client_Flows_Utils = require("../ZAP.Dart.Client.Flows.Utils/index.js");
var ZAP_Dart_Client_Screens_CreatePin = require("../ZAP.Dart.Client.Screens.CreatePin/index.js");
var ZAP_Dart_Client_Screens_EnterGuestMobile = require("../ZAP.Dart.Client.Screens.EnterGuestMobile/index.js");
var ZAP_Dart_Client_Screens_EnterMobile = require("../ZAP.Dart.Client.Screens.EnterMobile/index.js");
var ZAP_Dart_Client_Screens_EnterOtpF = require("../ZAP.Dart.Client.Screens.EnterOtpF/index.js");
var ZAP_Dart_Client_Screens_EnterPinF = require("../ZAP.Dart.Client.Screens.EnterPinF/index.js");
var ZAP_Dart_Client_Screens_EnterShortCode = require("../ZAP.Dart.Client.Screens.EnterShortCode/index.js");
var ZAP_Dart_Client_Screens_GettingStarted = require("../ZAP.Dart.Client.Screens.GettingStarted/index.js");
var ZAP_Dart_Client_Screens_GuestClaimReceipt = require("../ZAP.Dart.Client.Screens.GuestClaimReceipt/index.js");
var ZAP_Dart_Client_Screens_PrivacyConsent = require("../ZAP.Dart.Client.Screens.PrivacyConsent/index.js");
var ZAP_Dart_Client_Screens_ScanQr = require("../ZAP.Dart.Client.Screens.ScanQr/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Screens_WebLanding = require("../ZAP.Dart.Client.Screens.WebLanding/index.js");
var ZAP_Dart_Client_Utilities_DeviceInfo = require("../ZAP.Dart.Client.Utilities.DeviceInfo/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var PushAction = (function () {
    function PushAction() {

    };
    PushAction.value = new PushAction();
    return PushAction;
})();
var ReplaceAction = (function () {
    function ReplaceAction() {

    };
    ReplaceAction.value = new ReplaceAction();
    return ReplaceAction;
})();
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "webLanding";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "resetPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "privacyConsent";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "gettingStarted";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "enterPinF";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "createPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "confirmPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "askShortCode";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "askSessionMobile";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "askRegOtp";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "askQrCode";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "askLoginOtp";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "askGuestAction";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "askClaimMobile";
}))));
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_SimpleNavBar.simpleNavBar)(ReactNavigation_Types.NBNone.value);
var flow = (function () {
    var restartDialog = function (v) {
        return Control_Apply.applySecond(ReactNavigation.navmApply)(ReactNavigation_Class_MonadNavM.navDialogI(ReactNavigation_Class_MonadNavM.monadNMNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
            title: v.title,
            message: v.message,
            actionLabel: "Try Again"
        }))(ReactNavigation_Class_MonadNavM.navPopToTop(ReactNavigation_Class_MonadNavM.monadNMNavM));
    };
    var initialFlowCall = function (clientApi) {
        return function (v) {
            return function (releaseH) {
                return ZAP_Dart_Client_ApiClient_Utils.callApi(ReactNavigation_Class_MonadNavM.monadNMNavM)(clientApi.onboardingFlow({
                    currentRef: Data_Maybe.Nothing.value,
                    req: new ZAP_Dart_Common.OInit(v)
                }))(releaseH);
            };
        };
    };
    var mobileInitialize = function (cApi) {
        return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
            return "askSessionMobile";
        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.askSessionMobile))(function (v) {
            return initialFlowCall(cApi)({
                code: Data_Maybe.Nothing.value,
                mobile: new Data_Maybe.Just(v),
                clientOs: new Data_Maybe.Just(ZAP_Dart_Client_Utilities_DeviceInfo.clientOs)
            })(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
        });
    };
    var handleRetry = function (v) {
        return function (afterHook) {
            var title = Data_Maybe.fromMaybe("")(v.title);
            var message = Data_Maybe.fromMaybe("")(v.message);
            var v1 = Data_Maybe.fromMaybe({
                label: "Dismiss",
                effect: ZAP_Dart_Common.RetryActionUnit.value
            })(v.action);
            if (v1.effect instanceof ZAP_Dart_Common.RetryActionUnit) {
                return ReactNavigation_Class_MonadNavM.navDialogIC(ReactNavigation_Class_MonadNavM.monadNMNavM)(ZAP_Dart_Client_Dialogs_Template_SingleAction.singleActionDialog(function (v2) {
                    return function (v3) {
                        return {
                            title: title,
                            message: message,
                            primaryAction: {
                                label: v1.label,
                                onPress: v3.close
                            }
                        };
                    };
                }))(Data_Unit.unit)(afterHook);
            };
            if (v1.effect instanceof ZAP_Dart_Common.RetryActionBack) {
                return Control_Apply.applySecond(ReactNavigation.navmApply)(ReactNavigation_Class_MonadNavM.navDialogIC(ReactNavigation_Class_MonadNavM.monadNMNavM)(ZAP_Dart_Client_Dialogs_Template_SingleAction.singleActionDialog(function (v2) {
                    return function (v3) {
                        return {
                            title: title,
                            message: message,
                            primaryAction: {
                                label: v1.label,
                                onPress: v3.onAction(Data_Unit.unit)
                            }
                        };
                    };
                }))(Data_Unit.unit)(afterHook))(ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_Class_MonadNavM.monadNMNavM));
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 235, column 5 - line 268, column 23): " + [ v1.effect.constructor.name ]);
        };
    };
    var getCode = function (runA) {
        return Control_Bind.bind(ReactNavigation.navmBind)(runA(rs.askShortCode))(function (v) {
            if (v instanceof ZAP_Dart_Client_Screens_Types.SCIMManual) {
                return Control_Applicative.pure(ReactNavigation.navmApplicative)({
                    claimCode: new ZAP_Dart_Common.ShortCode({
                        val: v.value0
                    }),
                    releaseHook: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
                });
            };
            if (v instanceof ZAP_Dart_Client_Screens_Types.SCIMScanQr) {
                return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                    return "askQrCode";
                }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.askQrCode))(function (v1) {
                    if (v1 instanceof ZAP_Dart_Client_Screens_Types.SQVal) {
                        return Control_Applicative.pure(ReactNavigation.navmApplicative)(v1.value0);
                    };
                    if (v1 instanceof ZAP_Dart_Client_Screens_Types.SQGoToEnterShortCodeScreen) {
                        return ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_Class_MonadNavM.monadNMNavM);
                    };
                    if (v1 instanceof ZAP_Dart_Client_Screens_Types.SQClose) {
                        return ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_Class_MonadNavM.monadNMNavM);
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 91, column 19 - line 94, column 39): " + [ v1.constructor.name ]);
                });
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 87, column 11 - line 94, column 39): " + [ v.constructor.name ]);
        });
    };
    var processInstruction = function (clientApi) {
        return function (afterHook) {
            return function (instructionFromServer) {
                if (instructionFromServer instanceof ZAP_Dart_Common.Push) {
                    return handleInstruction(clientApi)(instructionFromServer.value0)(afterHook)(PushAction.value);
                };
                if (instructionFromServer instanceof ZAP_Dart_Common.Replace) {
                    return handleInstruction(clientApi)(instructionFromServer.value0)(afterHook)(ReplaceAction.value);
                };
                if (instructionFromServer instanceof ZAP_Dart_Common.End) {
                    return Control_Applicative.pure(ReactNavigation.navmApplicative)(instructionFromServer.value0.value);
                };
                if (instructionFromServer instanceof ZAP_Dart_Common.Retry) {
                    return handleRetry(instructionFromServer.value0)(afterHook);
                };
                if (instructionFromServer instanceof ZAP_Dart_Common.Restart) {
                    return restartDialog({
                        title: Data_Maybe.fromMaybe("")(instructionFromServer.value0.title),
                        message: Data_Maybe.fromMaybe("")(instructionFromServer.value0.message)
                    });
                };
                if (instructionFromServer instanceof ZAP_Dart_Common.Pop) {
                    return ReactNavigation_Class_MonadNavM.navPop(ReactNavigation_Class_MonadNavM.monadNMNavM)(instructionFromServer.value0.count);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 104, column 66 - line 110, column 34): " + [ instructionFromServer.constructor.name ]);
            };
        };
    };
    var handleInstruction = function (clientApi) {
        return function (v) {
            return function (afterHook) {
                return function (action) {
                    var submit = function (req) {
                        return ZAP_Dart_Client_ApiClient_Utils.callApi(ReactNavigation_Class_MonadNavM.monadNMNavM)(clientApi.onboardingFlow({
                            currentRef: new Data_Maybe.Just(v.currentRef),
                            req: req
                        }));
                    };
                    var submit_ = function (payload) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(submit(payload)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))(processInstruction(clientApi)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                    };
                    var runActionI = function (dictHasRoute) {
                        return function (dictMonadNavM) {
                            if (action instanceof PushAction) {
                                return ReactNavigation_Class_MonadNavM.navPushI(dictHasRoute)(dictMonadNavM);
                            };
                            if (action instanceof ReplaceAction) {
                                return ReactNavigation_Class_MonadNavM.navReplaceI(dictHasRoute)(dictMonadNavM);
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 180, column 18 - line 182, column 35): " + [ action.constructor.name ]);
                        };
                    };
                    var runAction = function (dictHasRoute) {
                        return function (dictMonadNavM) {
                            if (action instanceof PushAction) {
                                return ReactNavigation_Class_MonadNavM.navPush(dictHasRoute)(dictMonadNavM);
                            };
                            if (action instanceof ReplaceAction) {
                                return ReactNavigation_Class_MonadNavM.navReplace(dictHasRoute)(dictMonadNavM);
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 169, column 17 - line 171, column 34): " + [ action.constructor.name ]);
                        };
                    };
                    var genAskOtp = function (dictHasRoute) {
                        return function (route) {
                            return function (v1) {
                                var resendOtp = clientApi.resendOtp({
                                    refId: v1.refId
                                });
                                return Control_Bind.bind(ReactNavigation.navmBind)(runActionI(dictHasRoute)(ReactNavigation_Class_MonadNavM.monadNMNavM)(route)({
                                    mobile: v1.mobile,
                                    error: v1.error,
                                    resendOtp: resendOtp
                                }))(function (v2) {
                                    if (v2 instanceof Data_Either.Right) {
                                        return Control_Bind.bind(ReactNavigation.navmBind)(submit(new ZAP_Dart_Common.OOtp({
                                            refId: v1.refId,
                                            otp: v2.value0
                                        }))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))(processInstruction(clientApi)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                                    };
                                    if (v2 instanceof Data_Either.Left) {
                                        return restartDialog(v2.value0);
                                    };
                                    throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 214, column 7 - line 216, column 34): " + [ v2.constructor.name ]);
                                });
                            };
                        };
                    };
                    var createPin = Control_Bind.bind(ReactNavigation.navmBind)(runAction(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                        return "createPin";
                    }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.createPin))(function (v1) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPushK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "confirmPin";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.confirmPin))(function (v2) {
                            var $77 = v1 === v2.value1;
                            if ($77) {
                                return Control_Applicative.pure(ReactNavigation.navmApplicative)(v1);
                            };
                            return Control_Apply.applySecond(ReactNavigation.navmApply)(ReactNavigation_Class_MonadNavM.navDialogI(ReactNavigation_Class_MonadNavM.monadNMNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
                                title: "Pins don't match",
                                message: "Make sure you enter the same pin",
                                actionLabel: "Try Again"
                            }))(ReactNavigation_Class_MonadNavM.navGoBackToKey(ReactNavigation_Class_MonadNavM.monadNMNavM)(v2.value0));
                        });
                    });
                    if (v.instruction instanceof ZAP_Dart_Common.AskNewPin) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(createPin)(function (v1) {
                            return submit_(new ZAP_Dart_Common.OPin({
                                pin: v1,
                                clientId: ZAP_Dart_Client_Constants.clientId
                            }));
                        });
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskClaimMobile) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(runAction(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "askClaimMobile";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.askClaimMobile))(function (v1) {
                            return submit_(new ZAP_Dart_Common.OMobile({
                                mobile: v1
                            }));
                        });
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskShortCode) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(getCode(runAction(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "askShortCode";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)))(function (v1) {
                            return Control_Bind.bind(ReactNavigation.navmBind)(submit(new ZAP_Dart_Common.OCode({
                                claimCode: v1.claimCode,
                                clientOs: new Data_Maybe.Just(ZAP_Dart_Client_Utilities_DeviceInfo.clientOs)
                            }))(v1.releaseHook))(processInstruction(clientApi)(v1.releaseHook));
                        });
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskSessionMobile) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(runAction(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "askSessionMobile";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.askSessionMobile))(function (v1) {
                            return submit_(new ZAP_Dart_Common.OMobile({
                                mobile: v1
                            }));
                        });
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskPrivacyConsent) {
                        return Control_Apply.applySecond(ReactNavigation.navmApply)(runAction(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "privacyConsent";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.privacyConsent))(submit_(new ZAP_Dart_Common.OPrivacyConsent({
                            accepted: true
                        })));
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskGuestAction) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(runActionI(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "askGuestAction";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.askGuestAction)({
                            claims: Data_Maybe.fromMaybe([  ])(v.instruction.value0.claims),
                            actions: Data_Maybe.fromMaybe([  ])(v.instruction.value0.actions)
                        }))(function (v1) {
                            return submit_(new ZAP_Dart_Common.OGuestAction({
                                guestAction: v1
                            }));
                        });
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskLoginOtp) {
                        return genAskOtp(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "askLoginOtp";
                        }))())(rs.askLoginOtp)(v.instruction.value0);
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskRegOtp) {
                        return genAskOtp(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "askRegOtp";
                        }))())(rs.askRegOtp)(v.instruction.value0);
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskPin) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(runActionI(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "enterPinF";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.enterPinF)({
                            error: v.instruction.value0.error
                        }))(function (v1) {
                            if (v1 instanceof ZAP_Dart_Client_Screens_Types.EPaResetPin) {
                                if (v.instruction.value0.mobile instanceof Data_Maybe.Just && v.instruction.value0.zapperId instanceof Data_Maybe.Just) {
                                    return Control_Bind.bind(ReactNavigation.navmBind)(ZAP_Dart_Client_ApiClient_Utils.callApi_(ReactNavigation_Class_MonadNavM.monadNMNavM)(ReactNavigation.navmMonad)(clientApi.resetPinSendOtp({
                                        zapperId: v.instruction.value0.zapperId.value0
                                    })))(function (v2) {
                                        if (v2 instanceof Data_Either.Right) {
                                            return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPushIK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                                                return "resetPin";
                                            }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.resetPin)({
                                                refId: v2.value0.refId,
                                                mobileNumber: v.instruction.value0.mobile.value0,
                                                clientApi: clientApi
                                            }))(function (v3) {
                                                return ReactNavigation_Class_MonadNavM.navGoBackToKey(ReactNavigation_Class_MonadNavM.monadNMNavM)(v3.value0);
                                            });
                                        };
                                        if (v2 instanceof Data_Either.Left) {
                                            return ReactNavigation_Class_MonadNavM.navErrDialog({
                                                title: "Cannot Reset Pin",
                                                message: "Unable to reset pin."
                                            });
                                        };
                                        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 143, column 13 - line 147, column 100): " + [ v2.constructor.name ]);
                                    });
                                };
                                return ReactNavigation_Class_MonadNavM.navErrDialog({
                                    title: "Cannot Reset Pin",
                                    message: "Unable to reset pin."
                                });
                            };
                            if (v1 instanceof ZAP_Dart_Client_Screens_Types.EPaPin) {
                                return submit_(new ZAP_Dart_Common.OPin({
                                    pin: v1.value0,
                                    clientId: ZAP_Dart_Client_Constants.clientId
                                }));
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 139, column 7 - line 149, column 55): " + [ v1.constructor.name ]);
                        });
                    };
                    if (v.instruction instanceof ZAP_Dart_Common.AskQrCode) {
                        return Control_Bind.bind(ReactNavigation.navmBind)(Control_Bind.bind(ReactNavigation.navmBind)(runAction(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "askQrCode";
                        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.askQrCode))(function (v1) {
                            if (v1 instanceof ZAP_Dart_Client_Screens_Types.SQVal) {
                                return Control_Applicative.pure(ReactNavigation.navmApplicative)(v1.value0);
                            };
                            if (v1 instanceof ZAP_Dart_Client_Screens_Types.SQGoToEnterShortCodeScreen) {
                                return Control_Bind.bind(ReactNavigation.navmBind)(runAction(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                                    return "askShortCode";
                                }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.askShortCode))(function (v2) {
                                    if (v2 instanceof ZAP_Dart_Client_Screens_Types.SCIMManual) {
                                        return Control_Applicative.pure(ReactNavigation.navmApplicative)({
                                            claimCode: new ZAP_Dart_Common.ShortCode({
                                                val: v2.value0
                                            }),
                                            releaseHook: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
                                        });
                                    };
                                    if (v2 instanceof ZAP_Dart_Client_Screens_Types.SCIMScanQr) {
                                        return ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_Class_MonadNavM.monadNMNavM);
                                    };
                                    throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 157, column 23 - line 159, column 46): " + [ v2.constructor.name ]);
                                });
                            };
                            if (v1 instanceof ZAP_Dart_Client_Screens_Types.SQClose) {
                                return ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_Class_MonadNavM.monadNMNavM);
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 153, column 15 - line 160, column 35): " + [ v1.constructor.name ]);
                        }))(function (v1) {
                            return Control_Bind.bind(ReactNavigation.navmBind)(submit(new ZAP_Dart_Common.OCode({
                                claimCode: v1.claimCode,
                                clientOs: new Data_Maybe.Just(ZAP_Dart_Client_Utilities_DeviceInfo.clientOs)
                            }))(v1.releaseHook))(processInstruction(clientApi)(v1.releaseHook));
                        });
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 118, column 78 - line 161, column 117): " + [ v.instruction.constructor.name ]);
                };
            };
        };
    };
    var mobileFlowProcessor = function (v) {
        return Control_Bind.bind(ReactNavigation.navmBind)(mobileInitialize(v.info))(processInstruction(v.info)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
    };
    var webFlowProcessor = function (v) {
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.WebLandingInputCCode) {
            return Control_Bind.bind(ReactNavigation.navmBind)(getCode(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "askShortCode";
            }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)))(function (v1) {
                return Control_Bind.bind(ReactNavigation.navmBind)(initialFlowCall(v.info)({
                    code: new Data_Maybe.Just(v1.claimCode),
                    clientOs: new Data_Maybe.Just(ZAP_Dart_Client_Utilities_DeviceInfo.clientOs),
                    mobile: Data_Maybe.Nothing.value
                })(v1.releaseHook))(processInstruction(v.info)(v1.releaseHook));
            });
        };
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.WebLandingSignIn) {
            return mobileFlowProcessor({
                info: v.info,
                value: Data_Unit.unit
            });
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.OnboardingCF (line 78, column 44 - line 82, column 72): " + [ v.value.constructor.name ]);
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable.notNull(navBar)
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    return ReactNavigation["createFlow'"](new Data_Symbol.IsSymbol(function () {
        return "gettingStarted";
    }))(ReactNavigation.navCreatorStack)(rs)(rs.gettingStarted)(Data_Function["const"](Data_Unit.unit))(configBuilder)(mobileFlowProcessor);
})();
var onboardingScreen = (function () {
    var webLanding = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable["null"]
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_WebLanding.webLanding)
    });
    var resetPin = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("LOG IN"))
        }),
        screen: ZAP_Dart_Client_Flows_ResetPin.resetPinScreen
    });
    var privacyConsent = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("PRIVACY CONSENT"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_PrivacyConsent.privacyConsent)
    });
    var gettingStarted = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable["null"]
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_GettingStarted.gettingStarted)
    });
    var enterPinF = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("LOG IN"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_EnterPinF.enterPinF)
    });
    var createPin = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("SIGN UP"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_CreatePin.createPin)
    });
    var confirmPin = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("SIGN UP"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_CreatePin.confirmPin)
    });
    var askSessionMobile = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("ENTER MOBILE NUMBER"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_EnterMobile.enterMobile)
    });
    var askRegOtp = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("SIGN UP"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_EnterOtpF.enterOtpF)
    });
    var askQrCode = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable["null"]
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_ScanQr.scanQr)
    });
    var askLoginOtp = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("LOG IN"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_EnterOtpF.enterOtpF)
    });
    var askGuestAction = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable["null"]
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_GuestClaimReceipt.guestClaimReceipt)
    });
    return ReactNavigation.createFlowScreen()(flow)({
        gettingStarted: gettingStarted,
        askSessionMobile: askSessionMobile,
        privacyConsent: privacyConsent,
        createPin: createPin,
        confirmPin: confirmPin,
        resetPin: resetPin,
        askRegOtp: askRegOtp,
        askLoginOtp: askLoginOtp,
        enterPinF: enterPinF,
        webLanding: webLanding,
        askQrCode: askQrCode,
        askGuestAction: askGuestAction,
        askClaimMobile: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_EnterGuestMobile.enterGuestMobile)),
        askShortCode: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_EnterShortCode.enterShortCode))
    });
})();
module.exports = {
    onboardingScreen: onboardingScreen
};
