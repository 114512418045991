// Generated by purs version 0.13.5
"use strict";
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var CoercibleRecordRL = {};
var Coercible = {};
var coercibleRecordRLNil = CoercibleRecordRL;
var coercibleRecordRLConsOptional = function (dictCoercibleRecordRL) {
    return function (dictCoercible) {
        return CoercibleRecordRL;
    };
};
var coercibleRecordRLConsDirect = function (dictCoercibleRecordRL) {
    return CoercibleRecordRL;
};
var coercibleRecordRLConsCoercible = function (dictCoercibleRecordRL) {
    return function (dictCoercible) {
        return CoercibleRecordRL;
    };
};
var coercibleRecord = function (dictRowToList) {
    return function (dictRowToList1) {
        return function (dictCoercibleRecordRL) {
            return Coercible;
        };
    };
};
var coercibleIntNumber = Coercible;
var coercibleForeign = Coercible;
var coercibleCharString = Coercible;
var coerce = function (dictCoercible) {
    return Unsafe_Coerce.unsafeCoerce;
};
module.exports = {
    Coercible: Coercible,
    CoercibleRecordRL: CoercibleRecordRL,
    coerce: coerce,
    coercibleIntNumber: coercibleIntNumber,
    coercibleCharString: coercibleCharString,
    coercibleRecord: coercibleRecord,
    coercibleForeign: coercibleForeign,
    coercibleRecordRLNil: coercibleRecordRLNil,
    coercibleRecordRLConsDirect: coercibleRecordRLConsDirect,
    coercibleRecordRLConsCoercible: coercibleRecordRLConsCoercible,
    coercibleRecordRLConsOptional: coercibleRecordRLConsOptional
};
