// Generated by purs version 0.13.5
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var sheet = {
    tileContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderTopWidth(1), React_Basic_Native_Styles.borderBottomWidth(1), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.rgb(227)(227)(227)) ]),
    tilePairContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    tile: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(91), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(50)) ]),
    verticalLine: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRightWidth(1), React_Basic_Native_Styles.borderRightColor(React_Basic_Native_PropTypes_Color.rgb(213)(213)(213)) ])
};
var component = React_Basic.createComponent("Tile");
var tile = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.tileContainer, v.style ]),
            children: v.children
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
var tilePair = function (v) {
    return tile({
        style: React_Basic_Native_Styles["styles'"]([ sheet.tilePairContainer, v.style ]),
        children: [ React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.tile, sheet.verticalLine ]),
            children: v.left
        }), React_Basic_Native_Generated.view()({
            style: sheet.tile,
            children: v.right
        }) ]
    });
};
module.exports = {
    tile: tile,
    tilePair: tilePair
};
