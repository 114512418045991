// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var DimEvent = function (x) {
    return x;
};
var removeEventListener = function (eventType) {
    return function (handler) {
        return function () {
            return $foreign["_removeEventListener"](eventType, Effect_Uncurried.mkEffectFn1(handler));
        };
    };
};
var getWindow = $foreign.get("window");
var getScreen = $foreign.get("screen");
var change = "change";
var addEventListener = function (eventType) {
    return function (handler) {
        return function () {
            return $foreign["_addEventListener"](eventType, Effect_Uncurried.mkEffectFn1(handler));
        };
    };
};
module.exports = {
    addEventListener: addEventListener,
    removeEventListener: removeEventListener,
    getWindow: getWindow,
    getScreen: getScreen,
    change: change
};
