// Generated by purs version 0.13.5
"use strict";
var words = "words";
var sentences = "sentences";
var phonePad = "phone-pad";
var numeric = "numeric";
var numberPad = "number-pad";
var none = "none";
var emailAddress = "email-address";
var $$default = "default";
var decimalPad = "decimal-pad";
var characters = "characters";
module.exports = {
    numberPad: numberPad,
    "default": $$default,
    decimalPad: decimalPad,
    numeric: numeric,
    emailAddress: emailAddress,
    phonePad: phonePad,
    none: none,
    characters: characters,
    words: words,
    sentences: sentences
};
