// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_EmptyPage = require("../ZAP.Dart.Client.Components.EmptyPage/index.js");
var ZAP_Dart_Client_Components_FullPageSpinner = require("../ZAP.Dart.Client.Components.FullPageSpinner/index.js");
var ZAP_Dart_Client_Components_InfiniteList = require("../ZAP.Dart.Client.Components.InfiniteList/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Utilities_DateUtils = require("../ZAP.Dart.Client.Utilities.DateUtils/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    headerText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxHeight(React_Basic_Native_Styles.intMaxHeight)(48), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.flexEnd(React_Basic_Native_Styles_Flex.justifyContentFlexSE)) ]),
    headerComponent: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(30), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(15), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30) ]),
    centerText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    leftText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    rightText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.right), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    pointsText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(5) ]),
    topComponent: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween) ]),
    bottomComponent: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(25) ]),
    txItemComponent: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.minHeight(React_Basic_Native_Styles.intMinHeight)(134), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(15), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.borderBottomWidth(1), React_Basic_Native_Styles.borderBottomColor(ZAP_Dart_Client_Styles_Colors.divider) ]),
    txTopItem: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderTopWidth(1), React_Basic_Native_Styles.borderTopColor(ZAP_Dart_Client_Styles_Colors.divider) ]),
    paddingTop: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(5) ]),
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    listContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ])
};
var component = React_Basic.createComponent("Transactions");
var transactions = function (v) {
    var usedItem = function (str) {
        return ZAP_Dart_Client_Components_Typography.b1({
            style: sheet.pointsText,
            color: ZAP_Dart_Client_Styles_Colors.error,
            value: str
        });
    };
    var showDifference = function (upper) {
        return function (lower) {
            var numDiff = Data_HugeNum.toNumber(Data_Ring.sub(Data_HugeNum.ringHugeNum)(upper)(lower));
            return Data_Number_Format.toString(numDiff);
        };
    };
    var rewardItem = function (str) {
        return ZAP_Dart_Client_Components_Typography.b3({
            style: React_Basic_Native_Styles["styles'"]([ sheet.centerText, sheet.paddingTop ]),
            color: React_Basic_Native_PropTypes_Color.black,
            value: str
        });
    };
    var renderHeader = function (self) {
        return React_Basic_Native_Generated.view()({
            style: sheet.headerComponent,
            children: [ ZAP_Dart_Client_Components_Typography.h3({
                style: sheet.headerText,
                color: React_Basic_Native_PropTypes_Color.black,
                value: "My Transactions"
            }) ]
        });
    };
    var initialState = {
        loading: false
    };
    var fetchTransactionsFn = function (marker) {
        return Data_Functor.mapFlipped(Control_Monad_Except_Trans.functorExceptT(Effect_Aff.functorAff))(v.apiCalls.listTransactions({
            marker: marker
        }))(function (res) {
            return {
                items: res.transactions,
                marker: res.marker
            };
        });
    };
    var earnedItem = function (str) {
        return ZAP_Dart_Client_Components_Typography.b1({
            style: sheet.pointsText,
            color: ZAP_Dart_Client_Styles_Colors.success,
            value: str
        });
    };
    var transactionItem = function (v1) {
        var usedStrings = Data_Array.catMaybes(Data_Functor.mapFlipped(Data_Functor.functorArray)(v1.item.allotments)(function (a) {
            if (a instanceof ZAP_Dart_Common.PointsAllotment && Data_Ord.greaterThan(Data_HugeNum.ordHugeNum)(a.value0.redeemed)(a.value0.earned)) {
                return new Data_Maybe.Just("USED " + (showDifference(a.value0.redeemed)(a.value0.earned) + (" " + Data_String_Common.toUpper(a.value0.currencyName))));
            };
            if (a instanceof ZAP_Dart_Common.CouponAllotment && Data_Ord.greaterThan(Data_HugeNum.ordHugeNum)(a.value0.redeemed)(a.value0.earned)) {
                return new Data_Maybe.Just("USED " + (showDifference(a.value0.redeemed)(a.value0.earned) + " COUPON"));
            };
            return Data_Maybe.Nothing.value;
        }));
        var skuStrings = Data_Functor.mapFlipped(Data_Functor.functorArray)(v1.item.txDetails.skus)(function (sku) {
            var qtyOpt = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(sku.quantity)(function (q) {
                return " x " + Data_Number_Format.toString(Data_HugeNum.toNumber(q));
            });
            var qty = Data_Maybe.fromMaybe("")(qtyOpt);
            var name = Data_Maybe.fromMaybe("SKUs scanned")(sku.name);
            return name + qty;
        });
        var rewardStrings = Data_Functor.mapFlipped(Data_Functor.functorArray)(v1.item.txDetails.rewardItems)(function (reward) {
            var vendorNameOpt = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(reward.vendorName)(function (vn) {
                return vn + " ";
            });
            var vendorName = Data_Maybe.fromMaybe("")(vendorNameOpt);
            var name = Data_Maybe.fromMaybe("Reward purchased")(reward.name);
            return vendorName + name;
        });
        var earnedStrings = Data_Array.catMaybes(Data_Functor.mapFlipped(Data_Functor.functorArray)(v1.item.allotments)(function (a) {
            if (a instanceof ZAP_Dart_Common.PointsAllotment && Data_Ord.greaterThan(Data_HugeNum.ordHugeNum)(a.value0.earned)(a.value0.redeemed)) {
                return new Data_Maybe.Just("EARNED " + (showDifference(a.value0.earned)(a.value0.redeemed) + (" " + Data_String_Common.toUpper(a.value0.currencyName))));
            };
            if (a instanceof ZAP_Dart_Common.CouponAllotment && Data_Ord.greaterThan(Data_HugeNum.ordHugeNum)(a.value0.earned)(a.value0.redeemed)) {
                return new Data_Maybe.Just("EARNED " + (showDifference(a.value0.earned)(a.value0.redeemed) + " COUPON"));
            };
            return Data_Maybe.Nothing.value;
        }));
        return React_Basic_Native_Generated.view()({
            style: (function () {
                var $22 = v1.index === 0;
                if ($22) {
                    return React_Basic_Native_Styles["styles'"]([ sheet.txItemComponent, sheet.txTopItem ]);
                };
                return sheet.txItemComponent;
            })(),
            children: [ React_Basic_Native_Generated.view()({
                style: sheet.topComponent,
                children: [ React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_Typography.b3({
                    style: sheet.leftText,
                    color: React_Basic_Native_PropTypes_Color.black,
                    value: v1.item.refNo
                }), ZAP_Dart_Client_Components_Typography.b3({
                    style: React_Basic_Native_Styles["styles'"]([ sheet.leftText, sheet.paddingTop ]),
                    color: React_Basic_Native_PropTypes_Color.black,
                    value: ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(Effect_Unsafe.unsafePerformEffect(ZAP_Dart_Client_Utilities_DateUtils.adjustTimezone(v1.item.transactionDate))))
                }) ]), ZAP_Dart_Client_Components_Typography.b3({
                    style: sheet.rightText,
                    color: React_Basic_Native_PropTypes_Color.black,
                    value: ZAP_Dart_Client_Screens_Utils.formatTime(Data_DateTime.time(Effect_Unsafe.unsafePerformEffect(ZAP_Dart_Client_Utilities_DateUtils.adjustTimezone(v1.item.transactionDate))))
                }) ]
            }), React_Basic_Native_Generated.view()({
                style: sheet.bottomComponent,
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.mapFlipped(Data_Functor.functorArray)(earnedStrings)(earnedItem))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.mapFlipped(Data_Functor.functorArray)(usedStrings)(usedItem))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.mapFlipped(Data_Functor.functorArray)(skuStrings)(rewardItem))(Data_Functor.mapFlipped(Data_Functor.functorArray)(rewardStrings)(rewardItem))))
            }) ]
        });
    };
    var render = function (v1) {
        return React_Basic.fragment([ ZAP_Dart_Client_Components_FullPageSpinner.fullPageSpinner({
            show: v1.state.loading
        }), React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ ZAP_Dart_Client_Components_InfiniteList.infiniteList(Data_Eq.eqUnit)({
                contentContainerStyle: sheet.listContainer,
                key: "transactions",
                fetchFn: fetchTransactionsFn,
                header: renderHeader(v1),
                renderItem: transactionItem,
                keyExtractor: function (v2) {
                    return v2.item.id;
                },
                numColumns: 1,
                onRefresh: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
                params: Data_Unit.unit,
                runLApi: v1.props.runLApi,
                isFocused: v1.props.focusState.isFocused,
                emptyComponent: ZAP_Dart_Client_Components_EmptyPage.emptyPage({
                    image: ZAP_Dart_Client_Assets.emptyTransactions,
                    message: {
                        primary: "You haven\u2019t made any transactions yet",
                        secondary: "Scan QRs to earn points and browse catalog to redeem points"
                    }
                })
            }) ]
        }) ]);
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
};
module.exports = {
    transactions: transactions
};
