// Generated by purs version 0.13.5
"use strict";
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var WebLandingSignIn = (function () {
    function WebLandingSignIn() {

    };
    WebLandingSignIn.value = new WebLandingSignIn();
    return WebLandingSignIn;
})();
var WebLandingInputCCode = (function () {
    function WebLandingInputCCode() {

    };
    WebLandingInputCCode.value = new WebLandingInputCCode();
    return WebLandingInputCCode;
})();
var SCIMScanQr = (function () {
    function SCIMScanQr() {

    };
    SCIMScanQr.value = new SCIMScanQr();
    return SCIMScanQr;
})();
var SCIMManual = (function () {
    function SCIMManual(value0) {
        this.value0 = value0;
    };
    SCIMManual.create = function (value0) {
        return new SCIMManual(value0);
    };
    return SCIMManual;
})();
var SQGoToEnterShortCodeScreen = (function () {
    function SQGoToEnterShortCodeScreen() {

    };
    SQGoToEnterShortCodeScreen.value = new SQGoToEnterShortCodeScreen();
    return SQGoToEnterShortCodeScreen;
})();
var SQVal = (function () {
    function SQVal(value0) {
        this.value0 = value0;
    };
    SQVal.create = function (value0) {
        return new SQVal(value0);
    };
    return SQVal;
})();
var SQClose = (function () {
    function SQClose() {

    };
    SQClose.value = new SQClose();
    return SQClose;
})();
var GoToRewardItem = (function () {
    function GoToRewardItem(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    GoToRewardItem.create = function (value0) {
        return function (value1) {
            return new GoToRewardItem(value0, value1);
        };
    };
    return GoToRewardItem;
})();
var PRGoBackToCatalog = (function () {
    function PRGoBackToCatalog() {

    };
    PRGoBackToCatalog.value = new PRGoBackToCatalog();
    return PRGoBackToCatalog;
})();
var PRGoToRewardDetails = (function () {
    function PRGoToRewardDetails(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PRGoToRewardDetails.create = function (value0) {
        return function (value1) {
            return new PRGoToRewardDetails(value0, value1);
        };
    };
    return PRGoToRewardDetails;
})();
var EditProfile = (function () {
    function EditProfile() {

    };
    EditProfile.value = new EditProfile();
    return EditProfile;
})();
var ViewTransactions = (function () {
    function ViewTransactions() {

    };
    ViewTransactions.value = new ViewTransactions();
    return ViewTransactions;
})();
var ResetPin = (function () {
    function ResetPin() {

    };
    ResetPin.value = new ResetPin();
    return ResetPin;
})();
var Logout = (function () {
    function Logout() {

    };
    Logout.value = new Logout();
    return Logout;
})();
var AdvancedOpts = (function () {
    function AdvancedOpts() {

    };
    AdvancedOpts.value = new AdvancedOpts();
    return AdvancedOpts;
})();
var PinErrInvalidPin = (function () {
    function PinErrInvalidPin() {

    };
    PinErrInvalidPin.value = new PinErrInvalidPin();
    return PinErrInvalidPin;
})();
var PinErrAccountLocked = (function () {
    function PinErrAccountLocked() {

    };
    PinErrAccountLocked.value = new PinErrAccountLocked();
    return PinErrAccountLocked;
})();
var PinErrOthers = (function () {
    function PinErrOthers() {

    };
    PinErrOthers.value = new PinErrOthers();
    return PinErrOthers;
})();
var ScanQr = (function () {
    function ScanQr() {

    };
    ScanQr.value = new ScanQr();
    return ScanQr;
})();
var ManageCart = (function () {
    function ManageCart() {

    };
    ManageCart.value = new ManageCart();
    return ManageCart;
})();
var PopToTop = (function () {
    function PopToTop() {

    };
    PopToTop.value = new PopToTop();
    return PopToTop;
})();
var EPaPin = (function () {
    function EPaPin(value0) {
        this.value0 = value0;
    };
    EPaPin.create = function (value0) {
        return new EPaPin(value0);
    };
    return EPaPin;
})();
var EPaResetPin = (function () {
    function EPaResetPin() {

    };
    EPaResetPin.value = new EPaResetPin();
    return EPaResetPin;
})();
var EPAReturnVerifiedPin = (function () {
    function EPAReturnVerifiedPin(value0) {
        this.value0 = value0;
    };
    EPAReturnVerifiedPin.create = function (value0) {
        return new EPAReturnVerifiedPin(value0);
    };
    return EPAReturnVerifiedPin;
})();
var EPAResetPin = (function () {
    function EPAResetPin() {

    };
    EPAResetPin.value = new EPAResetPin();
    return EPAResetPin;
})();
var CompleteProfileSuccess = (function () {
    function CompleteProfileSuccess() {

    };
    CompleteProfileSuccess.value = new CompleteProfileSuccess();
    return CompleteProfileSuccess;
})();
var CompleteProfileMaybeLater = (function () {
    function CompleteProfileMaybeLater() {

    };
    CompleteProfileMaybeLater.value = new CompleteProfileMaybeLater();
    return CompleteProfileMaybeLater;
})();
var CRCompleteProfile = (function () {
    function CRCompleteProfile() {

    };
    CRCompleteProfile.value = new CRCompleteProfile();
    return CRCompleteProfile;
})();
var CRMaybeLater = (function () {
    function CRMaybeLater() {

    };
    CRMaybeLater.value = new CRMaybeLater();
    return CRMaybeLater;
})();
var CRDone = (function () {
    function CRDone() {

    };
    CRDone.value = new CRDone();
    return CRDone;
})();
var GoToCatalogItem = (function () {
    function GoToCatalogItem(value0) {
        this.value0 = value0;
    };
    GoToCatalogItem.create = function (value0) {
        return new GoToCatalogItem(value0);
    };
    return GoToCatalogItem;
})();
var GoToCart = (function () {
    function GoToCart() {

    };
    GoToCart.value = new GoToCart();
    return GoToCart;
})();
var BAScanQr = (function () {
    function BAScanQr() {

    };
    BAScanQr.value = new BAScanQr();
    return BAScanQr;
})();
var BAManageCart = (function () {
    function BAManageCart() {

    };
    BAManageCart.value = new BAManageCart();
    return BAManageCart;
})();
var BACatalog = (function () {
    function BACatalog() {

    };
    BACatalog.value = new BACatalog();
    return BACatalog;
})();
var BAPopToTop = (function () {
    function BAPopToTop() {

    };
    BAPopToTop.value = new BAPopToTop();
    return BAPopToTop;
})();
var genericMainAction = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ScanQr) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof ManageCart) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof PopToTop) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.Types (line 136, column 1 - line 136, column 60): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return ScanQr.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return ManageCart.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return PopToTop.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.Types (line 136, column 1 - line 136, column 60): " + [ x.constructor.name ]);
});
var showMainAction = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericMainAction)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ScanQr";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ManageCart";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "PopToTop";
}))))));
module.exports = {
    EPaPin: EPaPin,
    EPaResetPin: EPaResetPin,
    PinErrInvalidPin: PinErrInvalidPin,
    PinErrAccountLocked: PinErrAccountLocked,
    PinErrOthers: PinErrOthers,
    SQGoToEnterShortCodeScreen: SQGoToEnterShortCodeScreen,
    SQVal: SQVal,
    SQClose: SQClose,
    EPAReturnVerifiedPin: EPAReturnVerifiedPin,
    EPAResetPin: EPAResetPin,
    GoToCatalogItem: GoToCatalogItem,
    GoToCart: GoToCart,
    GoToRewardItem: GoToRewardItem,
    EditProfile: EditProfile,
    ViewTransactions: ViewTransactions,
    ResetPin: ResetPin,
    Logout: Logout,
    AdvancedOpts: AdvancedOpts,
    CompleteProfileSuccess: CompleteProfileSuccess,
    CompleteProfileMaybeLater: CompleteProfileMaybeLater,
    CRCompleteProfile: CRCompleteProfile,
    CRMaybeLater: CRMaybeLater,
    CRDone: CRDone,
    ScanQr: ScanQr,
    ManageCart: ManageCart,
    PopToTop: PopToTop,
    BAScanQr: BAScanQr,
    BAManageCart: BAManageCart,
    BACatalog: BACatalog,
    BAPopToTop: BAPopToTop,
    WebLandingSignIn: WebLandingSignIn,
    WebLandingInputCCode: WebLandingInputCCode,
    SCIMScanQr: SCIMScanQr,
    SCIMManual: SCIMManual,
    PRGoBackToCatalog: PRGoBackToCatalog,
    PRGoToRewardDetails: PRGoToRewardDetails,
    genericMainAction: genericMainAction,
    showMainAction: showMainAction
};
