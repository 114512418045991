// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_Components_NavBar_SimpleNavBar = require("../ZAP.Dart.Client.Components.NavBar.SimpleNavBar/index.js");
var ZAP_Dart_Client_Flows_CartMgt = require("../ZAP.Dart.Client.Flows.CartMgt/index.js");
var ZAP_Dart_Client_Flows_Home = require("../ZAP.Dart.Client.Flows.Home/index.js");
var ZAP_Dart_Client_Flows_ScanQr = require("../ZAP.Dart.Client.Flows.ScanQr/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "scanQr";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "home";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "cartMgt";
}))));
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_SimpleNavBar.simpleNavBar)(ReactNavigation_Types.NBNone.value);
var flow = (function () {
    var endFlow = Control_Applicative.pure(ReactNavigation.navmApplicative)(Data_Unit.unit);
    var processMa = function (authCtx) {
        return function (v) {
            if (v.value1 instanceof Data_Maybe.Just) {
                if (v.value1.value0 instanceof ZAP_Dart_Client_Screens_Types.ManageCart) {
                    return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPushIK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                        return "cartMgt";
                    }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.cartMgt)(authCtx))(processMa(authCtx));
                };
                if (v.value1.value0 instanceof ZAP_Dart_Client_Screens_Types.ScanQr) {
                    return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPushIK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                        return "scanQr";
                    }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.scanQr)(authCtx))(processMa(authCtx));
                };
                if (v.value1.value0 instanceof ZAP_Dart_Client_Screens_Types.PopToTop) {
                    return ReactNavigation_Class_MonadNavM.navGoBackToKey(ReactNavigation_Class_MonadNavM.monadNMNavM)(v.value0);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Main (line 60, column 16 - line 65, column 37): " + [ v.value1.value0.constructor.name ]);
            };
            if (v.value1 instanceof Data_Maybe.Nothing) {
                return endFlow;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Main (line 59, column 39 - line 66, column 23): " + [ v.value1.constructor.name ]);
        };
    };
    var processMaForHome = function (authCtx) {
        return function (ma$prime) {
            if (ma$prime instanceof Data_Maybe.Just) {
                if (ma$prime.value0 instanceof ZAP_Dart_Client_Screens_Types.ManageCart) {
                    return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPushIK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                        return "cartMgt";
                    }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.cartMgt)(authCtx))(processMa(authCtx));
                };
                if (ma$prime.value0 instanceof ZAP_Dart_Client_Screens_Types.ScanQr) {
                    return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navPushIK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                        return "scanQr";
                    }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.scanQr)(authCtx))(processMa(authCtx));
                };
                if (ma$prime.value0 instanceof ZAP_Dart_Client_Screens_Types.PopToTop) {
                    return ReactNavigation_Class_MonadNavM.navPopToTop(ReactNavigation_Class_MonadNavM.monadNMNavM);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Main (line 50, column 16 - line 55, column 30): " + [ ma$prime.value0.constructor.name ]);
            };
            if (ma$prime instanceof Data_Maybe.Nothing) {
                return endFlow;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Main (line 49, column 34 - line 56, column 23): " + [ ma$prime.constructor.name ]);
        };
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable["null"]
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var afterInitF = function (v) {
        return processMaForHome(v.info)(v.value);
    };
    return ReactNavigation["createFlow'"](new Data_Symbol.IsSymbol(function () {
        return "home";
    }))(ReactNavigation.navCreatorStack)(rs)(rs.home)(Control_Category.identity(Control_Category.categoryFn))(configBuilder)(afterInitF);
})();
var mainScreen = ReactNavigation.createFlowScreen()(flow)({
    home: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_Home.homeScreen),
    scanQr: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_ScanQr.scanQrScreen),
    cartMgt: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_CartMgt.cartMgtScreen)
});
module.exports = {
    mainScreen: mainScreen
};
