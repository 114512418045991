// Generated by purs version 0.13.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Show = require("../Data.Show/index.js");
var ReactNative_Platform = require("../ReactNative.Platform/index.js");
var maxScreenWidth = 750;
var defaultAvatarUrl = "https://dummyimage.com/100";
var clientId = "dart-" + Data_Show.show(ReactNative_Platform.showPlatform)(ReactNative_Platform.os);
module.exports = {
    defaultAvatarUrl: defaultAvatarUrl,
    clientId: clientId,
    maxScreenWidth: maxScreenWidth,
    preprodApiUrl: $foreign.preprodApiUrl,
    apiUrl: $foreign.apiUrl,
    aboutUrl: $foreign.aboutUrl,
    version: $foreign.version
};
