// Generated by purs version 0.13.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_TextInput = require("../ReactNative.TextInput/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_Divider = require("../ZAP.Dart.Client.Components.Divider/index.js");
var ZAP_Dart_Client_Components_EmptyPage = require("../ZAP.Dart.Client.Components.EmptyPage/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_InfiniteList = require("../ZAP.Dart.Client.Components.InfiniteList/index.js");
var ZAP_Dart_Client_Components_RewardItem = require("../ZAP.Dart.Client.Components.RewardItem/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var unfocusedColor = React_Basic_Native_PropTypes_Color.rgb(191)(191)(191);
var rewardHeaderComponent = React_Basic.createComponent("RewardHeader");
var focusedColor = React_Basic_Native_PropTypes_Color.rgb(90)(100)(101);
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.altBg) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(30), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(25) ]),
    listContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(25), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(40) ]),
    topHeaderContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(5) ]),
    label: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(24), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(1), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold) ]),
    visiblityToggle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    visiblityText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(0), React_Basic_Native_Styles.paddingLeft(React_Basic_Native_Styles.intPaddingLeft)(4) ]),
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(15) ]),
    searchContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderWidth(1), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.rgb(213)(213)(213)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(2), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(5), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(5), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ]),
    focusedSearchContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderColor(focusedColor) ]),
    searchIcon: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(4) ]),
    searchInput: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.fontSize(14), React_Basic_Native_Styles_Text.color(focusedColor), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.7) ]),
    clearIcon: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(16), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(16), React_Basic_Native_Styles.borderRadius(8), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgb(99)(100)(101)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    rewardItemContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(12), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(12), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(15), React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(5), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(2.0), React_Basic_Native_Styles.shadowOffset({
        height: 2.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(4) ]),
    clearFiltersButtonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(390), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30) ])
};
var rewardHeader = (function () {
    var toggleExpiredButton = function (props) {
        var iconName = (function () {
            if (props.includeExpired) {
                return "eye_show";
            };
            return "eye_hidden";
        })();
        return React_Basic_Native_Generated.touchableOpacity()({
            style: sheet.visiblityToggle,
            onPress: React_Basic_Events.handler_(props.onToggleExpired),
            children: [ ZAP_Dart_Client_Components_Icon.icon({
                name: iconName,
                size: 30,
                color: ZAP_Dart_Client_Styles_Colors.primary
            }), ZAP_Dart_Client_Components_Typography.b3({
                style: sheet.visiblityText,
                color: ZAP_Dart_Client_Styles_Colors.primary,
                value: "expired rewards"
            }) ]
        });
    };
    var searchRewards = function (props) {
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.container, sheet.searchContainer, (function () {
                if (props.isInputFocused) {
                    return sheet.focusedSearchContainer;
                };
                if (!props.isInputFocused) {
                    return ZAP_Dart_Client_Styles_Utils.noStyle;
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardList (line 313, column 13 - line 315, column 31): " + [ props.isInputFocused.constructor.name ]);
            })() ]),
            children: [ React_Basic_Native_Generated.view()({
                style: sheet.searchIcon,
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Icon.icon({
                    name: "search",
                    size: 25,
                    color: (function () {
                        if (props.isInputFocused) {
                            return focusedColor;
                        };
                        if (!props.isInputFocused) {
                            return unfocusedColor;
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardList (line 326, column 23 - line 328, column 48): " + [ props.isInputFocused.constructor.name ]);
                    })()
                }))
            }), React_Basic_Native_Generated.textInput()({
                style: sheet.searchInput,
                placeholderTextColor: ZAP_Dart_Client_Styles_Utils.fromColor(unfocusedColor),
                placeholder: "Search",
                value: props.query,
                onChangeText: Effect_Uncurried.mkEffectFn1(props.onQueryChange),
                autoCapitalize: ReactNative_TextInput.none,
                spellCheck: false,
                autoCorrect: false,
                onFocus: React_Basic_Events.handler_(props.setInputFocus(true)),
                onBlur: React_Basic_Events.handler_(props.setInputFocus(false))
            }), Data_Monoid.guard(React_Basic.monoidJSX)(props.isInputFocused || Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(props.query))(React_Basic_Native_Generated.touchableOpacity()({
                style: sheet.searchIcon,
                onPress: React_Basic_Events.handler_(props.onQueryChange("")),
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.view()({
                    style: sheet.clearIcon,
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Icon.icon({
                        name: "exit_outlined",
                        size: 12,
                        color: ZAP_Dart_Client_Styles_Colors.bg
                    }))
                }))
            })) ]
        });
    };
    var render = function (props) {
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.container, sheet.headerContainer ]),
            children: [ React_Basic_Native_Generated.view()({
                style: sheet.topHeaderContainer,
                children: [ React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_Typography.h4({
                    style: sheet.label,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: "My Rewards"
                }) ]), toggleExpiredButton(props) ]
            }), React_Basic_Native_Generated.view()({
                style: sheet.divider,
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Divider.divider_)
            }), searchRewards(props) ]
        });
    };
    return React_Basic.makeStateless(rewardHeaderComponent)(render);
})();
var component = React_Basic.createComponent("RewardList");
var rewardList = function (v) {
    var willUnmount = function (self) {
        return ZAP_Dart_Client_Screens_Utils.removeClock(self.state.intervalId);
    };
    var initialState = {
        rewardCount: 0,
        includeExpired: true,
        query: "",
        now: Data_Maybe.Nothing.value,
        intervalId: Data_Maybe.Nothing.value,
        isInputFocused: false,
        showEmptyUi: false
    };
    var handleToggleExpired = function (self) {
        return self.setState(function (s) {
            return {
                includeExpired: !s.includeExpired,
                showEmptyUi: false,
                intervalId: s.intervalId,
                isInputFocused: s.isInputFocused,
                now: s.now,
                query: s.query,
                rewardCount: s.rewardCount
            };
        });
    };
    var handleQueryChange = function (self) {
        return function (query) {
            return self.setState(function (v1) {
                return {
                    query: query,
                    showEmptyUi: false,
                    includeExpired: v1.includeExpired,
                    intervalId: v1.intervalId,
                    isInputFocused: v1.isInputFocused,
                    now: v1.now,
                    rewardCount: v1.rewardCount
                };
            });
        };
    };
    var handleInputFocus = function (self) {
        return function (isFocused) {
            return self.setState(function (v1) {
                return {
                    isInputFocused: isFocused,
                    includeExpired: v1.includeExpired,
                    intervalId: v1.intervalId,
                    now: v1.now,
                    query: v1.query,
                    rewardCount: v1.rewardCount,
                    showEmptyUi: v1.showEmptyUi
                };
            });
        };
    };
    var fetchRewardsFn = function (self) {
        return function (v1) {
            return function (m) {
                var mkRewards = function (v2) {
                    return Effect_Class.liftEffect(Control_Monad_Except_Trans.monadEffectExceptT(Effect_Aff.monadEffectAff))(Data_Functor.voidLeft(Effect.functorEffect)(self.setState(function (v3) {
                        return {
                            rewardCount: v2.rewardCount,
                            includeExpired: v3.includeExpired,
                            query: v3.query,
                            now: v3.now,
                            intervalId: v3.intervalId,
                            isInputFocused: v3.isInputFocused,
                            showEmptyUi: true
                        };
                    }))({
                        items: v2.items,
                        marker: v2.marker
                    }));
                };
                return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(v.apiCalls.getRewardCount)(function (v2) {
                    if (v2 === 0) {
                        return mkRewards({
                            rewardCount: v2,
                            items: [  ],
                            marker: Data_Maybe.Nothing.value
                        });
                    };
                    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(v.apiCalls.getRewards({
                        includeExpired: v1.includeExpired,
                        query: new Data_Maybe.Just(v1.query),
                        marker: m
                    }))(function (v3) {
                        return mkRewards({
                            rewardCount: v2,
                            items: v3.rewards,
                            marker: v3.marker
                        });
                    });
                });
            };
        };
    };
    var emptyGraphic = function (self) {
        return function (isSearching) {
            return function (hasFilter) {
                return React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_EmptyPage.emptyPage({
                    image: ZAP_Dart_Client_Assets.noSearchResults,
                    message: {
                        primary: (function () {
                            if (isSearching) {
                                return "No search results";
                            };
                            if (!isSearching) {
                                return "No rewards to show";
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardList (line 211, column 15 - line 213, column 46): " + [ isSearching.constructor.name ]);
                        })(),
                        secondary: Data_Monoid.guard(Data_Monoid.monoidString)(hasFilter)("Try turning off your filters to see more rewards")
                    }
                }), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(hasFilter)(React_Basic_Native_Generated.view())({
                    style: sheet.clearFiltersButtonContainer,
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Button.primaryButton({
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        size: ZAP_Dart_Client_Components_Button.md,
                        title: "Clear all filters",
                        onPress: self.setState(function (v1) {
                            return {
                                includeExpired: true,
                                showEmptyUi: false,
                                intervalId: v1.intervalId,
                                isInputFocused: v1.isInputFocused,
                                now: v1.now,
                                query: v1.query,
                                rewardCount: v1.rewardCount
                            };
                        }),
                        disabled: false,
                        inverted: false
                    }))
                }) ]);
            };
        };
    };
    var didMount = function (self) {
        return ZAP_Dart_Client_Screens_Utils.addClock(10.0)(self);
    };
    var defaultEmptyGraphic = ZAP_Dart_Client_Components_EmptyPage.emptyPage({
        image: ZAP_Dart_Client_Assets.emptyRewards,
        message: {
            primary: "You haven\u2019t purchased rewards yet",
            secondary: "Browse the catalog and purchase rewards to add to your inventory"
        }
    });
    var render = function (self) {
        var hasFilter = !self.state.includeExpired;
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_InfiniteList.infiniteList(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                return "query";
            }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                return "now";
            }))(Data_Maybe.eqMaybe(Data_DateTime.eqDateTime)))()(new Data_Symbol.IsSymbol(function () {
                return "includeExpired";
            }))(Data_Eq.eqBoolean)))({
                contentContainerStyle: sheet.listContainer,
                key: "rewards",
                fetchFn: fetchRewardsFn(self)({
                    includeExpired: self.state.includeExpired,
                    query: self.state.query
                }),
                header: rewardHeader({
                    includeExpired: self.state.includeExpired,
                    query: self.state.query,
                    isInputFocused: self.state.isInputFocused,
                    onToggleExpired: handleToggleExpired(self),
                    onQueryChange: handleQueryChange(self),
                    setInputFocus: handleInputFocus(self)
                }),
                renderItem: function (v1) {
                    return React_Basic_Native_Generated.view()({
                        style: sheet.rewardItemContainer,
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_RewardItem.rewardItem({
                            now: self.state.now,
                            rewardItem: v1.item,
                            onMoreDetails: self.props.onAction(ReactNavigation_Types.PgSubmit.create(new ZAP_Dart_Client_Screens_Types.GoToRewardItem(v1.item.productId, v1.item.productCodeId)))
                        }))
                    });
                },
                keyExtractor: function (v1) {
                    return Data_Show.show(Data_Show.showInt)(v1.index);
                },
                numColumns: 1,
                onRefresh: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
                params: {
                    includeExpired: self.state.includeExpired,
                    query: self.state.query,
                    now: self.state.now
                },
                runLApi: self.props.runLApi,
                isFocused: self.props.focusState.isFocused,
                emptyComponent: Data_Monoid.guard(React_Basic.monoidJSX)(self.state.showEmptyUi)((function () {
                    if (self.state.rewardCount === 0) {
                        return defaultEmptyGraphic;
                    };
                    return emptyGraphic(self)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(self.state.query))(hasFilter);
                })())
            }))
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        willUnmount: willUnmount,
        render: render
    });
};
module.exports = {
    rewardList: rewardList
};
