// Generated by purs version 0.13.5
"use strict";
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Time = require("../Data.Time/index.js");
var LocalDateTime = function (x) {
    return x;
};
var toUTCDateTime = function (v) {
    return v;
};
var time = Data_DateTime.time;
var showLocalDateTime = new Data_Show.Show(function (v) {
    return "(LocalDateTime " + (Data_Show.show(Data_Date.showDate)(v.value0) + (" " + (Data_Show.show(Data_Time.showTime)(v.value1) + ")")));
});
var modifyTimeF = function (dictFunctor) {
    return Data_DateTime.modifyTimeF(dictFunctor);
};
var modifyTime = Data_DateTime.modifyTime;
var modifyDateF = function (dictFunctor) {
    return Data_DateTime.modifyDateF(dictFunctor);
};
var modifyDate = Data_DateTime.modifyDate;
var localDateTime = function (d) {
    return function (t) {
        return new Data_DateTime.DateTime(d, t);
    };
};
var eqLocalDateTime = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_DateTime.eqDateTime)(x)(y);
    };
});
var ordLocalDateTime = new Data_Ord.Ord(function () {
    return eqLocalDateTime;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_DateTime.ordDateTime)(x)(y);
    };
});
var diff = function (dictDuration) {
    return Data_DateTime.diff(dictDuration);
};
var date = function (v) {
    return Data_DateTime.date(v);
};
var boundedLocalDateTime = new Data_Bounded.Bounded(function () {
    return ordLocalDateTime;
}, Data_Bounded.bottom(Data_DateTime.boundedDateTime), Data_Bounded.top(Data_DateTime.boundedDateTime));
var adjust = function (dictDuration) {
    return Data_DateTime.adjust(dictDuration);
};
module.exports = {
    localDateTime: localDateTime,
    toUTCDateTime: toUTCDateTime,
    date: date,
    modifyDate: modifyDate,
    modifyDateF: modifyDateF,
    time: time,
    modifyTime: modifyTime,
    modifyTimeF: modifyTimeF,
    adjust: adjust,
    diff: diff,
    eqLocalDateTime: eqLocalDateTime,
    ordLocalDateTime: ordLocalDateTime,
    boundedLocalDateTime: boundedLocalDateTime,
    showLocalDateTime: showLocalDateTime
};
