// Generated by purs version 0.13.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ZRPC_Client = require("../ZRPC.Client/index.js");
var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");
var UpdateVendorErrorVendorNotFound = (function () {
    function UpdateVendorErrorVendorNotFound() {

    };
    UpdateVendorErrorVendorNotFound.value = new UpdateVendorErrorVendorNotFound();
    return UpdateVendorErrorVendorNotFound;
})();
var UpdateVendorErrorUploadFailed = (function () {
    function UpdateVendorErrorUploadFailed() {

    };
    UpdateVendorErrorUploadFailed.value = new UpdateVendorErrorUploadFailed();
    return UpdateVendorErrorUploadFailed;
})();
var UpdateVendorErrorAttachmentNotFound = (function () {
    function UpdateVendorErrorAttachmentNotFound() {

    };
    UpdateVendorErrorAttachmentNotFound.value = new UpdateVendorErrorAttachmentNotFound();
    return UpdateVendorErrorAttachmentNotFound;
})();
var UpdateVendorErrorUpdateFailed = (function () {
    function UpdateVendorErrorUpdateFailed() {

    };
    UpdateVendorErrorUpdateFailed.value = new UpdateVendorErrorUpdateFailed();
    return UpdateVendorErrorUpdateFailed;
})();
var UpdateProductErrorProductNotFound = (function () {
    function UpdateProductErrorProductNotFound() {

    };
    UpdateProductErrorProductNotFound.value = new UpdateProductErrorProductNotFound();
    return UpdateProductErrorProductNotFound;
})();
var UpdateProductErrorInvalidVendor = (function () {
    function UpdateProductErrorInvalidVendor() {

    };
    UpdateProductErrorInvalidVendor.value = new UpdateProductErrorInvalidVendor();
    return UpdateProductErrorInvalidVendor;
})();
var UpdateProductErrorInvalidCategory = (function () {
    function UpdateProductErrorInvalidCategory() {

    };
    UpdateProductErrorInvalidCategory.value = new UpdateProductErrorInvalidCategory();
    return UpdateProductErrorInvalidCategory;
})();
var UpdateProductErrorUploadFailed = (function () {
    function UpdateProductErrorUploadFailed() {

    };
    UpdateProductErrorUploadFailed.value = new UpdateProductErrorUploadFailed();
    return UpdateProductErrorUploadFailed;
})();
var UpdateProductErrorAttachmentNotFound = (function () {
    function UpdateProductErrorAttachmentNotFound() {

    };
    UpdateProductErrorAttachmentNotFound.value = new UpdateProductErrorAttachmentNotFound();
    return UpdateProductErrorAttachmentNotFound;
})();
var UpdateProductErrorUpdateFailed = (function () {
    function UpdateProductErrorUpdateFailed() {

    };
    UpdateProductErrorUpdateFailed.value = new UpdateProductErrorUpdateFailed();
    return UpdateProductErrorUpdateFailed;
})();
var UpdateModeSet = (function () {
    function UpdateModeSet(value0) {
        this.value0 = value0;
    };
    UpdateModeSet.create = function (value0) {
        return new UpdateModeSet(value0);
    };
    return UpdateModeSet;
})();
var UpdateModeUnset = (function () {
    function UpdateModeUnset() {

    };
    UpdateModeUnset.value = new UpdateModeUnset();
    return UpdateModeUnset;
})();
var UpdateModeRetain = (function () {
    function UpdateModeRetain() {

    };
    UpdateModeRetain.value = new UpdateModeRetain();
    return UpdateModeRetain;
})();
var UpdateHoldingThemeErrorHoldingNotFound = (function () {
    function UpdateHoldingThemeErrorHoldingNotFound() {

    };
    UpdateHoldingThemeErrorHoldingNotFound.value = new UpdateHoldingThemeErrorHoldingNotFound();
    return UpdateHoldingThemeErrorHoldingNotFound;
})();
var UpdateHoldingThemeErrorUploadFailed = (function () {
    function UpdateHoldingThemeErrorUploadFailed() {

    };
    UpdateHoldingThemeErrorUploadFailed.value = new UpdateHoldingThemeErrorUploadFailed();
    return UpdateHoldingThemeErrorUploadFailed;
})();
var UpdateHoldingThemeErrorAttachmentNotFound = (function () {
    function UpdateHoldingThemeErrorAttachmentNotFound() {

    };
    UpdateHoldingThemeErrorAttachmentNotFound.value = new UpdateHoldingThemeErrorAttachmentNotFound();
    return UpdateHoldingThemeErrorAttachmentNotFound;
})();
var UpdateHoldingThemeErrorUpdateFailed = (function () {
    function UpdateHoldingThemeErrorUpdateFailed() {

    };
    UpdateHoldingThemeErrorUpdateFailed.value = new UpdateHoldingThemeErrorUpdateFailed();
    return UpdateHoldingThemeErrorUpdateFailed;
})();
var UpdateCartErrorInvalidZapperId = (function () {
    function UpdateCartErrorInvalidZapperId() {

    };
    UpdateCartErrorInvalidZapperId.value = new UpdateCartErrorInvalidZapperId();
    return UpdateCartErrorInvalidZapperId;
})();
var UpdateCartErrorProductDoesNotExist = (function () {
    function UpdateCartErrorProductDoesNotExist() {

    };
    UpdateCartErrorProductDoesNotExist.value = new UpdateCartErrorProductDoesNotExist();
    return UpdateCartErrorProductDoesNotExist;
})();
var UpdateCartErrorAllocationNotFound = (function () {
    function UpdateCartErrorAllocationNotFound() {

    };
    UpdateCartErrorAllocationNotFound.value = new UpdateCartErrorAllocationNotFound();
    return UpdateCartErrorAllocationNotFound;
})();
var UpdateCartErrorInsufficientAllocation = (function () {
    function UpdateCartErrorInsufficientAllocation() {

    };
    UpdateCartErrorInsufficientAllocation.value = new UpdateCartErrorInsufficientAllocation();
    return UpdateCartErrorInsufficientAllocation;
})();
var UpdateCartErrorUpdateCartFailed = (function () {
    function UpdateCartErrorUpdateCartFailed() {

    };
    UpdateCartErrorUpdateCartFailed.value = new UpdateCartErrorUpdateCartFailed();
    return UpdateCartErrorUpdateCartFailed;
})();
var ReservationErrorNoProductCodesAvailable = (function () {
    function ReservationErrorNoProductCodesAvailable(value0) {
        this.value0 = value0;
    };
    ReservationErrorNoProductCodesAvailable.create = function (value0) {
        return new ReservationErrorNoProductCodesAvailable(value0);
    };
    return ReservationErrorNoProductCodesAvailable;
})();
var ReservationErrorNoAllocation = (function () {
    function ReservationErrorNoAllocation(value0) {
        this.value0 = value0;
    };
    ReservationErrorNoAllocation.create = function (value0) {
        return new ReservationErrorNoAllocation(value0);
    };
    return ReservationErrorNoAllocation;
})();
var ReservationErrorInsufficientAllocations = (function () {
    function ReservationErrorInsufficientAllocations(value0) {
        this.value0 = value0;
    };
    ReservationErrorInsufficientAllocations.create = function (value0) {
        return new ReservationErrorInsufficientAllocations(value0);
    };
    return ReservationErrorInsufficientAllocations;
})();
var PurchaseStatusProcessing = (function () {
    function PurchaseStatusProcessing() {

    };
    PurchaseStatusProcessing.value = new PurchaseStatusProcessing();
    return PurchaseStatusProcessing;
})();
var PurchaseStatusProcessed = (function () {
    function PurchaseStatusProcessed() {

    };
    PurchaseStatusProcessed.value = new PurchaseStatusProcessed();
    return PurchaseStatusProcessed;
})();
var PurchaseStatusFailed = (function () {
    function PurchaseStatusFailed() {

    };
    PurchaseStatusFailed.value = new PurchaseStatusFailed();
    return PurchaseStatusFailed;
})();
var ProductCodeValidationErrorIncompleteField = (function () {
    function ProductCodeValidationErrorIncompleteField(value0) {
        this.value0 = value0;
    };
    ProductCodeValidationErrorIncompleteField.create = function (value0) {
        return new ProductCodeValidationErrorIncompleteField(value0);
    };
    return ProductCodeValidationErrorIncompleteField;
})();
var ProductCodeValidationErrorInvalidVendorName = (function () {
    function ProductCodeValidationErrorInvalidVendorName(value0) {
        this.value0 = value0;
    };
    ProductCodeValidationErrorInvalidVendorName.create = function (value0) {
        return new ProductCodeValidationErrorInvalidVendorName(value0);
    };
    return ProductCodeValidationErrorInvalidVendorName;
})();
var ProductCodeValidationErrorInvalidProductName = (function () {
    function ProductCodeValidationErrorInvalidProductName(value0) {
        this.value0 = value0;
    };
    ProductCodeValidationErrorInvalidProductName.create = function (value0) {
        return new ProductCodeValidationErrorInvalidProductName(value0);
    };
    return ProductCodeValidationErrorInvalidProductName;
})();
var ProductCodeValidationErrorInvalidProductId = (function () {
    function ProductCodeValidationErrorInvalidProductId(value0) {
        this.value0 = value0;
    };
    ProductCodeValidationErrorInvalidProductId.create = function (value0) {
        return new ProductCodeValidationErrorInvalidProductId(value0);
    };
    return ProductCodeValidationErrorInvalidProductId;
})();
var ProductCodeValidationErrorDuplicateProductCode = (function () {
    function ProductCodeValidationErrorDuplicateProductCode(value0) {
        this.value0 = value0;
    };
    ProductCodeValidationErrorDuplicateProductCode.create = function (value0) {
        return new ProductCodeValidationErrorDuplicateProductCode(value0);
    };
    return ProductCodeValidationErrorDuplicateProductCode;
})();
var ItemValidationErrorItemDoesNotExist = (function () {
    function ItemValidationErrorItemDoesNotExist(value0) {
        this.value0 = value0;
    };
    ItemValidationErrorItemDoesNotExist.create = function (value0) {
        return new ItemValidationErrorItemDoesNotExist(value0);
    };
    return ItemValidationErrorItemDoesNotExist;
})();
var ItemValidationErrorItemNotInCart = (function () {
    function ItemValidationErrorItemNotInCart(value0) {
        this.value0 = value0;
    };
    ItemValidationErrorItemNotInCart.create = function (value0) {
        return new ItemValidationErrorItemNotInCart(value0);
    };
    return ItemValidationErrorItemNotInCart;
})();
var ItemValidationErrorInvalidQuantity = (function () {
    function ItemValidationErrorInvalidQuantity(value0) {
        this.value0 = value0;
    };
    ItemValidationErrorInvalidQuantity.create = function (value0) {
        return new ItemValidationErrorInvalidQuantity(value0);
    };
    return ItemValidationErrorInvalidQuantity;
})();
var HoldingActivityTypeCredit = (function () {
    function HoldingActivityTypeCredit() {

    };
    HoldingActivityTypeCredit.value = new HoldingActivityTypeCredit();
    return HoldingActivityTypeCredit;
})();
var HoldingActivityTypeDebit = (function () {
    function HoldingActivityTypeDebit() {

    };
    HoldingActivityTypeDebit.value = new HoldingActivityTypeDebit();
    return HoldingActivityTypeDebit;
})();
var HoldingActivityTypeAllocation = (function () {
    function HoldingActivityTypeAllocation() {

    };
    HoldingActivityTypeAllocation.value = new HoldingActivityTypeAllocation();
    return HoldingActivityTypeAllocation;
})();
var GetHoldingBalanceErrorHoldingDoesNotExist = (function () {
    function GetHoldingBalanceErrorHoldingDoesNotExist() {

    };
    GetHoldingBalanceErrorHoldingDoesNotExist.value = new GetHoldingBalanceErrorHoldingDoesNotExist();
    return GetHoldingBalanceErrorHoldingDoesNotExist;
})();
var GetCartErrorInvalidZapperId = (function () {
    function GetCartErrorInvalidZapperId() {

    };
    GetCartErrorInvalidZapperId.value = new GetCartErrorInvalidZapperId();
    return GetCartErrorInvalidZapperId;
})();
var DeleteCartItemsErrorInvalidZapperId = (function () {
    function DeleteCartItemsErrorInvalidZapperId() {

    };
    DeleteCartItemsErrorInvalidZapperId.value = new DeleteCartItemsErrorInvalidZapperId();
    return DeleteCartItemsErrorInvalidZapperId;
})();
var DeleteCartItemsErrorCartDoesNotExist = (function () {
    function DeleteCartItemsErrorCartDoesNotExist() {

    };
    DeleteCartItemsErrorCartDoesNotExist.value = new DeleteCartItemsErrorCartDoesNotExist();
    return DeleteCartItemsErrorCartDoesNotExist;
})();
var DeleteCartItemsErrorProductIdNotFound = (function () {
    function DeleteCartItemsErrorProductIdNotFound(value0) {
        this.value0 = value0;
    };
    DeleteCartItemsErrorProductIdNotFound.create = function (value0) {
        return new DeleteCartItemsErrorProductIdNotFound(value0);
    };
    return DeleteCartItemsErrorProductIdNotFound;
})();
var DeleteCartItemsErrorUpdateCartFailed = (function () {
    function DeleteCartItemsErrorUpdateCartFailed() {

    };
    DeleteCartItemsErrorUpdateCartFailed.value = new DeleteCartItemsErrorUpdateCartFailed();
    return DeleteCartItemsErrorUpdateCartFailed;
})();
var DebitHoldingErrorInvalidAmount = (function () {
    function DebitHoldingErrorInvalidAmount() {

    };
    DebitHoldingErrorInvalidAmount.value = new DebitHoldingErrorInvalidAmount();
    return DebitHoldingErrorInvalidAmount;
})();
var DebitHoldingErrorHoldingDoesNotExist = (function () {
    function DebitHoldingErrorHoldingDoesNotExist() {

    };
    DebitHoldingErrorHoldingDoesNotExist.value = new DebitHoldingErrorHoldingDoesNotExist();
    return DebitHoldingErrorHoldingDoesNotExist;
})();
var DebitHoldingErrorInsufficientBalance = (function () {
    function DebitHoldingErrorInsufficientBalance() {

    };
    DebitHoldingErrorInsufficientBalance.value = new DebitHoldingErrorInsufficientBalance();
    return DebitHoldingErrorInsufficientBalance;
})();
var DebitHoldingErrorUpdateHoldingError = (function () {
    function DebitHoldingErrorUpdateHoldingError() {

    };
    DebitHoldingErrorUpdateHoldingError.value = new DebitHoldingErrorUpdateHoldingError();
    return DebitHoldingErrorUpdateHoldingError;
})();
var DateTimeOrderDescending = (function () {
    function DateTimeOrderDescending() {

    };
    DateTimeOrderDescending.value = new DateTimeOrderDescending();
    return DateTimeOrderDescending;
})();
var DateTimeOrderAscending = (function () {
    function DateTimeOrderAscending() {

    };
    DateTimeOrderAscending.value = new DateTimeOrderAscending();
    return DateTimeOrderAscending;
})();
var CreditHoldingErrorInvalidAmount = (function () {
    function CreditHoldingErrorInvalidAmount() {

    };
    CreditHoldingErrorInvalidAmount.value = new CreditHoldingErrorInvalidAmount();
    return CreditHoldingErrorInvalidAmount;
})();
var CreditHoldingErrorHoldingDoesNotExist = (function () {
    function CreditHoldingErrorHoldingDoesNotExist() {

    };
    CreditHoldingErrorHoldingDoesNotExist.value = new CreditHoldingErrorHoldingDoesNotExist();
    return CreditHoldingErrorHoldingDoesNotExist;
})();
var CreditHoldingErrorUpdateHoldingError = (function () {
    function CreditHoldingErrorUpdateHoldingError() {

    };
    CreditHoldingErrorUpdateHoldingError.value = new CreditHoldingErrorUpdateHoldingError();
    return CreditHoldingErrorUpdateHoldingError;
})();
var CreateVendorErrorUploadFailed = (function () {
    function CreateVendorErrorUploadFailed() {

    };
    CreateVendorErrorUploadFailed.value = new CreateVendorErrorUploadFailed();
    return CreateVendorErrorUploadFailed;
})();
var CreateVendorErrorAttachmentNotFound = (function () {
    function CreateVendorErrorAttachmentNotFound() {

    };
    CreateVendorErrorAttachmentNotFound.value = new CreateVendorErrorAttachmentNotFound();
    return CreateVendorErrorAttachmentNotFound;
})();
var CreateProductErrorProductAlreadyExists = (function () {
    function CreateProductErrorProductAlreadyExists() {

    };
    CreateProductErrorProductAlreadyExists.value = new CreateProductErrorProductAlreadyExists();
    return CreateProductErrorProductAlreadyExists;
})();
var CreateProductErrorInvalidVendor = (function () {
    function CreateProductErrorInvalidVendor() {

    };
    CreateProductErrorInvalidVendor.value = new CreateProductErrorInvalidVendor();
    return CreateProductErrorInvalidVendor;
})();
var CreateProductErrorInvalidCategory = (function () {
    function CreateProductErrorInvalidCategory() {

    };
    CreateProductErrorInvalidCategory.value = new CreateProductErrorInvalidCategory();
    return CreateProductErrorInvalidCategory;
})();
var CreateProductErrorUploadFailed = (function () {
    function CreateProductErrorUploadFailed() {

    };
    CreateProductErrorUploadFailed.value = new CreateProductErrorUploadFailed();
    return CreateProductErrorUploadFailed;
})();
var CreateProductErrorAttachmentNotFound = (function () {
    function CreateProductErrorAttachmentNotFound() {

    };
    CreateProductErrorAttachmentNotFound.value = new CreateProductErrorAttachmentNotFound();
    return CreateProductErrorAttachmentNotFound;
})();
var CreateProductErrorInsertFailed = (function () {
    function CreateProductErrorInsertFailed() {

    };
    CreateProductErrorInsertFailed.value = new CreateProductErrorInsertFailed();
    return CreateProductErrorInsertFailed;
})();
var CreateProductCodesErrorInvalidAttachment = (function () {
    function CreateProductCodesErrorInvalidAttachment() {

    };
    CreateProductCodesErrorInvalidAttachment.value = new CreateProductCodesErrorInvalidAttachment();
    return CreateProductCodesErrorInvalidAttachment;
})();
var CreateProductCodesErrorProductNotFound = (function () {
    function CreateProductCodesErrorProductNotFound() {

    };
    CreateProductCodesErrorProductNotFound.value = new CreateProductCodesErrorProductNotFound();
    return CreateProductCodesErrorProductNotFound;
})();
var CreateProductCodesErrorVendorNotFound = (function () {
    function CreateProductCodesErrorVendorNotFound() {

    };
    CreateProductCodesErrorVendorNotFound.value = new CreateProductCodesErrorVendorNotFound();
    return CreateProductCodesErrorVendorNotFound;
})();
var CreateProductCodesErrorInvalidFileFormat = (function () {
    function CreateProductCodesErrorInvalidFileFormat() {

    };
    CreateProductCodesErrorInvalidFileFormat.value = new CreateProductCodesErrorInvalidFileFormat();
    return CreateProductCodesErrorInvalidFileFormat;
})();
var CreateProductCodesErrorNoEntriesFound = (function () {
    function CreateProductCodesErrorNoEntriesFound() {

    };
    CreateProductCodesErrorNoEntriesFound.value = new CreateProductCodesErrorNoEntriesFound();
    return CreateProductCodesErrorNoEntriesFound;
})();
var CreateProductCodesErrorValidationErrors = (function () {
    function CreateProductCodesErrorValidationErrors(value0) {
        this.value0 = value0;
    };
    CreateProductCodesErrorValidationErrors.create = function (value0) {
        return new CreateProductCodesErrorValidationErrors(value0);
    };
    return CreateProductCodesErrorValidationErrors;
})();
var CreateProductCodesErrorUploadFailed = (function () {
    function CreateProductCodesErrorUploadFailed() {

    };
    CreateProductCodesErrorUploadFailed.value = new CreateProductCodesErrorUploadFailed();
    return CreateProductCodesErrorUploadFailed;
})();
var CreateProductCodesErrorCreateProductCodesFailed = (function () {
    function CreateProductCodesErrorCreateProductCodesFailed() {

    };
    CreateProductCodesErrorCreateProductCodesFailed.value = new CreateProductCodesErrorCreateProductCodesFailed();
    return CreateProductCodesErrorCreateProductCodesFailed;
})();
var CreateHoldingErrorHoldingAlreadyExists = (function () {
    function CreateHoldingErrorHoldingAlreadyExists() {

    };
    CreateHoldingErrorHoldingAlreadyExists.value = new CreateHoldingErrorHoldingAlreadyExists();
    return CreateHoldingErrorHoldingAlreadyExists;
})();
var CodeTypeAlphanum = (function () {
    function CodeTypeAlphanum() {

    };
    CodeTypeAlphanum.value = new CodeTypeAlphanum();
    return CodeTypeAlphanum;
})();
var CodeTypeBCode = (function () {
    function CodeTypeBCode() {

    };
    CodeTypeBCode.value = new CodeTypeBCode();
    return CodeTypeBCode;
})();
var CheckoutErrorInvalidProducts = (function () {
    function CheckoutErrorInvalidProducts(value0) {
        this.value0 = value0;
    };
    CheckoutErrorInvalidProducts.create = function (value0) {
        return new CheckoutErrorInvalidProducts(value0);
    };
    return CheckoutErrorInvalidProducts;
})();
var CheckoutErrorCartNotFound = (function () {
    function CheckoutErrorCartNotFound() {

    };
    CheckoutErrorCartNotFound.value = new CheckoutErrorCartNotFound();
    return CheckoutErrorCartNotFound;
})();
var CheckoutErrorReservationFailed = (function () {
    function CheckoutErrorReservationFailed(value0) {
        this.value0 = value0;
    };
    CheckoutErrorReservationFailed.create = function (value0) {
        return new CheckoutErrorReservationFailed(value0);
    };
    return CheckoutErrorReservationFailed;
})();
var CheckoutErrorInvalidTransaction = (function () {
    function CheckoutErrorInvalidTransaction(value0) {
        this.value0 = value0;
    };
    CheckoutErrorInvalidTransaction.create = function (value0) {
        return new CheckoutErrorInvalidTransaction(value0);
    };
    return CheckoutErrorInvalidTransaction;
})();
var CheckoutErrorTransactionAlreadyUsed = (function () {
    function CheckoutErrorTransactionAlreadyUsed(value0) {
        this.value0 = value0;
    };
    CheckoutErrorTransactionAlreadyUsed.create = function (value0) {
        return new CheckoutErrorTransactionAlreadyUsed(value0);
    };
    return CheckoutErrorTransactionAlreadyUsed;
})();
var CheckoutErrorInvalidPurchase = (function () {
    function CheckoutErrorInvalidPurchase(value0) {
        this.value0 = value0;
    };
    CheckoutErrorInvalidPurchase.create = function (value0) {
        return new CheckoutErrorInvalidPurchase(value0);
    };
    return CheckoutErrorInvalidPurchase;
})();
var CheckoutErrorInvalidProductCode = (function () {
    function CheckoutErrorInvalidProductCode() {

    };
    CheckoutErrorInvalidProductCode.value = new CheckoutErrorInvalidProductCode();
    return CheckoutErrorInvalidProductCode;
})();
var CheckoutErrorUpdateCartFailed = (function () {
    function CheckoutErrorUpdateCartFailed() {

    };
    CheckoutErrorUpdateCartFailed.value = new CheckoutErrorUpdateCartFailed();
    return CheckoutErrorUpdateCartFailed;
})();
var CheckoutErrorInvalidZapperId = (function () {
    function CheckoutErrorInvalidZapperId() {

    };
    CheckoutErrorInvalidZapperId.value = new CheckoutErrorInvalidZapperId();
    return CheckoutErrorInvalidZapperId;
})();
var CheckoutErrorProcessingError = (function () {
    function CheckoutErrorProcessingError() {

    };
    CheckoutErrorProcessingError.value = new CheckoutErrorProcessingError();
    return CheckoutErrorProcessingError;
})();
var AllocationsOrderAscendingStockCount = (function () {
    function AllocationsOrderAscendingStockCount() {

    };
    AllocationsOrderAscendingStockCount.value = new AllocationsOrderAscendingStockCount();
    return AllocationsOrderAscendingStockCount;
})();
var AllocationsOrderDescendingStockCount = (function () {
    function AllocationsOrderDescendingStockCount() {

    };
    AllocationsOrderDescendingStockCount.value = new AllocationsOrderDescendingStockCount();
    return AllocationsOrderDescendingStockCount;
})();
var AllocationErrorProductNotFound = (function () {
    function AllocationErrorProductNotFound() {

    };
    AllocationErrorProductNotFound.value = new AllocationErrorProductNotFound();
    return AllocationErrorProductNotFound;
})();
var AllocationErrorHoldingNotFound = (function () {
    function AllocationErrorHoldingNotFound() {

    };
    AllocationErrorHoldingNotFound.value = new AllocationErrorHoldingNotFound();
    return AllocationErrorHoldingNotFound;
})();
var AllocationErrorCurrencyNotFound = (function () {
    function AllocationErrorCurrencyNotFound(value0) {
        this.value0 = value0;
    };
    AllocationErrorCurrencyNotFound.create = function (value0) {
        return new AllocationErrorCurrencyNotFound(value0);
    };
    return AllocationErrorCurrencyNotFound;
})();
var AllocationErrorNoZapToHoldingPrice = (function () {
    function AllocationErrorNoZapToHoldingPrice() {

    };
    AllocationErrorNoZapToHoldingPrice.value = new AllocationErrorNoZapToHoldingPrice();
    return AllocationErrorNoZapToHoldingPrice;
})();
var AllocationErrorNoHoldingToCustomerPrice = (function () {
    function AllocationErrorNoHoldingToCustomerPrice() {

    };
    AllocationErrorNoHoldingToCustomerPrice.value = new AllocationErrorNoHoldingToCustomerPrice();
    return AllocationErrorNoHoldingToCustomerPrice;
})();
var AllocationErrorInsufficientQuantity = (function () {
    function AllocationErrorInsufficientQuantity() {

    };
    AllocationErrorInsufficientQuantity.value = new AllocationErrorInsufficientQuantity();
    return AllocationErrorInsufficientQuantity;
})();
var AllocationErrorInsufficientBalance = (function () {
    function AllocationErrorInsufficientBalance() {

    };
    AllocationErrorInsufficientBalance.value = new AllocationErrorInsufficientBalance();
    return AllocationErrorInsufficientBalance;
})();
var AllocationErrorAllocationFailed = (function () {
    function AllocationErrorAllocationFailed() {

    };
    AllocationErrorAllocationFailed.value = new AllocationErrorAllocationFailed();
    return AllocationErrorAllocationFailed;
})();
var AllocationErrorInvalidCVal = (function () {
    function AllocationErrorInvalidCVal() {

    };
    AllocationErrorInvalidCVal.value = new AllocationErrorInvalidCVal();
    return AllocationErrorInvalidCVal;
})();
var rpcReq = function (dictZRPCWrite) {
    return function (dictZRPCRead) {
        return ZRPC_Client.mkRPCAppReqBuilder(dictZRPCWrite)(dictZRPCRead)("trove");
    };
};
var listVendors = rpcReq(ZRPC_ZRPCWrite.zrpcWriteUnit)(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendors";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "shortName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("listVendors");
var listHoldings = rpcReq(ZRPC_ZRPCWrite.zrpcWriteUnit)(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdings";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "balance";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "theme";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "aboutUsUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "banner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "url";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "text";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "primaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "secondaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "tertiaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("listHoldings");
var listCategories = rpcReq(ZRPC_ZRPCWrite.zrpcWriteUnit)(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "categories";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("listCategories");
var getVendor = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "shortName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getVendor");
var getPurchasedProductsByRefNo = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refNo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchasedProductCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "txRefNo";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getPurchasedProductsByRefNo");
var getPurchasedProductCountByZapper = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchaseCount";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getPurchasedProductCountByZapper");
var getPurchasedProductCode = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchasedProductCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "txRefNo";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getPurchasedProductCode");
var getProductCode = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "batchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isValid";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchaseId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getProductCode");
var getHolding = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holding";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "balance";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "theme";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "aboutUsUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "banner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "url";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "text";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "primaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "secondaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "tertiaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getHolding");
var getAllocationByProduct = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "allocation";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "availableQuantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "cVal";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "reservedQuantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getAllocationByProduct");
var genericUpdateVendorError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UpdateVendorErrorVendorNotFound) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof UpdateVendorErrorUploadFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof UpdateVendorErrorAttachmentNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof UpdateVendorErrorUpdateFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 476, column 1 - line 476, column 72): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return UpdateVendorErrorVendorNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return UpdateVendorErrorUploadFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return UpdateVendorErrorAttachmentNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return UpdateVendorErrorUpdateFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 476, column 1 - line 476, column 72): " + [ x.constructor.name ]);
});
var showUpdateVendorError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericUpdateVendorError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorVendorNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorUploadFailed";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorAttachmentNotFound";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorUpdateFailed";
    }))))))(x);
});
var genericUpdateProductError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UpdateProductErrorProductNotFound) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof UpdateProductErrorInvalidVendor) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof UpdateProductErrorInvalidCategory) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof UpdateProductErrorUploadFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof UpdateProductErrorAttachmentNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof UpdateProductErrorUpdateFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 713, column 1 - line 713, column 74): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return UpdateProductErrorProductNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return UpdateProductErrorInvalidVendor.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return UpdateProductErrorInvalidCategory.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return UpdateProductErrorUploadFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return UpdateProductErrorAttachmentNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
        return UpdateProductErrorUpdateFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 713, column 1 - line 713, column 74): " + [ x.constructor.name ]);
});
var showUpdateProductError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericUpdateProductError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorProductNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorInvalidVendor";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorInvalidCategory";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorUploadFailed";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorAttachmentNotFound";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorUpdateFailed";
    }))))))))(x);
});
var genericUpdateMode = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UpdateModeSet) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof UpdateModeUnset) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof UpdateModeRetain) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 163, column 1 - line 163, column 62): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new UpdateModeSet(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return UpdateModeUnset.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return UpdateModeRetain.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 163, column 1 - line 163, column 62): " + [ x.constructor.name ]);
});
var showUpdateMode = function (dictShow) {
    return new Data_Show.Show(function (x) {
        return Data_Generic_Rep_Show.genericShow(genericUpdateMode)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "value";
        }))(Data_Show.showRecordFieldsNil)(dictShow))))(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeSet";
        })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeUnset";
        })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeRetain";
        })))))(x);
    });
};
var genericUpdateHoldingThemeError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UpdateHoldingThemeErrorHoldingNotFound) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof UpdateHoldingThemeErrorUploadFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof UpdateHoldingThemeErrorAttachmentNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof UpdateHoldingThemeErrorUpdateFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 1164, column 1 - line 1164, column 84): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return UpdateHoldingThemeErrorHoldingNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return UpdateHoldingThemeErrorUploadFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return UpdateHoldingThemeErrorAttachmentNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return UpdateHoldingThemeErrorUpdateFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 1164, column 1 - line 1164, column 84): " + [ x.constructor.name ]);
});
var showUpdateHoldingThemeError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericUpdateHoldingThemeError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorHoldingNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorUploadFailed";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorAttachmentNotFound";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorUpdateFailed";
    }))))))(x);
});
var genericUpdateCartError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UpdateCartErrorInvalidZapperId) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof UpdateCartErrorProductDoesNotExist) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof UpdateCartErrorAllocationNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof UpdateCartErrorInsufficientAllocation) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof UpdateCartErrorUpdateCartFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 876, column 1 - line 876, column 68): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return UpdateCartErrorInvalidZapperId.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return UpdateCartErrorProductDoesNotExist.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return UpdateCartErrorAllocationNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return UpdateCartErrorInsufficientAllocation.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return UpdateCartErrorUpdateCartFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 876, column 1 - line 876, column 68): " + [ x.constructor.name ]);
});
var showUpdateCartError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericUpdateCartError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorInvalidZapperId";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorProductDoesNotExist";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorAllocationNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorInsufficientAllocation";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorUpdateCartFailed";
    })))))))(x);
});
var genericReservationError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ReservationErrorNoProductCodesAvailable) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof ReservationErrorNoAllocation) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof ReservationErrorInsufficientAllocations) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 985, column 1 - line 985, column 70): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new ReservationErrorNoProductCodesAvailable(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new ReservationErrorNoAllocation(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return new ReservationErrorInsufficientAllocations(x.value0.value0);
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 985, column 1 - line 985, column 70): " + [ x.constructor.name ]);
});
var showReservationError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericReservationError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorNoProductCodesAvailable";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "holdingId";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorNoAllocation";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "holdingId";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorInsufficientAllocations";
    })))))(x);
});
var genericPurchaseStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof PurchaseStatusProcessing) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof PurchaseStatusProcessed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof PurchaseStatusFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 316, column 1 - line 316, column 66): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return PurchaseStatusProcessing.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return PurchaseStatusProcessed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return PurchaseStatusFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 316, column 1 - line 316, column 66): " + [ x.constructor.name ]);
});
var showPurchaseStatus = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericPurchaseStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusProcessing";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusProcessed";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusFailed";
    })))))(x);
});
var genericProductCodeValidationError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ProductCodeValidationErrorIncompleteField) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof ProductCodeValidationErrorInvalidVendorName) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof ProductCodeValidationErrorInvalidProductName) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof ProductCodeValidationErrorInvalidProductId) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof ProductCodeValidationErrorDuplicateProductCode) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 270, column 1 - line 270, column 90): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new ProductCodeValidationErrorIncompleteField(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new ProductCodeValidationErrorInvalidVendorName(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new ProductCodeValidationErrorInvalidProductName(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new ProductCodeValidationErrorInvalidProductId(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return new ProductCodeValidationErrorDuplicateProductCode(x.value0.value0.value0.value0);
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 270, column 1 - line 270, column 90): " + [ x.constructor.name ]);
});
var showProductCodeValidationError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericProductCodeValidationError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorIncompleteField";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidVendorName";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidProductName";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidProductId";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorDuplicateProductCode";
    })))))))(x);
});
var genericItemValidationError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ItemValidationErrorItemDoesNotExist) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof ItemValidationErrorItemNotInCart) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof ItemValidationErrorInvalidQuantity) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 961, column 1 - line 961, column 76): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new ItemValidationErrorItemDoesNotExist(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new ItemValidationErrorItemNotInCart(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return new ItemValidationErrorInvalidQuantity(x.value0.value0);
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 961, column 1 - line 961, column 76): " + [ x.constructor.name ]);
});
var showItemValidationError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericItemValidationError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorItemDoesNotExist";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorItemNotInCart";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorInvalidQuantity";
    })))))(x);
});
var genericHoldingActivityType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof HoldingActivityTypeCredit) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof HoldingActivityTypeDebit) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof HoldingActivityTypeAllocation) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 402, column 1 - line 402, column 76): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return HoldingActivityTypeCredit.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return HoldingActivityTypeDebit.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return HoldingActivityTypeAllocation.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 402, column 1 - line 402, column 76): " + [ x.constructor.name ]);
});
var showHoldingActivityType = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericHoldingActivityType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeCredit";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeDebit";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeAllocation";
    })))))(x);
});
var genericGetHoldingBalanceError = new Data_Generic_Rep.Generic(function (x) {
    return Data_Generic_Rep.NoArguments.value;
}, function (x) {
    return GetHoldingBalanceErrorHoldingDoesNotExist.value;
});
var showGetHoldingBalanceError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericGetHoldingBalanceError)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "GetHoldingBalanceErrorHoldingDoesNotExist";
    })))(x);
});
var genericGetCartError = new Data_Generic_Rep.Generic(function (x) {
    return Data_Generic_Rep.NoArguments.value;
}, function (x) {
    return GetCartErrorInvalidZapperId.value;
});
var showGetCartError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericGetCartError)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "GetCartErrorInvalidZapperId";
    })))(x);
});
var genericDeleteCartItemsError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof DeleteCartItemsErrorInvalidZapperId) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof DeleteCartItemsErrorCartDoesNotExist) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof DeleteCartItemsErrorProductIdNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof DeleteCartItemsErrorUpdateCartFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 904, column 1 - line 904, column 78): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return DeleteCartItemsErrorInvalidZapperId.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return DeleteCartItemsErrorCartDoesNotExist.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new DeleteCartItemsErrorProductIdNotFound(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return DeleteCartItemsErrorUpdateCartFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 904, column 1 - line 904, column 78): " + [ x.constructor.name ]);
});
var showDeleteCartItemsError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericDeleteCartItemsError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorInvalidZapperId";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorCartDoesNotExist";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productIds";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorProductIdNotFound";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorUpdateCartFailed";
    }))))))(x);
});
var genericDebitHoldingError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof DebitHoldingErrorInvalidAmount) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof DebitHoldingErrorHoldingDoesNotExist) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof DebitHoldingErrorInsufficientBalance) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof DebitHoldingErrorUpdateHoldingError) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 598, column 1 - line 598, column 72): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return DebitHoldingErrorInvalidAmount.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return DebitHoldingErrorHoldingDoesNotExist.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return DebitHoldingErrorInsufficientBalance.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return DebitHoldingErrorUpdateHoldingError.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 598, column 1 - line 598, column 72): " + [ x.constructor.name ]);
});
var showDebitHoldingError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericDebitHoldingError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorInvalidAmount";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorHoldingDoesNotExist";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorInsufficientBalance";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorUpdateHoldingError";
    }))))))(x);
});
var genericDateTimeOrder = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof DateTimeOrderDescending) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof DateTimeOrderAscending) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 357, column 1 - line 357, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return DateTimeOrderDescending.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return DateTimeOrderAscending.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 357, column 1 - line 357, column 64): " + [ x.constructor.name ]);
});
var showDateTimeOrder = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericDateTimeOrder)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DateTimeOrderDescending";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "DateTimeOrderAscending";
    }))))(x);
});
var genericCreditHoldingError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CreditHoldingErrorInvalidAmount) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CreditHoldingErrorHoldingDoesNotExist) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof CreditHoldingErrorUpdateHoldingError) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 568, column 1 - line 568, column 74): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return CreditHoldingErrorInvalidAmount.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return CreditHoldingErrorHoldingDoesNotExist.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return CreditHoldingErrorUpdateHoldingError.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 568, column 1 - line 568, column 74): " + [ x.constructor.name ]);
});
var showCreditHoldingError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericCreditHoldingError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorInvalidAmount";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorHoldingDoesNotExist";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorUpdateHoldingError";
    })))))(x);
});
var genericCreateVendorError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CreateVendorErrorUploadFailed) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CreateVendorErrorAttachmentNotFound) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 447, column 1 - line 447, column 72): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return CreateVendorErrorUploadFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return CreateVendorErrorAttachmentNotFound.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 447, column 1 - line 447, column 72): " + [ x.constructor.name ]);
});
var showCreateVendorError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericCreateVendorError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateVendorErrorUploadFailed";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateVendorErrorAttachmentNotFound";
    }))))(x);
});
var genericCreateProductError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CreateProductErrorProductAlreadyExists) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CreateProductErrorInvalidVendor) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof CreateProductErrorInvalidCategory) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof CreateProductErrorUploadFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof CreateProductErrorAttachmentNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof CreateProductErrorInsertFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 672, column 1 - line 672, column 74): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return CreateProductErrorProductAlreadyExists.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return CreateProductErrorInvalidVendor.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return CreateProductErrorInvalidCategory.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return CreateProductErrorUploadFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return CreateProductErrorAttachmentNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
        return CreateProductErrorInsertFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 672, column 1 - line 672, column 74): " + [ x.constructor.name ]);
});
var showCreateProductError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericCreateProductError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorProductAlreadyExists";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInvalidVendor";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInvalidCategory";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorUploadFailed";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorAttachmentNotFound";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInsertFailed";
    }))))))))(x);
});
var genericCreateProductCodesError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CreateProductCodesErrorInvalidAttachment) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CreateProductCodesErrorProductNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof CreateProductCodesErrorVendorNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof CreateProductCodesErrorInvalidFileFormat) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof CreateProductCodesErrorNoEntriesFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof CreateProductCodesErrorValidationErrors) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
    };
    if (x instanceof CreateProductCodesErrorUploadFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    };
    if (x instanceof CreateProductCodesErrorCreateProductCodesFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 767, column 1 - line 767, column 84): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return CreateProductCodesErrorInvalidAttachment.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return CreateProductCodesErrorProductNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return CreateProductCodesErrorVendorNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return CreateProductCodesErrorInvalidFileFormat.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return CreateProductCodesErrorNoEntriesFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return new CreateProductCodesErrorValidationErrors(x.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return CreateProductCodesErrorUploadFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))) {
        return CreateProductCodesErrorCreateProductCodesFailed.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 767, column 1 - line 767, column 84): " + [ x.constructor.name ]);
});
var showCreateProductCodesError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericCreateProductCodesError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorInvalidAttachment";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorProductNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorVendorNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorInvalidFileFormat";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorNoEntriesFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(showProductCodeValidationError)))))(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorValidationErrors";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorUploadFailed";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorCreateProductCodesFailed";
    }))))))))))(x);
});
var genericCreateHoldingError = new Data_Generic_Rep.Generic(function (x) {
    return Data_Generic_Rep.NoArguments.value;
}, function (x) {
    return CreateHoldingErrorHoldingAlreadyExists.value;
});
var showCreateHoldingError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericCreateHoldingError)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CreateHoldingErrorHoldingAlreadyExists";
    })))(x);
});
var genericCodeType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CodeTypeAlphanum) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CodeTypeBCode) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 643, column 1 - line 643, column 54): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return CodeTypeAlphanum.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return CodeTypeBCode.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 643, column 1 - line 643, column 54): " + [ x.constructor.name ]);
});
var showCodeType = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericCodeType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CodeTypeAlphanum";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CodeTypeBCode";
    }))))(x);
});
var genericCheckoutError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CheckoutErrorInvalidProducts) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof CheckoutErrorCartNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof CheckoutErrorReservationFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof CheckoutErrorInvalidTransaction) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof CheckoutErrorTransactionAlreadyUsed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
    };
    if (x instanceof CheckoutErrorInvalidPurchase) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
    };
    if (x instanceof CheckoutErrorInvalidProductCode) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    };
    if (x instanceof CheckoutErrorUpdateCartFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
    };
    if (x instanceof CheckoutErrorInvalidZapperId) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))));
    };
    if (x instanceof CheckoutErrorProcessingError) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))))));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 1023, column 1 - line 1023, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new CheckoutErrorInvalidProducts(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return CheckoutErrorCartNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new CheckoutErrorReservationFailed(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new CheckoutErrorInvalidTransaction(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return new CheckoutErrorTransactionAlreadyUsed(x.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return new CheckoutErrorInvalidPurchase(x.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return CheckoutErrorInvalidProductCode.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
        return CheckoutErrorUpdateCartFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
        return CheckoutErrorInvalidZapperId.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))) {
        return CheckoutErrorProcessingError.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 1023, column 1 - line 1023, column 64): " + [ x.constructor.name ]);
});
var showCheckoutError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericCheckoutError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(showItemValidationError)))))(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidProducts";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorCartNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(showReservationError)))))(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorReservationFailed";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "txRefNo";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidTransaction";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "txRefNo";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorTransactionAlreadyUsed";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "purchaseId";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidPurchase";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidProductCode";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorUpdateCartFailed";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidZapperId";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorProcessingError";
    }))))))))))))(x);
});
var genericAllocationsOrder = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof AllocationsOrderAscendingStockCount) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof AllocationsOrderDescendingStockCount) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 825, column 1 - line 825, column 70): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return AllocationsOrderAscendingStockCount.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return AllocationsOrderDescendingStockCount.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 825, column 1 - line 825, column 70): " + [ x.constructor.name ]);
});
var showAllocationsOrder = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericAllocationsOrder)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationsOrderAscendingStockCount";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationsOrderDescendingStockCount";
    }))))(x);
});
var genericAllocationError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof AllocationErrorProductNotFound) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof AllocationErrorHoldingNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof AllocationErrorCurrencyNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof AllocationErrorNoZapToHoldingPrice) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof AllocationErrorNoHoldingToCustomerPrice) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof AllocationErrorInsufficientQuantity) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
    };
    if (x instanceof AllocationErrorInsufficientBalance) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    };
    if (x instanceof AllocationErrorAllocationFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
    };
    if (x instanceof AllocationErrorInvalidCVal) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))))));
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 808, column 1 - line 808, column 68): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return AllocationErrorProductNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return AllocationErrorHoldingNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new AllocationErrorCurrencyNotFound(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return AllocationErrorNoZapToHoldingPrice.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return AllocationErrorNoHoldingToCustomerPrice.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return AllocationErrorInsufficientQuantity.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return AllocationErrorInsufficientBalance.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
        return AllocationErrorAllocationFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))) {
        return AllocationErrorInvalidCVal.value;
    };
    throw new Error("Failed pattern match at ZRPC.Clients.Trove (line 808, column 1 - line 808, column 68): " + [ x.constructor.name ]);
});
var showAllocationError = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericAllocationError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorProductNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorHoldingNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "currencyIds";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showInt)))))(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorCurrencyNotFound";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorNoZapToHoldingPrice";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorNoHoldingToCustomerPrice";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInsufficientQuantity";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInsufficientBalance";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorAllocationFailed";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInvalidCVal";
    })))))))))))(x);
});
var eqUpdateVendorError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof UpdateVendorErrorVendorNotFound && y instanceof UpdateVendorErrorVendorNotFound) {
            return true;
        };
        if (x instanceof UpdateVendorErrorUploadFailed && y instanceof UpdateVendorErrorUploadFailed) {
            return true;
        };
        if (x instanceof UpdateVendorErrorAttachmentNotFound && y instanceof UpdateVendorErrorAttachmentNotFound) {
            return true;
        };
        if (x instanceof UpdateVendorErrorUpdateFailed && y instanceof UpdateVendorErrorUpdateFailed) {
            return true;
        };
        return false;
    };
});
var eqUpdateProductError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof UpdateProductErrorProductNotFound && y instanceof UpdateProductErrorProductNotFound) {
            return true;
        };
        if (x instanceof UpdateProductErrorInvalidVendor && y instanceof UpdateProductErrorInvalidVendor) {
            return true;
        };
        if (x instanceof UpdateProductErrorInvalidCategory && y instanceof UpdateProductErrorInvalidCategory) {
            return true;
        };
        if (x instanceof UpdateProductErrorUploadFailed && y instanceof UpdateProductErrorUploadFailed) {
            return true;
        };
        if (x instanceof UpdateProductErrorAttachmentNotFound && y instanceof UpdateProductErrorAttachmentNotFound) {
            return true;
        };
        if (x instanceof UpdateProductErrorUpdateFailed && y instanceof UpdateProductErrorUpdateFailed) {
            return true;
        };
        return false;
    };
});
var eqUpdateMode = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            if (x instanceof UpdateModeSet && y instanceof UpdateModeSet) {
                return Data_Eq.eq(dictEq)(x.value0.value)(y.value0.value);
            };
            if (x instanceof UpdateModeUnset && y instanceof UpdateModeUnset) {
                return true;
            };
            if (x instanceof UpdateModeRetain && y instanceof UpdateModeRetain) {
                return true;
            };
            return false;
        };
    });
};
var eqUpdateHoldingThemeError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof UpdateHoldingThemeErrorHoldingNotFound && y instanceof UpdateHoldingThemeErrorHoldingNotFound) {
            return true;
        };
        if (x instanceof UpdateHoldingThemeErrorUploadFailed && y instanceof UpdateHoldingThemeErrorUploadFailed) {
            return true;
        };
        if (x instanceof UpdateHoldingThemeErrorAttachmentNotFound && y instanceof UpdateHoldingThemeErrorAttachmentNotFound) {
            return true;
        };
        if (x instanceof UpdateHoldingThemeErrorUpdateFailed && y instanceof UpdateHoldingThemeErrorUpdateFailed) {
            return true;
        };
        return false;
    };
});
var eqUpdateCartError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof UpdateCartErrorInvalidZapperId && y instanceof UpdateCartErrorInvalidZapperId) {
            return true;
        };
        if (x instanceof UpdateCartErrorProductDoesNotExist && y instanceof UpdateCartErrorProductDoesNotExist) {
            return true;
        };
        if (x instanceof UpdateCartErrorAllocationNotFound && y instanceof UpdateCartErrorAllocationNotFound) {
            return true;
        };
        if (x instanceof UpdateCartErrorInsufficientAllocation && y instanceof UpdateCartErrorInsufficientAllocation) {
            return true;
        };
        if (x instanceof UpdateCartErrorUpdateCartFailed && y instanceof UpdateCartErrorUpdateCartFailed) {
            return true;
        };
        return false;
    };
});
var eqReservationError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof ReservationErrorNoProductCodesAvailable && y instanceof ReservationErrorNoProductCodesAvailable) {
            return x.value0.productId === y.value0.productId;
        };
        if (x instanceof ReservationErrorNoAllocation && y instanceof ReservationErrorNoAllocation) {
            return x.value0.holdingId === y.value0.holdingId && x.value0.productId === y.value0.productId;
        };
        if (x instanceof ReservationErrorInsufficientAllocations && y instanceof ReservationErrorInsufficientAllocations) {
            return x.value0.holdingId === y.value0.holdingId && x.value0.productId === y.value0.productId;
        };
        return false;
    };
});
var eqPurchaseStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof PurchaseStatusProcessing && y instanceof PurchaseStatusProcessing) {
            return true;
        };
        if (x instanceof PurchaseStatusProcessed && y instanceof PurchaseStatusProcessed) {
            return true;
        };
        if (x instanceof PurchaseStatusFailed && y instanceof PurchaseStatusFailed) {
            return true;
        };
        return false;
    };
});
var eqProductCodeValidationError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof ProductCodeValidationErrorIncompleteField && y instanceof ProductCodeValidationErrorIncompleteField) {
            return x.value0.lineNumber === y.value0.lineNumber;
        };
        if (x instanceof ProductCodeValidationErrorInvalidVendorName && y instanceof ProductCodeValidationErrorInvalidVendorName) {
            return x.value0.lineNumber === y.value0.lineNumber;
        };
        if (x instanceof ProductCodeValidationErrorInvalidProductName && y instanceof ProductCodeValidationErrorInvalidProductName) {
            return x.value0.lineNumber === y.value0.lineNumber;
        };
        if (x instanceof ProductCodeValidationErrorInvalidProductId && y instanceof ProductCodeValidationErrorInvalidProductId) {
            return x.value0.lineNumber === y.value0.lineNumber;
        };
        if (x instanceof ProductCodeValidationErrorDuplicateProductCode && y instanceof ProductCodeValidationErrorDuplicateProductCode) {
            return x.value0.lineNumber === y.value0.lineNumber;
        };
        return false;
    };
});
var eqItemValidationError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof ItemValidationErrorItemDoesNotExist && y instanceof ItemValidationErrorItemDoesNotExist) {
            return x.value0.productId === y.value0.productId;
        };
        if (x instanceof ItemValidationErrorItemNotInCart && y instanceof ItemValidationErrorItemNotInCart) {
            return x.value0.productId === y.value0.productId;
        };
        if (x instanceof ItemValidationErrorInvalidQuantity && y instanceof ItemValidationErrorInvalidQuantity) {
            return x.value0.productId === y.value0.productId;
        };
        return false;
    };
});
var eqHoldingActivityType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof HoldingActivityTypeCredit && y instanceof HoldingActivityTypeCredit) {
            return true;
        };
        if (x instanceof HoldingActivityTypeDebit && y instanceof HoldingActivityTypeDebit) {
            return true;
        };
        if (x instanceof HoldingActivityTypeAllocation && y instanceof HoldingActivityTypeAllocation) {
            return true;
        };
        return false;
    };
});
var eqGetHoldingBalanceError = new Data_Eq.Eq(function (x) {
    return function (y) {
        return true;
    };
});
var eqGetCartError = new Data_Eq.Eq(function (x) {
    return function (y) {
        return true;
    };
});
var eqDeleteCartItemsError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof DeleteCartItemsErrorInvalidZapperId && y instanceof DeleteCartItemsErrorInvalidZapperId) {
            return true;
        };
        if (x instanceof DeleteCartItemsErrorCartDoesNotExist && y instanceof DeleteCartItemsErrorCartDoesNotExist) {
            return true;
        };
        if (x instanceof DeleteCartItemsErrorProductIdNotFound && y instanceof DeleteCartItemsErrorProductIdNotFound) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.productIds)(y.value0.productIds);
        };
        if (x instanceof DeleteCartItemsErrorUpdateCartFailed && y instanceof DeleteCartItemsErrorUpdateCartFailed) {
            return true;
        };
        return false;
    };
});
var eqDebitHoldingError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof DebitHoldingErrorInvalidAmount && y instanceof DebitHoldingErrorInvalidAmount) {
            return true;
        };
        if (x instanceof DebitHoldingErrorHoldingDoesNotExist && y instanceof DebitHoldingErrorHoldingDoesNotExist) {
            return true;
        };
        if (x instanceof DebitHoldingErrorInsufficientBalance && y instanceof DebitHoldingErrorInsufficientBalance) {
            return true;
        };
        if (x instanceof DebitHoldingErrorUpdateHoldingError && y instanceof DebitHoldingErrorUpdateHoldingError) {
            return true;
        };
        return false;
    };
});
var eqDateTimeOrder = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof DateTimeOrderDescending && y instanceof DateTimeOrderDescending) {
            return true;
        };
        if (x instanceof DateTimeOrderAscending && y instanceof DateTimeOrderAscending) {
            return true;
        };
        return false;
    };
});
var eqCreditHoldingError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof CreditHoldingErrorInvalidAmount && y instanceof CreditHoldingErrorInvalidAmount) {
            return true;
        };
        if (x instanceof CreditHoldingErrorHoldingDoesNotExist && y instanceof CreditHoldingErrorHoldingDoesNotExist) {
            return true;
        };
        if (x instanceof CreditHoldingErrorUpdateHoldingError && y instanceof CreditHoldingErrorUpdateHoldingError) {
            return true;
        };
        return false;
    };
});
var eqCreateVendorError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof CreateVendorErrorUploadFailed && y instanceof CreateVendorErrorUploadFailed) {
            return true;
        };
        if (x instanceof CreateVendorErrorAttachmentNotFound && y instanceof CreateVendorErrorAttachmentNotFound) {
            return true;
        };
        return false;
    };
});
var eqCreateProductError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof CreateProductErrorProductAlreadyExists && y instanceof CreateProductErrorProductAlreadyExists) {
            return true;
        };
        if (x instanceof CreateProductErrorInvalidVendor && y instanceof CreateProductErrorInvalidVendor) {
            return true;
        };
        if (x instanceof CreateProductErrorInvalidCategory && y instanceof CreateProductErrorInvalidCategory) {
            return true;
        };
        if (x instanceof CreateProductErrorUploadFailed && y instanceof CreateProductErrorUploadFailed) {
            return true;
        };
        if (x instanceof CreateProductErrorAttachmentNotFound && y instanceof CreateProductErrorAttachmentNotFound) {
            return true;
        };
        if (x instanceof CreateProductErrorInsertFailed && y instanceof CreateProductErrorInsertFailed) {
            return true;
        };
        return false;
    };
});
var eqCreateProductCodesError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof CreateProductCodesErrorInvalidAttachment && y instanceof CreateProductCodesErrorInvalidAttachment) {
            return true;
        };
        if (x instanceof CreateProductCodesErrorProductNotFound && y instanceof CreateProductCodesErrorProductNotFound) {
            return true;
        };
        if (x instanceof CreateProductCodesErrorVendorNotFound && y instanceof CreateProductCodesErrorVendorNotFound) {
            return true;
        };
        if (x instanceof CreateProductCodesErrorInvalidFileFormat && y instanceof CreateProductCodesErrorInvalidFileFormat) {
            return true;
        };
        if (x instanceof CreateProductCodesErrorNoEntriesFound && y instanceof CreateProductCodesErrorNoEntriesFound) {
            return true;
        };
        if (x instanceof CreateProductCodesErrorValidationErrors && y instanceof CreateProductCodesErrorValidationErrors) {
            return Data_Eq.eq(Data_Eq.eqArray(eqProductCodeValidationError))(x.value0.errors)(y.value0.errors);
        };
        if (x instanceof CreateProductCodesErrorUploadFailed && y instanceof CreateProductCodesErrorUploadFailed) {
            return true;
        };
        if (x instanceof CreateProductCodesErrorCreateProductCodesFailed && y instanceof CreateProductCodesErrorCreateProductCodesFailed) {
            return true;
        };
        return false;
    };
});
var eqCreateHoldingError = new Data_Eq.Eq(function (x) {
    return function (y) {
        return true;
    };
});
var eqCodeType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof CodeTypeAlphanum && y instanceof CodeTypeAlphanum) {
            return true;
        };
        if (x instanceof CodeTypeBCode && y instanceof CodeTypeBCode) {
            return true;
        };
        return false;
    };
});
var eqCheckoutError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof CheckoutErrorInvalidProducts && y instanceof CheckoutErrorInvalidProducts) {
            return Data_Eq.eq(Data_Eq.eqArray(eqItemValidationError))(x.value0.errors)(y.value0.errors);
        };
        if (x instanceof CheckoutErrorCartNotFound && y instanceof CheckoutErrorCartNotFound) {
            return true;
        };
        if (x instanceof CheckoutErrorReservationFailed && y instanceof CheckoutErrorReservationFailed) {
            return Data_Eq.eq(Data_Eq.eqArray(eqReservationError))(x.value0.errors)(y.value0.errors);
        };
        if (x instanceof CheckoutErrorInvalidTransaction && y instanceof CheckoutErrorInvalidTransaction) {
            return x.value0.txRefNo === y.value0.txRefNo;
        };
        if (x instanceof CheckoutErrorTransactionAlreadyUsed && y instanceof CheckoutErrorTransactionAlreadyUsed) {
            return x.value0.txRefNo === y.value0.txRefNo;
        };
        if (x instanceof CheckoutErrorInvalidPurchase && y instanceof CheckoutErrorInvalidPurchase) {
            return x.value0.purchaseId === y.value0.purchaseId;
        };
        if (x instanceof CheckoutErrorInvalidProductCode && y instanceof CheckoutErrorInvalidProductCode) {
            return true;
        };
        if (x instanceof CheckoutErrorUpdateCartFailed && y instanceof CheckoutErrorUpdateCartFailed) {
            return true;
        };
        if (x instanceof CheckoutErrorInvalidZapperId && y instanceof CheckoutErrorInvalidZapperId) {
            return true;
        };
        if (x instanceof CheckoutErrorProcessingError && y instanceof CheckoutErrorProcessingError) {
            return true;
        };
        return false;
    };
});
var eqAllocationsOrder = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof AllocationsOrderAscendingStockCount && y instanceof AllocationsOrderAscendingStockCount) {
            return true;
        };
        if (x instanceof AllocationsOrderDescendingStockCount && y instanceof AllocationsOrderDescendingStockCount) {
            return true;
        };
        return false;
    };
});
var eqAllocationError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof AllocationErrorProductNotFound && y instanceof AllocationErrorProductNotFound) {
            return true;
        };
        if (x instanceof AllocationErrorHoldingNotFound && y instanceof AllocationErrorHoldingNotFound) {
            return true;
        };
        if (x instanceof AllocationErrorCurrencyNotFound && y instanceof AllocationErrorCurrencyNotFound) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqInt))(x.value0.currencyIds)(y.value0.currencyIds);
        };
        if (x instanceof AllocationErrorNoZapToHoldingPrice && y instanceof AllocationErrorNoZapToHoldingPrice) {
            return true;
        };
        if (x instanceof AllocationErrorNoHoldingToCustomerPrice && y instanceof AllocationErrorNoHoldingToCustomerPrice) {
            return true;
        };
        if (x instanceof AllocationErrorInsufficientQuantity && y instanceof AllocationErrorInsufficientQuantity) {
            return true;
        };
        if (x instanceof AllocationErrorInsufficientBalance && y instanceof AllocationErrorInsufficientBalance) {
            return true;
        };
        if (x instanceof AllocationErrorAllocationFailed && y instanceof AllocationErrorAllocationFailed) {
            return true;
        };
        if (x instanceof AllocationErrorInvalidCVal && y instanceof AllocationErrorInvalidCVal) {
            return true;
        };
        return false;
    };
});
var createCategory = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "category";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("createCategory");
var consPrefixUpdateVendorError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "UpdateVendorError";
});
var zrpcReadUpdateVendorError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixUpdateVendorError)(genericUpdateVendorError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorVendorNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorUploadFailed";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorAttachmentNotFound";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorUpdateFailed";
    }))))))(x);
});
var zrpcWriteUpdateVendorError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixUpdateVendorError)(genericUpdateVendorError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorVendorNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorUploadFailed";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorAttachmentNotFound";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateVendorErrorUpdateFailed";
    }))))))(x);
});
var consPrefixUpdateProductError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "UpdateProductError";
});
var zrpcReadUpdateProductError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixUpdateProductError)(genericUpdateProductError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorProductNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorInvalidVendor";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorInvalidCategory";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorUploadFailed";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorAttachmentNotFound";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorUpdateFailed";
    }))))))))(x);
});
var zrpcWriteUpdateProductError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixUpdateProductError)(genericUpdateProductError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorProductNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorInvalidVendor";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorInvalidCategory";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorUploadFailed";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorAttachmentNotFound";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateProductErrorUpdateFailed";
    }))))))))(x);
});
var consPrefixUpdateMode = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "UpdateMode";
});
var zrpcReadUpdateMode = function (dictZRPCRead) {
    return new ZRPC_ZRPCRead.ZRPCRead(function (x) {
        return ZRPC_ZRPCRead.genericZRPCRead(consPrefixUpdateMode)(genericUpdateMode)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeSet";
        }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "value";
        }))(ZRPC_ZRPCRead.zrpcReadFieldId(dictZRPCRead))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeUnset";
        })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeRetain";
        })))))(x);
    });
};
var zrpcWriteUpdateMode = function (dictZRPCWrite) {
    return new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
        return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixUpdateMode)(genericUpdateMode)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeSet";
        }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "value";
        }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(dictZRPCWrite))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeUnset";
        })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
            return "UpdateModeRetain";
        })))))(x);
    });
};
var updateVendor = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteUpdateMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "shortName";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteUpdateMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadUpdateVendorError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))("updateVendor");
var consPrefixUpdateHoldingThemeError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "UpdateHoldingThemeError";
});
var zrpcReadUpdateHoldingThemeError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixUpdateHoldingThemeError)(genericUpdateHoldingThemeError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorHoldingNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorUploadFailed";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorAttachmentNotFound";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorUpdateFailed";
    }))))))(x);
});
var updateHoldingTheme = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "aboutUsUrl";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "banner";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteUpdateMode(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "url";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteUpdateMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteUpdateMode(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "text";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "primaryColor";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "secondaryColor";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "tertiaryColor";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteUpdateMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())())())())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadUpdateHoldingThemeError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))("updateHoldingTheme");
var zrpcWriteUpdateHoldingThemeError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixUpdateHoldingThemeError)(genericUpdateHoldingThemeError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorHoldingNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorUploadFailed";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorAttachmentNotFound";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateHoldingThemeErrorUpdateFailed";
    }))))))(x);
});
var consPrefixUpdateCartError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "UpdateCartError";
});
var zrpcReadUpdateCartError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixUpdateCartError)(genericUpdateCartError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorInvalidZapperId";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorProductDoesNotExist";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorAllocationNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorInsufficientAllocation";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorUpdateCartFailed";
    })))))))(x);
});
var updateCart = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "item";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "dateAdded";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadUpdateCartError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "dateAdded";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()()))))("updateCart");
var zrpcWriteUpdateCartError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixUpdateCartError)(genericUpdateCartError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorInvalidZapperId";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorProductDoesNotExist";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorAllocationNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorInsufficientAllocation";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "UpdateCartErrorUpdateCartFailed";
    })))))))(x);
});
var consPrefixReservationError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ReservationError";
});
var zrpcReadReservationError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixReservationError)(genericReservationError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorNoProductCodesAvailable";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorNoAllocation";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "holdingId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorInsufficientAllocations";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "holdingId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(x);
});
var zrpcWriteReservationError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixReservationError)(genericReservationError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorNoProductCodesAvailable";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorNoAllocation";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "holdingId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ReservationErrorInsufficientAllocations";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "holdingId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())))(x);
});
var consPrefixPurchaseStatus = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "PurchaseStatus";
});
var zrpcReadPurchaseStatus = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixPurchaseStatus)(genericPurchaseStatus)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusProcessing";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusProcessed";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusFailed";
    })))))(x);
});
var getPurchase = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchase";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "cVals";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "finalizedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "reservedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "status";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadPurchaseStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "transactionRefNo";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getPurchase");
var zrpcWritePurchaseStatus = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixPurchaseStatus)(genericPurchaseStatus)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusProcessing";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusProcessed";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "PurchaseStatusFailed";
    })))))(x);
});
var consPrefixProductCodeValidationError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ProductCodeValidationError";
});
var zrpcReadProductCodeValidationError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixProductCodeValidationError)(genericProductCodeValidationError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorIncompleteField";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidVendorName";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidProductName";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidProductId";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorDuplicateProductCode";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))))))(x);
});
var zrpcWriteProductCodeValidationError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixProductCodeValidationError)(genericProductCodeValidationError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorIncompleteField";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidVendorName";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidProductName";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorInvalidProductId";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeValidationErrorDuplicateProductCode";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lineNumber";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))))(x);
});
var consPrefixItemValidationError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ItemValidationError";
});
var zrpcReadItemValidationError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixItemValidationError)(genericItemValidationError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorItemDoesNotExist";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorItemNotInCart";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorInvalidQuantity";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))))(x);
});
var zrpcWriteItemValidationError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixItemValidationError)(genericItemValidationError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorItemDoesNotExist";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorItemNotInCart";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ItemValidationErrorInvalidQuantity";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(x);
});
var consPrefixHoldingActivityType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "HoldingActivityType";
});
var zrpcReadHoldingActivityType = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixHoldingActivityType)(genericHoldingActivityType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeCredit";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeDebit";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeAllocation";
    })))))(x);
});
var zrpcWriteHoldingActivityType = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixHoldingActivityType)(genericHoldingActivityType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeCredit";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeDebit";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "HoldingActivityTypeAllocation";
    })))))(x);
});
var consPrefixGetHoldingBalanceError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "GetHoldingBalanceError";
});
var zrpcReadGetHoldingBalanceError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixGetHoldingBalanceError)(genericGetHoldingBalanceError)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "GetHoldingBalanceErrorHoldingDoesNotExist";
    })))(x);
});
var getHoldingBalance = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadGetHoldingBalanceError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "balance";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("getHoldingBalance");
var zrpcWriteGetHoldingBalanceError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixGetHoldingBalanceError)(genericGetHoldingBalanceError)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "GetHoldingBalanceErrorHoldingDoesNotExist";
    })))(x);
});
var consPrefixGetCartError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "GetCartError";
});
var zrpcReadGetCartError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixGetCartError)(genericGetCartError)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "GetCartErrorInvalidZapperId";
    })))(x);
});
var getCart = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadGetCartError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "dateAdded";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("getCart");
var zrpcWriteGetCartError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixGetCartError)(genericGetCartError)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "GetCartErrorInvalidZapperId";
    })))(x);
});
var consPrefixDeleteCartItemsError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "DeleteCartItemsError";
});
var zrpcReadDeleteCartItemsError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixDeleteCartItemsError)(genericDeleteCartItemsError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorInvalidZapperId";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorCartDoesNotExist";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorProductIdNotFound";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productIds";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorUpdateCartFailed";
    }))))))(x);
});
var deleteCartItems = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productIds";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadDeleteCartItemsError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "dateAdded";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()()))))("deleteCartItems");
var zrpcWriteDeleteCartItemsError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixDeleteCartItemsError)(genericDeleteCartItemsError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorInvalidZapperId";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorCartDoesNotExist";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorProductIdNotFound";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "productIds";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DeleteCartItemsErrorUpdateCartFailed";
    }))))))(x);
});
var consPrefixDebitHoldingError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "DebitHoldingError";
});
var zrpcReadDebitHoldingError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixDebitHoldingError)(genericDebitHoldingError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorInvalidAmount";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorHoldingDoesNotExist";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorInsufficientBalance";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorUpdateHoldingError";
    }))))))(x);
});
var debitHolding = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadDebitHoldingError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "balance";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("debitHolding");
var zrpcWriteDebitHoldingError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixDebitHoldingError)(genericDebitHoldingError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorInvalidAmount";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorHoldingDoesNotExist";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorInsufficientBalance";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DebitHoldingErrorUpdateHoldingError";
    }))))))(x);
});
var consPrefixDateTimeOrder = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "DateTimeOrder";
});
var zrpcReadDateTimeOrder = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixDateTimeOrder)(genericDateTimeOrder)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DateTimeOrderDescending";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DateTimeOrderAscending";
    }))))(x);
});
var zrpcWriteDateTimeOrder = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixDateTimeOrder)(genericDateTimeOrder)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DateTimeOrderDescending";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "DateTimeOrderAscending";
    }))))(x);
});
var listHoldingActivities = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coverage";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "order";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteDateTimeOrder))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "activities";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "activityType";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadHoldingActivityType))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "products";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingActivityId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapToHoldingPrice";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("listHoldingActivities");
var listProductCodeBatches = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coverage";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "order";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteDateTimeOrder))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "batches";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeCount";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "fileUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("listProductCodeBatches");
var listProductCodesPurchasedByZapper = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coverage";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "includeExpired";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "order";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteDateTimeOrder))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "query";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productCodes";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "batchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isValid";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchaseId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("listProductCodesPurchasedByZapper");
var listPurchasedProductCodesByZapper = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coverage";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "includeExpired";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "order";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteDateTimeOrder))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "query";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchasedProductCodes";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "txRefNo";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("listPurchasedProductCodesByZapper");
var consPrefixCreditHoldingError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CreditHoldingError";
});
var zrpcReadCreditHoldingError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixCreditHoldingError)(genericCreditHoldingError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorInvalidAmount";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorHoldingDoesNotExist";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorUpdateHoldingError";
    })))))(x);
});
var creditHolding = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCreditHoldingError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "balance";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("creditHolding");
var zrpcWriteCreditHoldingError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCreditHoldingError)(genericCreditHoldingError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorInvalidAmount";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorHoldingDoesNotExist";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreditHoldingErrorUpdateHoldingError";
    })))))(x);
});
var consPrefixCreateVendorError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CreateVendorError";
});
var zrpcReadCreateVendorError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixCreateVendorError)(genericCreateVendorError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateVendorErrorUploadFailed";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateVendorErrorAttachmentNotFound";
    }))))(x);
});
var createVendor = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "shortName";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCreateVendorError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendor";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "shortName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("createVendor");
var zrpcWriteCreateVendorError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCreateVendorError)(genericCreateVendorError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateVendorErrorUploadFailed";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateVendorErrorAttachmentNotFound";
    }))))(x);
});
var consPrefixCreateProductError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CreateProductError";
});
var zrpcReadCreateProductError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixCreateProductError)(genericCreateProductError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorProductAlreadyExists";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInvalidVendor";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInvalidCategory";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorUploadFailed";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorAttachmentNotFound";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInsertFailed";
    }))))))))(x);
});
var zrpcWriteCreateProductError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCreateProductError)(genericCreateProductError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorProductAlreadyExists";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInvalidVendor";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInvalidCategory";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorUploadFailed";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorAttachmentNotFound";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductErrorInsertFailed";
    }))))))))(x);
});
var consPrefixCreateProductCodesError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CreateProductCodesError";
});
var zrpcReadCreateProductCodesError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixCreateProductCodesError)(genericCreateProductCodesError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorInvalidAttachment";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorProductNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorVendorNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorInvalidFileFormat";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorNoEntriesFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorValidationErrors";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadProductCodeValidationError))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorUploadFailed";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorCreateProductCodesFailed";
    }))))))))))(x);
});
var createProductCodes = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCreateProductCodesError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productCodes";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "batchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isValid";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchaseId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("createProductCodes");
var zrpcWriteCreateProductCodesError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCreateProductCodesError)(genericCreateProductCodesError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorInvalidAttachment";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorProductNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorVendorNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorInvalidFileFormat";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorNoEntriesFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorValidationErrors";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteProductCodeValidationError))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorUploadFailed";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateProductCodesErrorCreateProductCodesFailed";
    }))))))))))(x);
});
var consPrefixCreateHoldingError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CreateHoldingError";
});
var zrpcReadCreateHoldingError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixCreateHoldingError)(genericCreateHoldingError)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateHoldingErrorHoldingAlreadyExists";
    })))(x);
});
var createHolding = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCreateHoldingError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holding";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "balance";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "theme";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "aboutUsUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "banner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "url";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "coloredLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "image";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "text";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "primaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "secondaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "tertiaryColor";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "whiteLogoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("createHolding");
var zrpcWriteCreateHoldingError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCreateHoldingError)(genericCreateHoldingError)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CreateHoldingErrorHoldingAlreadyExists";
    })))(x);
});
var consPrefixCodeType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CodeType";
});
var zrpcReadCodeType = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixCodeType)(genericCodeType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CodeTypeAlphanum";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CodeTypeBCode";
    }))))(x);
});
var getProduct = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "product";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "categoryId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeLabel";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeType";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCodeType))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "description";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "photoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionInstruction";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionPeriod";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorToZapPrice";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapToHoldingPrice";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getProduct");
var listProductsByVendor = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "products";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "categoryId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeLabel";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeType";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCodeType))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "description";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "photoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionInstruction";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionPeriod";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorToZapPrice";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapToHoldingPrice";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("listProductsByVendor");
var zrpcWriteCodeType = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCodeType)(genericCodeType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CodeTypeAlphanum";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CodeTypeBCode";
    }))))(x);
});
var createProduct = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "categoryId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeLabel";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeType";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteCodeType))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "description";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "photo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionInstruction";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionPeriod";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorToZapPrice";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapToHoldingPrice";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())())())())())())())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCreateProductError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "product";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "categoryId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeLabel";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeType";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCodeType))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "description";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastUpdatedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "photoUri";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionInstruction";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionPeriod";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorToZapPrice";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapToHoldingPrice";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("createProduct");
var updateProduct = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "categoryId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeLabel";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "codeType";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteCodeType))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "description";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "photo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteUpdateMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionInstruction";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redemptionPeriod";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "from";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "to";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "terms";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "vendorToZapPrice";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapToHoldingPrice";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())())())())())())())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadUpdateProductError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))("updateProduct");
var consPrefixCheckoutError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CheckoutError";
});
var zrpcReadCheckoutError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixCheckoutError)(genericCheckoutError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidProducts";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadItemValidationError))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorCartNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorReservationFailed";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadReservationError))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidTransaction";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "txRefNo";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorTransactionAlreadyUsed";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "txRefNo";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidPurchase";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "purchaseId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidProductCode";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorUpdateCartFailed";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidZapperId";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorProcessingError";
    }))))))))))))(x);
});
var finalizeCheckout = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchaseId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "transactionRefNo";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCheckoutError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productCodes";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "batchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isValid";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchaseId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchase";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "cVals";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "finalizedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "reservedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "status";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadPurchaseStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "transactionRefNo";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))))("finalizeCheckout");
var rollbackCheckout = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchaseId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCheckoutError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))("rollbackCheckout");
var zrpcWriteCheckoutError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCheckoutError)(genericCheckoutError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidProducts";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteItemValidationError))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorCartNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorReservationFailed";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "errors";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteReservationError))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidTransaction";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "txRefNo";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorTransactionAlreadyUsed";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "txRefNo";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidPurchase";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "purchaseId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidProductCode";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorUpdateCartFailed";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorInvalidZapperId";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "CheckoutErrorProcessingError";
    }))))))))))))(x);
});
var consPrefixAllocationsOrder = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "AllocationsOrder";
});
var zrpcReadAllocationsOrder = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixAllocationsOrder)(genericAllocationsOrder)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationsOrderAscendingStockCount";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationsOrderDescendingStockCount";
    }))))(x);
});
var zrpcWriteAllocationsOrder = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixAllocationsOrder)(genericAllocationsOrder)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationsOrderAscendingStockCount";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationsOrderDescendingStockCount";
    }))))(x);
});
var listAllocationsByHolding = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "count";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "includeWithCVal";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "order";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(zrpcWriteAllocationsOrder))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "allocations";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "availableQuantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "cVal";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "reservedQuantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "marker";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("listAllocationsByHolding");
var consPrefixAllocationError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "AllocationError";
});
var zrpcReadAllocationError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixAllocationError)(genericAllocationError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorProductNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorHoldingNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorCurrencyNotFound";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "currencyIds";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorNoZapToHoldingPrice";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorNoHoldingToCustomerPrice";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInsufficientQuantity";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInsufficientBalance";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorAllocationFailed";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInvalidCVal";
    })))))))))))(x);
});
var zrpcWriteAllocationError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixAllocationError)(genericAllocationError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorProductNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorHoldingNotFound";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorCurrencyNotFound";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "currencyIds";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorNoZapToHoldingPrice";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorNoHoldingToCustomerPrice";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInsufficientQuantity";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInsufficientBalance";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorAllocationFailed";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "AllocationErrorInvalidCVal";
    })))))))))))(x);
});
var checkout = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "items";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "dateAdded";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadCheckoutError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchase";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "cVals";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "finalizedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "reservedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "status";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadPurchaseStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "transactionRefNo";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("checkout");
var batchedGetPurchasedProductsByRefNo = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refNos";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "purchasedProductCodes";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "txRefNo";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("batchedGetPurchasedProductsByRefNo");
var allocate = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productCVals";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "cVal";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "quantity";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAllocationError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "allocations";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "availableQuantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "cVal";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "createdAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "reservedQuantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))("allocate");
module.exports = {
    UpdateModeSet: UpdateModeSet,
    UpdateModeUnset: UpdateModeUnset,
    UpdateModeRetain: UpdateModeRetain,
    ProductCodeValidationErrorIncompleteField: ProductCodeValidationErrorIncompleteField,
    ProductCodeValidationErrorInvalidVendorName: ProductCodeValidationErrorInvalidVendorName,
    ProductCodeValidationErrorInvalidProductName: ProductCodeValidationErrorInvalidProductName,
    ProductCodeValidationErrorInvalidProductId: ProductCodeValidationErrorInvalidProductId,
    ProductCodeValidationErrorDuplicateProductCode: ProductCodeValidationErrorDuplicateProductCode,
    PurchaseStatusProcessing: PurchaseStatusProcessing,
    PurchaseStatusProcessed: PurchaseStatusProcessed,
    PurchaseStatusFailed: PurchaseStatusFailed,
    DateTimeOrderDescending: DateTimeOrderDescending,
    DateTimeOrderAscending: DateTimeOrderAscending,
    HoldingActivityTypeCredit: HoldingActivityTypeCredit,
    HoldingActivityTypeDebit: HoldingActivityTypeDebit,
    HoldingActivityTypeAllocation: HoldingActivityTypeAllocation,
    CreateVendorErrorUploadFailed: CreateVendorErrorUploadFailed,
    CreateVendorErrorAttachmentNotFound: CreateVendorErrorAttachmentNotFound,
    UpdateVendorErrorVendorNotFound: UpdateVendorErrorVendorNotFound,
    UpdateVendorErrorUploadFailed: UpdateVendorErrorUploadFailed,
    UpdateVendorErrorAttachmentNotFound: UpdateVendorErrorAttachmentNotFound,
    UpdateVendorErrorUpdateFailed: UpdateVendorErrorUpdateFailed,
    CreateHoldingErrorHoldingAlreadyExists: CreateHoldingErrorHoldingAlreadyExists,
    GetHoldingBalanceErrorHoldingDoesNotExist: GetHoldingBalanceErrorHoldingDoesNotExist,
    CreditHoldingErrorInvalidAmount: CreditHoldingErrorInvalidAmount,
    CreditHoldingErrorHoldingDoesNotExist: CreditHoldingErrorHoldingDoesNotExist,
    CreditHoldingErrorUpdateHoldingError: CreditHoldingErrorUpdateHoldingError,
    DebitHoldingErrorInvalidAmount: DebitHoldingErrorInvalidAmount,
    DebitHoldingErrorHoldingDoesNotExist: DebitHoldingErrorHoldingDoesNotExist,
    DebitHoldingErrorInsufficientBalance: DebitHoldingErrorInsufficientBalance,
    DebitHoldingErrorUpdateHoldingError: DebitHoldingErrorUpdateHoldingError,
    CodeTypeAlphanum: CodeTypeAlphanum,
    CodeTypeBCode: CodeTypeBCode,
    CreateProductErrorProductAlreadyExists: CreateProductErrorProductAlreadyExists,
    CreateProductErrorInvalidVendor: CreateProductErrorInvalidVendor,
    CreateProductErrorInvalidCategory: CreateProductErrorInvalidCategory,
    CreateProductErrorUploadFailed: CreateProductErrorUploadFailed,
    CreateProductErrorAttachmentNotFound: CreateProductErrorAttachmentNotFound,
    CreateProductErrorInsertFailed: CreateProductErrorInsertFailed,
    UpdateProductErrorProductNotFound: UpdateProductErrorProductNotFound,
    UpdateProductErrorInvalidVendor: UpdateProductErrorInvalidVendor,
    UpdateProductErrorInvalidCategory: UpdateProductErrorInvalidCategory,
    UpdateProductErrorUploadFailed: UpdateProductErrorUploadFailed,
    UpdateProductErrorAttachmentNotFound: UpdateProductErrorAttachmentNotFound,
    UpdateProductErrorUpdateFailed: UpdateProductErrorUpdateFailed,
    CreateProductCodesErrorInvalidAttachment: CreateProductCodesErrorInvalidAttachment,
    CreateProductCodesErrorProductNotFound: CreateProductCodesErrorProductNotFound,
    CreateProductCodesErrorVendorNotFound: CreateProductCodesErrorVendorNotFound,
    CreateProductCodesErrorInvalidFileFormat: CreateProductCodesErrorInvalidFileFormat,
    CreateProductCodesErrorNoEntriesFound: CreateProductCodesErrorNoEntriesFound,
    CreateProductCodesErrorValidationErrors: CreateProductCodesErrorValidationErrors,
    CreateProductCodesErrorUploadFailed: CreateProductCodesErrorUploadFailed,
    CreateProductCodesErrorCreateProductCodesFailed: CreateProductCodesErrorCreateProductCodesFailed,
    AllocationErrorProductNotFound: AllocationErrorProductNotFound,
    AllocationErrorHoldingNotFound: AllocationErrorHoldingNotFound,
    AllocationErrorCurrencyNotFound: AllocationErrorCurrencyNotFound,
    AllocationErrorNoZapToHoldingPrice: AllocationErrorNoZapToHoldingPrice,
    AllocationErrorNoHoldingToCustomerPrice: AllocationErrorNoHoldingToCustomerPrice,
    AllocationErrorInsufficientQuantity: AllocationErrorInsufficientQuantity,
    AllocationErrorInsufficientBalance: AllocationErrorInsufficientBalance,
    AllocationErrorAllocationFailed: AllocationErrorAllocationFailed,
    AllocationErrorInvalidCVal: AllocationErrorInvalidCVal,
    AllocationsOrderAscendingStockCount: AllocationsOrderAscendingStockCount,
    AllocationsOrderDescendingStockCount: AllocationsOrderDescendingStockCount,
    UpdateCartErrorInvalidZapperId: UpdateCartErrorInvalidZapperId,
    UpdateCartErrorProductDoesNotExist: UpdateCartErrorProductDoesNotExist,
    UpdateCartErrorAllocationNotFound: UpdateCartErrorAllocationNotFound,
    UpdateCartErrorInsufficientAllocation: UpdateCartErrorInsufficientAllocation,
    UpdateCartErrorUpdateCartFailed: UpdateCartErrorUpdateCartFailed,
    DeleteCartItemsErrorInvalidZapperId: DeleteCartItemsErrorInvalidZapperId,
    DeleteCartItemsErrorCartDoesNotExist: DeleteCartItemsErrorCartDoesNotExist,
    DeleteCartItemsErrorProductIdNotFound: DeleteCartItemsErrorProductIdNotFound,
    DeleteCartItemsErrorUpdateCartFailed: DeleteCartItemsErrorUpdateCartFailed,
    GetCartErrorInvalidZapperId: GetCartErrorInvalidZapperId,
    ItemValidationErrorItemDoesNotExist: ItemValidationErrorItemDoesNotExist,
    ItemValidationErrorItemNotInCart: ItemValidationErrorItemNotInCart,
    ItemValidationErrorInvalidQuantity: ItemValidationErrorInvalidQuantity,
    ReservationErrorNoProductCodesAvailable: ReservationErrorNoProductCodesAvailable,
    ReservationErrorNoAllocation: ReservationErrorNoAllocation,
    ReservationErrorInsufficientAllocations: ReservationErrorInsufficientAllocations,
    CheckoutErrorInvalidProducts: CheckoutErrorInvalidProducts,
    CheckoutErrorCartNotFound: CheckoutErrorCartNotFound,
    CheckoutErrorReservationFailed: CheckoutErrorReservationFailed,
    CheckoutErrorInvalidTransaction: CheckoutErrorInvalidTransaction,
    CheckoutErrorTransactionAlreadyUsed: CheckoutErrorTransactionAlreadyUsed,
    CheckoutErrorInvalidPurchase: CheckoutErrorInvalidPurchase,
    CheckoutErrorInvalidProductCode: CheckoutErrorInvalidProductCode,
    CheckoutErrorUpdateCartFailed: CheckoutErrorUpdateCartFailed,
    CheckoutErrorInvalidZapperId: CheckoutErrorInvalidZapperId,
    CheckoutErrorProcessingError: CheckoutErrorProcessingError,
    UpdateHoldingThemeErrorHoldingNotFound: UpdateHoldingThemeErrorHoldingNotFound,
    UpdateHoldingThemeErrorUploadFailed: UpdateHoldingThemeErrorUploadFailed,
    UpdateHoldingThemeErrorAttachmentNotFound: UpdateHoldingThemeErrorAttachmentNotFound,
    UpdateHoldingThemeErrorUpdateFailed: UpdateHoldingThemeErrorUpdateFailed,
    createVendor: createVendor,
    updateVendor: updateVendor,
    getVendor: getVendor,
    listVendors: listVendors,
    createHolding: createHolding,
    listHoldings: listHoldings,
    getHoldingBalance: getHoldingBalance,
    creditHolding: creditHolding,
    debitHolding: debitHolding,
    createCategory: createCategory,
    listCategories: listCategories,
    createProduct: createProduct,
    updateProduct: updateProduct,
    getProduct: getProduct,
    listProductsByVendor: listProductsByVendor,
    createProductCodes: createProductCodes,
    allocate: allocate,
    listAllocationsByHolding: listAllocationsByHolding,
    getAllocationByProduct: getAllocationByProduct,
    updateCart: updateCart,
    deleteCartItems: deleteCartItems,
    getCart: getCart,
    checkout: checkout,
    finalizeCheckout: finalizeCheckout,
    rollbackCheckout: rollbackCheckout,
    getProductCode: getProductCode,
    getPurchase: getPurchase,
    getPurchasedProductCode: getPurchasedProductCode,
    listPurchasedProductCodesByZapper: listPurchasedProductCodesByZapper,
    listProductCodesPurchasedByZapper: listProductCodesPurchasedByZapper,
    listHoldingActivities: listHoldingActivities,
    listProductCodeBatches: listProductCodeBatches,
    getPurchasedProductCountByZapper: getPurchasedProductCountByZapper,
    getHolding: getHolding,
    updateHoldingTheme: updateHoldingTheme,
    getPurchasedProductsByRefNo: getPurchasedProductsByRefNo,
    batchedGetPurchasedProductsByRefNo: batchedGetPurchasedProductsByRefNo,
    eqUpdateMode: eqUpdateMode,
    genericUpdateMode: genericUpdateMode,
    consPrefixUpdateMode: consPrefixUpdateMode,
    showUpdateMode: showUpdateMode,
    zrpcReadUpdateMode: zrpcReadUpdateMode,
    zrpcWriteUpdateMode: zrpcWriteUpdateMode,
    eqProductCodeValidationError: eqProductCodeValidationError,
    genericProductCodeValidationError: genericProductCodeValidationError,
    consPrefixProductCodeValidationError: consPrefixProductCodeValidationError,
    showProductCodeValidationError: showProductCodeValidationError,
    zrpcReadProductCodeValidationError: zrpcReadProductCodeValidationError,
    zrpcWriteProductCodeValidationError: zrpcWriteProductCodeValidationError,
    eqPurchaseStatus: eqPurchaseStatus,
    genericPurchaseStatus: genericPurchaseStatus,
    consPrefixPurchaseStatus: consPrefixPurchaseStatus,
    showPurchaseStatus: showPurchaseStatus,
    zrpcReadPurchaseStatus: zrpcReadPurchaseStatus,
    zrpcWritePurchaseStatus: zrpcWritePurchaseStatus,
    eqDateTimeOrder: eqDateTimeOrder,
    genericDateTimeOrder: genericDateTimeOrder,
    consPrefixDateTimeOrder: consPrefixDateTimeOrder,
    showDateTimeOrder: showDateTimeOrder,
    zrpcReadDateTimeOrder: zrpcReadDateTimeOrder,
    zrpcWriteDateTimeOrder: zrpcWriteDateTimeOrder,
    eqHoldingActivityType: eqHoldingActivityType,
    genericHoldingActivityType: genericHoldingActivityType,
    consPrefixHoldingActivityType: consPrefixHoldingActivityType,
    showHoldingActivityType: showHoldingActivityType,
    zrpcReadHoldingActivityType: zrpcReadHoldingActivityType,
    zrpcWriteHoldingActivityType: zrpcWriteHoldingActivityType,
    eqCreateVendorError: eqCreateVendorError,
    genericCreateVendorError: genericCreateVendorError,
    consPrefixCreateVendorError: consPrefixCreateVendorError,
    showCreateVendorError: showCreateVendorError,
    zrpcReadCreateVendorError: zrpcReadCreateVendorError,
    zrpcWriteCreateVendorError: zrpcWriteCreateVendorError,
    eqUpdateVendorError: eqUpdateVendorError,
    genericUpdateVendorError: genericUpdateVendorError,
    consPrefixUpdateVendorError: consPrefixUpdateVendorError,
    showUpdateVendorError: showUpdateVendorError,
    zrpcReadUpdateVendorError: zrpcReadUpdateVendorError,
    zrpcWriteUpdateVendorError: zrpcWriteUpdateVendorError,
    eqCreateHoldingError: eqCreateHoldingError,
    genericCreateHoldingError: genericCreateHoldingError,
    consPrefixCreateHoldingError: consPrefixCreateHoldingError,
    showCreateHoldingError: showCreateHoldingError,
    zrpcReadCreateHoldingError: zrpcReadCreateHoldingError,
    zrpcWriteCreateHoldingError: zrpcWriteCreateHoldingError,
    eqGetHoldingBalanceError: eqGetHoldingBalanceError,
    genericGetHoldingBalanceError: genericGetHoldingBalanceError,
    consPrefixGetHoldingBalanceError: consPrefixGetHoldingBalanceError,
    showGetHoldingBalanceError: showGetHoldingBalanceError,
    zrpcReadGetHoldingBalanceError: zrpcReadGetHoldingBalanceError,
    zrpcWriteGetHoldingBalanceError: zrpcWriteGetHoldingBalanceError,
    eqCreditHoldingError: eqCreditHoldingError,
    genericCreditHoldingError: genericCreditHoldingError,
    consPrefixCreditHoldingError: consPrefixCreditHoldingError,
    showCreditHoldingError: showCreditHoldingError,
    zrpcReadCreditHoldingError: zrpcReadCreditHoldingError,
    zrpcWriteCreditHoldingError: zrpcWriteCreditHoldingError,
    eqDebitHoldingError: eqDebitHoldingError,
    genericDebitHoldingError: genericDebitHoldingError,
    consPrefixDebitHoldingError: consPrefixDebitHoldingError,
    showDebitHoldingError: showDebitHoldingError,
    zrpcReadDebitHoldingError: zrpcReadDebitHoldingError,
    zrpcWriteDebitHoldingError: zrpcWriteDebitHoldingError,
    eqCodeType: eqCodeType,
    genericCodeType: genericCodeType,
    consPrefixCodeType: consPrefixCodeType,
    showCodeType: showCodeType,
    zrpcReadCodeType: zrpcReadCodeType,
    zrpcWriteCodeType: zrpcWriteCodeType,
    eqCreateProductError: eqCreateProductError,
    genericCreateProductError: genericCreateProductError,
    consPrefixCreateProductError: consPrefixCreateProductError,
    showCreateProductError: showCreateProductError,
    zrpcReadCreateProductError: zrpcReadCreateProductError,
    zrpcWriteCreateProductError: zrpcWriteCreateProductError,
    eqUpdateProductError: eqUpdateProductError,
    genericUpdateProductError: genericUpdateProductError,
    consPrefixUpdateProductError: consPrefixUpdateProductError,
    showUpdateProductError: showUpdateProductError,
    zrpcReadUpdateProductError: zrpcReadUpdateProductError,
    zrpcWriteUpdateProductError: zrpcWriteUpdateProductError,
    eqCreateProductCodesError: eqCreateProductCodesError,
    genericCreateProductCodesError: genericCreateProductCodesError,
    consPrefixCreateProductCodesError: consPrefixCreateProductCodesError,
    showCreateProductCodesError: showCreateProductCodesError,
    zrpcReadCreateProductCodesError: zrpcReadCreateProductCodesError,
    zrpcWriteCreateProductCodesError: zrpcWriteCreateProductCodesError,
    eqAllocationError: eqAllocationError,
    genericAllocationError: genericAllocationError,
    consPrefixAllocationError: consPrefixAllocationError,
    showAllocationError: showAllocationError,
    zrpcReadAllocationError: zrpcReadAllocationError,
    zrpcWriteAllocationError: zrpcWriteAllocationError,
    eqAllocationsOrder: eqAllocationsOrder,
    genericAllocationsOrder: genericAllocationsOrder,
    consPrefixAllocationsOrder: consPrefixAllocationsOrder,
    showAllocationsOrder: showAllocationsOrder,
    zrpcReadAllocationsOrder: zrpcReadAllocationsOrder,
    zrpcWriteAllocationsOrder: zrpcWriteAllocationsOrder,
    eqUpdateCartError: eqUpdateCartError,
    genericUpdateCartError: genericUpdateCartError,
    consPrefixUpdateCartError: consPrefixUpdateCartError,
    showUpdateCartError: showUpdateCartError,
    zrpcReadUpdateCartError: zrpcReadUpdateCartError,
    zrpcWriteUpdateCartError: zrpcWriteUpdateCartError,
    eqDeleteCartItemsError: eqDeleteCartItemsError,
    genericDeleteCartItemsError: genericDeleteCartItemsError,
    consPrefixDeleteCartItemsError: consPrefixDeleteCartItemsError,
    showDeleteCartItemsError: showDeleteCartItemsError,
    zrpcReadDeleteCartItemsError: zrpcReadDeleteCartItemsError,
    zrpcWriteDeleteCartItemsError: zrpcWriteDeleteCartItemsError,
    eqGetCartError: eqGetCartError,
    genericGetCartError: genericGetCartError,
    consPrefixGetCartError: consPrefixGetCartError,
    showGetCartError: showGetCartError,
    zrpcReadGetCartError: zrpcReadGetCartError,
    zrpcWriteGetCartError: zrpcWriteGetCartError,
    eqItemValidationError: eqItemValidationError,
    genericItemValidationError: genericItemValidationError,
    consPrefixItemValidationError: consPrefixItemValidationError,
    showItemValidationError: showItemValidationError,
    zrpcReadItemValidationError: zrpcReadItemValidationError,
    zrpcWriteItemValidationError: zrpcWriteItemValidationError,
    eqReservationError: eqReservationError,
    genericReservationError: genericReservationError,
    consPrefixReservationError: consPrefixReservationError,
    showReservationError: showReservationError,
    zrpcReadReservationError: zrpcReadReservationError,
    zrpcWriteReservationError: zrpcWriteReservationError,
    eqCheckoutError: eqCheckoutError,
    genericCheckoutError: genericCheckoutError,
    consPrefixCheckoutError: consPrefixCheckoutError,
    showCheckoutError: showCheckoutError,
    zrpcReadCheckoutError: zrpcReadCheckoutError,
    zrpcWriteCheckoutError: zrpcWriteCheckoutError,
    eqUpdateHoldingThemeError: eqUpdateHoldingThemeError,
    genericUpdateHoldingThemeError: genericUpdateHoldingThemeError,
    consPrefixUpdateHoldingThemeError: consPrefixUpdateHoldingThemeError,
    showUpdateHoldingThemeError: showUpdateHoldingThemeError,
    zrpcReadUpdateHoldingThemeError: zrpcReadUpdateHoldingThemeError,
    zrpcWriteUpdateHoldingThemeError: zrpcWriteUpdateHoldingThemeError
};
